var Fily;
(function (Fily) {
    var Categories;
    (function (Categories) {
        Categories.requested = false;
        Categories.saved = false;
        Categories.list = null;
        Categories.disabled = false;
        Categories.init = function () {
            if (Categories.disabled || Categories.saved || Categories.requested) {
                return;
            }
            if (!!Categories.listHtml) {
                return;
            }
            if (Categories.saved === false || Categories.requested === false) {
                Categories.requested = true;
                Fily.Api.Idea.getCategory().done(function (res) {
                    Categories.list = res;
                    Categories.listHtml = res.map(function (c) { return "<option value=\"" + c.id + "\" data-english_name=\"" + c.english_name + "\" >" + c.name + "</option>"; }).join('');
                });
            }
        };
        Categories.getInitialOption = function () {
            return "<option value=\"0\">\u30AB\u30C6\u30B4\u30EA\u3092\u9078\u629E\u3057\u3066\u304F\u3060\u3055\u3044</option>" + Categories.listHtml;
        };
    })(Categories = Fily.Categories || (Fily.Categories = {}));
})(Fily || (Fily = {}));
