var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var Fily;
(function (Fily) {
    var IdeaEditInfoModalView = (function (_super) {
        __extends(IdeaEditInfoModalView, _super);
        function IdeaEditInfoModalView(ideaId, options) {
            var _this = _super.call(this, options) || this;
            _this.ideaId = ideaId;
            _this.selectableContests = [];
            return _this;
        }
        IdeaEditInfoModalView.prototype.events = function () {
            var _this = this;
            return $.extend(_super.prototype.events.call(this), {
                'click / .js-selectButton--photo': 'selectMainPhoto',
                'change / [name=category]': function (e) {
                    _this.showRecommendKeywords(_this.$category.find('option:selected').data('english_name'));
                }
            });
        };
        IdeaEditInfoModalView.prototype.render = function () {
            var _this = this;
            _super.prototype.render.call(this);
            this.$title = this.$body.find('input[name=title]');
            this.$title.val(this.params.title);
            this.$description = this.$body.find('textarea[name=description]');
            this.$description.val(this.params.description);
            this.$category = this.$body.find('select[name=category]');
            this.$category.html(Fily.Categories.listHtml);
            this.$category.val(this.params.categoryId);
            this.$recommendKeyword = $('#js-recommendKeywords');
            this.recommendKeywordTemplate = $('#js-recommendKeywordsTemplate').html();
            this.keywordEditor = this.params.keywordEditor;
            this.showRecommendKeywords(this.$category.find('option:selected').data('english_name'));
            this.$contest = this.$body.find('select[name=contest_id]');
            this.$commentRefusal = this.$body.find('input[name=comment_refusal]');
            if (this.params.enabledApplyContest) {
                Fily.Api.Contest.getEnterableIdea(this.ideaId).done(function (res) {
                    if (!res)
                        return;
                    _this.selectableContests = res || [];
                    $('#js-contest-select').show();
                    _this.$contest.append(res.map(function (c) { return "<option value=\"" + c.id + "\">" + c.title + "</option>"; }).join(''));
                });
            }
            if (this.params.mainPhotoId) {
                this.$body.find('.js-select-Photo[data-photo-id=' + this.params.mainPhotoId + ']').addClass('is-selected');
            }
            this.defaultPublish = this.$body.find('input[name=published]').prop('checked');
            this.titleCounter = new Fily.InputCounter(this.$title, $('.js-title-counter'));
            this.descriptionCounter = new Fily.InputCounter(this.$description, $('.js-description-counter'));
        };
        IdeaEditInfoModalView.prototype.done = function (event) {
            var _this = this;
            var $button = $(event.currentTarget).addClass('is-disabled');
            if (!this.checkCount()) {
                if (!this.titleCounter.checkRequire())
                    Fily.Field.Err.show(this.$title, Fily.ERR.CODE.INPUT_EMPTY, ['タイトル']);
                if (!this.titleCounter.checkLength())
                    Fily.Field.Err.show(this.$title, Fily.ERR.CODE.INPUT_MAX_LENGTH_INVALID, ['タイトル', this.titleCounter.maxLength.toString()]);
                if (!this.descriptionCounter.checkRequire())
                    Fily.Field.Err.show(this.$description, Fily.ERR.CODE.INPUT_EMPTY, ['アイデアの概要・説明文']);
                if (!this.descriptionCounter.checkLength())
                    Fily.Field.Err.show(this.$description, Fily.ERR.CODE.INPUT_MAX_LENGTH_INVALID, ['アイデアの概要・説明文', this.descriptionCounter.maxLength.toString()]);
                $button.removeClass('is-disabled').addClass('is-error');
                setTimeout(function () {
                    $button.removeClass('is-error');
                }, 1000);
                return;
            }
            var publish = this.$body.find('input[name=published]').prop('checked');
            var isCommentRefusal = this.$commentRefusal.prop('checked');
            var contestId = this.$contest.val();
            var normalParams = {
                publish: publish,
                title: this.$title.val(),
                description: this.$description.val().trim(),
                category_id: this.$category.val(),
                keywords: this.keywordEditor.keywords(),
                main_photo_element_id: this.$body.find('.js-select-Photo.is-selected').data('element-id')
            };
            var commentApi;
            if (isCommentRefusal) {
                commentApi = Fily.Api.Idea.commentRefusal(this.ideaId)
                    .fail(function (e) {
                    if (e.status === 401) {
                        alert('正しいユーザーでログインしてください。');
                    }
                    else {
                        alert('コメント受付設定の変更ができませんでした。');
                    }
                    _this.$commentRefusal.prop('checked', !isCommentRefusal);
                });
            }
            else {
                commentApi = Fily.Api.Idea.commentAccept(this.ideaId)
                    .fail(function (e) {
                    if (e.status === 401) {
                        alert('正しいユーザーでログインしてください。');
                    }
                    else {
                        alert('コメント受付設定の変更ができませんでした。');
                    }
                    _this.$commentRefusal.prop('checked', isCommentRefusal);
                });
            }
            ;
            var nextAction = contestId ? function () {
                commentApi.done(function () {
                    location.href = "/contest/" + contestId + "/";
                });
            } : function (res) {
                commentApi.done(function () {
                    res['comment_refusal'] = isCommentRefusal;
                    return _super.prototype.done.call(_this, res);
                });
            };
            if (this.params.enabledApplyContest && !publish && !!contestId) {
                if (window.confirm('イベントに応募するにはアイデアを公開する必要があります。\n公開しますか？')) {
                    Fily.showFullLoading();
                    var params = _.extend(normalParams, {
                        contest_id: contestId,
                        publish: true,
                    });
                    Fily.Api.Idea.edit(this.ideaId, params, publish != this.defaultPublish)
                        .done(nextAction)
                        .fail(function () { return Fily.Ngword.checkInput([_this.$title, _this.$description]); })
                        .always(function () { return _this.feedback($button); });
                    return;
                }
                else {
                    this.feedback($button);
                    return;
                }
            }
            Fily.showFullLoading();
            Fily.Api.Idea.edit(this.ideaId, contestId ? _.extend(normalParams, {
                contest_id: contestId,
            }) : normalParams, publish != this.defaultPublish)
                .done(nextAction)
                .fail(function () { return Fily.Ngword.checkInput([_this.$title, _this.$description]); })
                .always(function () { return _this.feedback($button); });
        };
        IdeaEditInfoModalView.prototype.feedback = function ($button) {
            $button.removeClass('is-disabled');
            Fily.hideFullLoading();
        };
        IdeaEditInfoModalView.prototype.checkCount = function () {
            return this.titleCounter.checkCount() && this.descriptionCounter.checkCount();
        };
        IdeaEditInfoModalView.prototype.selectMainPhoto = function (event) {
            var $photo = $(event.target).parents('.js-select-Photo');
            $('.js-select-Photo').removeClass('is-selected');
            $photo.addClass('is-selected');
        };
        IdeaEditInfoModalView.prototype.showRecommendKeywords = function (categoryEnglishName) {
            var _this = this;
            var template = this.recommendKeywordTemplate;
            var selectedKeywords = this.keywordEditor.keywords();
            Fily.Api.Idea.getRecommendKeywords(categoryEnglishName)
                .done(function (keywords) {
                if (keywords) {
                    var words = keywords.map(function (key) {
                        if (selectedKeywords.indexOf(key.name) > -1) {
                            key.added = true;
                        }
                        return Mustache.render(template, key);
                    }).join('');
                    _this.$recommendKeyword.show().find('.js-keywordList').html(words);
                }
                else {
                    _this.$recommendKeyword.hide().find('.js-keywordList').html('');
                }
            });
        };
        return IdeaEditInfoModalView;
    }(Fily.ModalView));
    Fily.IdeaEditInfoModalView = IdeaEditInfoModalView;
    var IdeaApplyContestModalView = (function (_super) {
        __extends(IdeaApplyContestModalView, _super);
        function IdeaApplyContestModalView(options) {
            return _super.call(this, options) || this;
        }
        IdeaApplyContestModalView.prototype.events = function () {
            return $.extend(_super.prototype.events.call(this), {});
        };
        IdeaApplyContestModalView.prototype.render = function () {
            _super.prototype.render.call(this);
        };
        IdeaApplyContestModalView.prototype.done = function (event) {
            alert('done!');
        };
        return IdeaApplyContestModalView;
    }(Fily.ModalView));
    Fily.IdeaApplyContestModalView = IdeaApplyContestModalView;
})(Fily || (Fily = {}));
