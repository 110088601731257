var Fily;
(function (Fily) {
    var Api;
    (function (Api) {
        var ZipSearch;
        (function (ZipSearch) {
            function findAll(zipcode) {
                return Api.send('zipsearch', {
                    zipcode: zipcode
                }, 'GET');
            }
            ZipSearch.findAll = findAll;
        })(ZipSearch = Api.ZipSearch || (Api.ZipSearch = {}));
    })(Api = Fily.Api || (Fily.Api = {}));
})(Fily || (Fily = {}));
