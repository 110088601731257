var Fily;
(function (Fily) {
    var Myself;
    (function (Myself) {
        var instance = window._myself = $('#js-myself').html() ? JSON.parse($('#js-myself').html()) : null;
        function get() {
            return instance;
        }
        Myself.get = get;
        function set(keyOrMyself, value) {
            if (value === void 0) { value = null; }
            if (!value) {
                instance = keyOrMyself;
            }
            else {
                instance[keyOrMyself] = value;
            }
        }
        Myself.set = set;
        Myself.alias = instance ? instance['alias'] : '';
        Myself.id = instance ? instance['id'] : '';
        Myself.isLogin = function () { return !!Myself.alias; };
        Myself.isSpecialist = function () { return instance ? instance['is_specialist'] : false; };
    })(Myself = Fily.Myself || (Fily.Myself = {}));
})(Fily || (Fily = {}));
