var Fily;
(function (Fily) {
    var Api;
    (function (Api) {
        var Product;
        (function (Product) {
            function getSearchIndex() {
                return Api.send('gmitemmaster.categories', {}, 'GET');
            }
            Product.getSearchIndex = getSearchIndex;
            function getItems(category, keyword, jan_code, page) {
                if (page === void 0) { page = 1; }
                return Api.send('products.search', {
                    category_id: category,
                    keyword: keyword,
                    jan_code: jan_code,
                    page: page
                }, 'GET');
            }
            Product.getItems = getItems;
            function getItemDetail(id) {
                return Api.send('product.' + id, {}, 'GET');
            }
            Product.getItemDetail = getItemDetail;
        })(Product = Api.Product || (Api.Product = {}));
    })(Api = Fily.Api || (Fily.Api = {}));
})(Fily || (Fily = {}));
