var Fily;
(function (Fily) {
    var Api;
    (function (Api) {
        var Present;
        (function (Present) {
            function entry(present_id, user_id) {
                return Api.send("present." + present_id + ".entry." + user_id, {}, 'PUT');
            }
            Present.entry = entry;
            function answers(present_id, user_id, params) {
                return Api.send("present." + present_id + ".answerer." + user_id, params, 'PUT');
            }
            Present.answers = answers;
        })(Present = Api.Present || (Api.Present = {}));
    })(Api = Fily.Api || (Fily.Api = {}));
})(Fily || (Fily = {}));
