var Fily;
(function (Fily) {
    var uniqId = 0;
    var viewUniqId = function () { return 'View' + uniqId++; };
    var BaseView = (function () {
        function BaseView(params) {
            this.viewId = viewUniqId();
            this.$body = $('body');
            var delegate = params.delegate != null ? params.delegate : true;
            if (delegate) {
                this.delegateEvent();
            }
            if (Fily.Myself.isLogin()) {
                Fily.Categories.init();
            }
        }
        BaseView.load = function (params) {
            var _this = this;
            if (params === void 0) { params = {}; }
            $(function () { return new _this(params); });
        };
        BaseView.prototype.events = function () {
            return {};
        };
        BaseView.prototype.delegateEvent = function () {
            var _this = this;
            this.undelegateEventAll();
            $.each(this.events(), function (eventNameAndSelector, fnOrFnName) {
                var match = eventNameAndSelector.match(/^(.+)\s\/\s(.*)$/);
                var eventName = match[1];
                var selector = match[2];
                var fn = Fily.Util.isFunction(fnOrFnName) ? fnOrFnName : _this[fnOrFnName];
                _this.$body.on(eventName + '.fily' + _this.viewId, selector, $.proxy(fn, _this));
            });
        };
        BaseView.prototype.undelegateEventAll = function () {
            this.$body.off('.fily' + this.viewId);
        };
        return BaseView;
    }());
    Fily.BaseView = BaseView;
})(Fily || (Fily = {}));
