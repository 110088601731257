var Fily;
(function (Fily) {
    var Api;
    (function (Api) {
        var Ngword;
        (function (Ngword) {
            function validCheck(params) {
                return Api.send('ngword', params, 'POST');
            }
            Ngword.validCheck = validCheck;
        })(Ngword = Api.Ngword || (Api.Ngword = {}));
    })(Api = Fily.Api || (Fily.Api = {}));
})(Fily || (Fily = {}));
