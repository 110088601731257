var Fily;
(function (Fily) {
    var Img;
    (function (Img) {
        var orientations = [360, 90, 180, 270];
        Img.rotate = function (ctx, o) {
            var x = o.x || 0;
            var y = o.y || 0;
            if (o.degrees) {
                o.radians = o.degrees * (Math.PI / 180);
            }
            ctx.translate(x, y);
            ctx.rotate(o.radians);
            ctx.translate(-x, -y);
        };
        Img.orient = function (img, n) {
            var degree = orientations[n - 1];
            var canvas = document.createElement('canvas');
            var ctx = canvas.getContext('2d');
            if (isRotated(n)) {
                canvas.height = img.width;
                canvas.width = img.height;
            }
            else {
                canvas.width = img.width;
                canvas.height = img.height;
            }
            if (degree) {
                Img.rotate(ctx, {
                    degrees: degree,
                    x: canvas.width / 2,
                    y: canvas.height / 2
                });
                if (isRotated(n)) {
                    var d = canvas.width - canvas.height;
                    ctx.translate(d / 2, -d / 2);
                }
            }
            ctx.drawImage(img, 0, 0);
            return canvas.toDataURL('image/jpeg');
        };
        var isRotated = function (n) { return !!([2, 4].indexOf(n) > -1); };
    })(Img = Fily.Img || (Fily.Img = {}));
})(Fily || (Fily = {}));
