var Fily;
(function (Fily) {
    var Api;
    (function (Api) {
        var Notification;
        (function (Notification) {
            function getActivity(offset, limit) {
                if (offset === void 0) { offset = 0; }
                if (limit === void 0) { limit = 10; }
                return Api.send('user.activity', {
                    offset: offset,
                    limit: limit
                }, 'GET');
            }
            Notification.getActivity = getActivity;
            function getService(offset, limit) {
                if (offset === void 0) { offset = 0; }
                if (limit === void 0) { limit = 10; }
                return Api.send('notice.operation', {
                    offset: offset,
                    limit: limit
                }, 'GET');
            }
            Notification.getService = getService;
            function read(count) {
                if (count === void 0) { count = 0; }
                return Api.send('user.notice.new', { count: count }, 'PUT');
            }
            Notification.read = read;
            function getNewCount() {
                return Api.send('user.notice.new', {}, 'GET');
            }
            Notification.getNewCount = getNewCount;
        })(Notification = Api.Notification || (Api.Notification = {}));
    })(Api = Fily.Api || (Fily.Api = {}));
})(Fily || (Fily = {}));
