var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var Fily;
(function (Fily) {
    var Obi = (function (_super) {
        __extends(Obi, _super);
        function Obi() {
            var _this = _super.call(this, {}) || this;
            _this.obiMessage = '.js-obiMessage';
            _this.activeClass = 'is-active';
            _this.hideTime = 3500;
            if (Obi._instance !== null) {
                return Obi._instance;
            }
            _this.$errorObi = $('#js-obiError');
            _this.$successObi = $('#js-obiSuccess');
            Obi._instance = _this;
            return _this;
        }
        Obi.init = function () {
            return new Obi();
        };
        Obi.prototype.events = function () {
            return {
                'click / #js-obiClose': this.hide
            };
        };
        Obi.prototype.show = function (isError, message) {
            var _this = this;
            if (isError) {
                this.$errorObi.addClass(this.activeClass).find(this.obiMessage).html(message);
            }
            else {
                this.$successObi.addClass(this.activeClass).find(this.obiMessage).html(message);
                setTimeout(function () { return _this.hide(); }, this.hideTime);
            }
        };
        Obi.prototype.hide = function () {
            this.$errorObi.removeClass(this.activeClass);
            this.$successObi.removeClass(this.activeClass);
        };
        Obi._instance = null;
        return Obi;
    }(Fily.BaseView));
    Fily.Obi = Obi;
})(Fily || (Fily = {}));
