var Fily;
(function (Fily) {
    var GA;
    (function (GA) {
        var _androidParams = {
            index1: null,
            index2: null
        };
        var _setAndroidParams = function (value, dimensionNum) {
            var dim = "index" + dimensionNum;
            var screenName = _getAndroidScreenName(value);
            _androidParams[dim] = value;
            if (screenName) {
                _androidParams['screen'] = screenName;
            }
        };
        var _getAndroidScreenName = function (dimValue) {
            var _contents = {
                ida: 'Idea',
                pht: 'Photo',
                usr: 'UserPage',
                icr: 'IdeaCreate'
            };
            var _contentKeys = Object.keys(_contents);
            var result = _contentKeys.indexOf(dimValue.slice(0, 3));
            return result > -1 ? _contents[_contentKeys[result]] : '';
        };
        var _eachPageAnalytics = function () { };
        GA.sendAndroid = function (params) {
            console.log("limia-bridge://log?" + $.param(params));
            location.href = "limia-bridge://log?" + $.param(params);
        };
        GA.sendAndroidRepro = function (params, doneCallback) {
            console.log("limia-bridge://event?" + $.param(params));
            location.href = "limia-bridge://event?" + $.param(params);
            if (typeof doneCallback !== 'undefined' && typeof doneCallback() === 'function') {
                doneCallback();
            }
        };
        GA.sendEvent = function (category, action, label, value) {
            if (Fily.Config.isAndroidWebview) {
                Fily.FA.logLimiaEvent(category, action, label, value);
            }
            else if (Fily.Config.isIOSWebview) {
                Fily.FA.logLimiaEvent(category, action, label, value);
            }
            else {
                gaWrapper('send', 'event', category, action, label, value);
            }
        };
        GA.sendSocial = function (socialNetwork, socialAction, socialTarget) {
            if (Fily.Config.isAndroidWebview) {
                Fily.FA.logLimiaEvent(socialNetwork, socialAction, socialTarget, 0);
            }
            else {
                gaWrapper('send', 'social', socialNetwork, socialAction, socialTarget);
            }
        };
        GA.sendPageview = function () {
            if (!Fily.Config.isAndroidWebview) {
                gaWrapper('send', 'pageview');
            }
        };
        GA.setDimension = function (value, dimensionNum) {
            if (dimensionNum === void 0) { dimensionNum = 2; }
            if (Fily.Config.isWebview) {
                _setAndroidParams(value, dimensionNum);
            }
            else {
                GA.set('dimension' + dimensionNum, value);
            }
        };
        GA.set = function (fieldName, value) {
            if (Fily.Config.isAndroidWebview) {
                gaWrapper('set', fieldName, value);
            }
            else {
                gaWrapper('set', fieldName, value);
            }
        };
        GA.setEachPageAnalytics = function (fn) {
            if (fn === void 0) { fn = null; }
            if (fn) {
                _eachPageAnalytics = fn;
            }
            else {
                _eachPageAnalytics();
            }
        };
        var gaWrapper = function () {
            var options = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                options[_i] = arguments[_i];
            }
            return;
            if (Fily.Config.isProduction && !Fily.Config.isIOSWebview) {
                ga.apply(null, options);
            }
            else {
                console.log("ga('" + options.join("', '") + "')");
            }
        };
        var initLoadView = function () {
            var userId = Fily.Myself.id;
            if (Fily.Config.isAndroidWebview) {
                GA.setDimension(Fily.Myself.isLogin() ? 'IN:' + (Fily.Myself.isSpecialist() ? 'P:' : 'A:') + userId : 'OUT', 1);
            }
            else {
                GA.setDimension(Fily.Myself.isLogin() ? 'IN:' + (Fily.Myself.isSpecialist() ? 'P:' : 'A:') + userId : 'OUT', 1);
                GA.setDimension(('serviceWorker' in navigator).toString(), 12);
                GA.set('userId', userId);
                GA.setEachPageAnalytics();
                GA.sendPageview();
            }
        };
        $(function () {
            initLoadView();
        });
    })(GA = Fily.GA || (Fily.GA = {}));
})(Fily || (Fily = {}));
