var Fily;
(function (Fily) {
    var InputCounter = (function () {
        function InputCounter($inputArea, $counterArea, feedbackFn) {
            if ($counterArea === void 0) { $counterArea = null; }
            if (feedbackFn === void 0) { feedbackFn = function () { }; }
            this.$inputArea = $inputArea;
            this.$counterArea = $counterArea;
            this.feedbackFn = feedbackFn;
            this.minLength = $inputArea.data('minlength');
            this.maxLength = $inputArea.data('maxlength');
            this.delegateEvent();
            this.onInput();
        }
        InputCounter.prototype.delegateEvent = function () {
            var _this = this;
            this.$inputArea.on('input keyup paste propertychange', function (e) { return _this.onInput(e); });
        };
        InputCounter.prototype.onInput = function (event) {
            var overLimit = !this.checkCount();
            if (this.$counterArea) {
                this.$counterArea.text(this.count()).toggleClass('is-error', overLimit);
            }
            if (event) {
                Fily.Field.Err.toggle(this.$inputArea, overLimit);
                this.feedbackFn(overLimit);
            }
        };
        InputCounter.prototype.count = function () {
            return Fily.Util.Str.count(this.$inputArea.val());
        };
        InputCounter.prototype.checkCount = function () {
            return this.checkLength() && this.checkRequire();
        };
        InputCounter.prototype.checkLength = function () {
            if (!this.maxLength && !this.minLength) {
                return true;
            }
            if (this.count() > this.maxLength || this.count() < this.minLength) {
                return false;
            }
            else {
                return true;
            }
        };
        InputCounter.prototype.checkRequire = function () {
            if (!this.$inputArea.prop('required')) {
                return true;
            }
            return this.count() != 0;
        };
        InputCounter.prototype.setZero = function () {
            this.$counterArea.html('0');
        };
        return InputCounter;
    }());
    Fily.InputCounter = InputCounter;
})(Fily || (Fily = {}));
