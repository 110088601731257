var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var Fily;
(function (Fily) {
    var IdeaEditAffiliateElementModalView = (function (_super) {
        __extends(IdeaEditAffiliateElementModalView, _super);
        function IdeaEditAffiliateElementModalView(ideaId, options) {
            var _this = _super.call(this, options) || this;
            _this.ideaId = ideaId;
            _this.affiliateCategoriesTpl = new Fily.MustacheTpl('idea/edit/select_affiliate_categories');
            _this.affiliateSortsTpl = new Fily.MustacheTpl('idea/edit/select_affiliate_sorts');
            _this.productDetailTpl = new Fily.MustacheTpl('idea/edit/affiliate_product_detail');
            _this.limiaItemStreamTpl = new Fily.MustacheTpl('idea/edit/select_limia_item');
            _this.amazonItemStreamTpl = new Fily.MustacheTpl('idea/edit/select_amazon_item');
            _this.rakutenIchibaItemStreamTpl = new Fily.MustacheTpl('idea/edit/select_rakutenichiba_item');
            _this.rakutenProductStreamTpl = new Fily.MustacheTpl('idea/edit/select_rakuten_product');
            _this.isOfficialUser = (_this.params.userClassification && _this.params.userClassification === 'official');
            return _this;
        }
        IdeaEditAffiliateElementModalView.prototype.events = function () {
            return $.extend(_super.prototype.events.call(this), {
                'click / .js-affiliateModalTab': 'clickModalTab',
                'click / .js-affiliateModalInnerTab': 'clickModalInnerTab',
                'submit change / .js-searchAffiliateItem': 'searchAffiliateItems',
                'submit change / .js-searchItemMaster': 'searchItemMaster',
                'click / .js-itemDetail': 'affiliateItemDetail',
                'click / .js-backToProductList': 'backToProductList',
                "click / .js-productFeatureCheckAll": "checkAll"
            });
        };
        IdeaEditAffiliateElementModalView.prototype.render = function () {
            _super.prototype.render.call(this);
            if (this.params.id && this.params.isEdit) {
                if (this.params.elementType === Fily.Api.Idea.Element.Type.affiliate) {
                    this.$caption = this.$body.find('textarea[name=caption]');
                    this.captionCounter = new Fily.InputCounter(this.$caption, this.$body.find('.js-counter'));
                }
                else if (this.params.elementType === Fily.Api.Idea.Element.Type.product) {
                    this.$productDescription = this.$body.find('input[name=inputProductDescription]');
                }
            }
            else {
                this.$affiliateModalTabPanel = this.$body.find('.js-affiliateModalTabPanel');
                this.$affiliateModalInnerTabPanel = this.$body.find('.js-affiliateModalInnerTabPanel');
                this.$itemMasterTab = this.$body.find('.js-itemMasterTab');
                this.loadAffiliateSelectBoxes();
                if (this.isOfficialUser) {
                    this.$itemMasterTab.show();
                    this.$body.find('.js-itemMasterPanel').show();
                    this.$itemMasterTab.find('.js-affiliateModalTab').attr('aria-expanded', 'true');
                    this.loadItemMasterSelectBoxes();
                }
                else {
                    $('.js-affiliateModalTabRakuten').attr('aria-expanded', 'true');
                }
            }
        };
        IdeaEditAffiliateElementModalView.prototype.clickModalTab = function (event) {
            event.preventDefault();
            var $target = $(event.currentTarget);
            $('.js-affiliateModalTab[aria-expanded=true]').attr('aria-expanded', 'false');
            this.$affiliateModalTabPanel.hide();
            $target.attr('aria-expanded', $target.attr('aria-expanded') === 'true' ? 'false' : 'true');
            this.$body.find("#" + $target.attr('aria-controls')).show();
        };
        IdeaEditAffiliateElementModalView.prototype.clickModalInnerTab = function (event) {
            event.preventDefault();
            var $target = $(event.currentTarget);
            $('.js-affiliateModalInnerTab[aria-expanded=true]').attr('aria-expanded', 'false');
            this.$affiliateModalInnerTabPanel.hide();
            $target.attr('aria-expanded', $target.attr('aria-expanded') === 'true' ? 'false' : 'true');
            this.$body.find("#" + $target.attr('aria-controls')).show();
        };
        IdeaEditAffiliateElementModalView.prototype.loadItemMasterSelectBoxes = function () {
            var _this = this;
            var affiliateSite;
            affiliateSite = parseInt($('.js-itemMasterPanel').data('site'), 10);
            if (affiliateSite === Fily.Api.Idea.Element.AffiliateSite.item_master) {
                Fily.Api.Product.getSearchIndex().done(function (res) {
                    _this.renderAffiliateSelectBoxes($('.js-itemMasterPanel').find('.js-affiliateCategories'), { name: 'ジャンルを選択', value: 0 }, res.map(function (item) {
                        return {
                            name: item.name,
                            value: item.id,
                        };
                    }), _this.affiliateCategoriesTpl);
                });
            }
        };
        IdeaEditAffiliateElementModalView.prototype.loadAffiliateSelectBoxes = function () {
            var _this = this;
            var affiliateSite;
            this.$affiliateModalTabPanel.each(function (key, value) {
                affiliateSite = parseInt($(value).data('site'), 10);
                switch (affiliateSite) {
                    case Fily.Api.Idea.Element.AffiliateSite.amazon: {
                        Fily.Api.Amazon.getSearchIndex().done(function (res) {
                            _this.renderAffiliateSelectBoxes($(value).find('.js-affiliateCategories'), { name: 'ジャンルを選択', value: 0 }, res.map(function (item) {
                                return {
                                    name: item.display_name,
                                    value: item.index_name
                                };
                            }), _this.affiliateCategoriesTpl);
                        });
                        break;
                    }
                    case Fily.Api.Idea.Element.AffiliateSite.ichiba: {
                        var sortsRakuten = Fily.Api.Rakuten.getIchibasortSearch(_this.isOfficialUser);
                        _this.renderAffiliateSelectBoxes($('.js-affiliate-panel-innerRakutenIchiba').find('.js-affiliateSorts'), null, sortsRakuten, _this.affiliateSortsTpl);
                        var sortsRakutenProduct = Fily.Api.Rakuten.getProductsortSearch();
                        _this.renderAffiliateSelectBoxes($('.js-affiliate-panel-innerRakutenProduct').find('.js-affiliateSorts'), null, sortsRakutenProduct, _this.affiliateSortsTpl);
                        Fily.Api.Rakuten.getIchibagenreSearch().done(function (res) {
                            _this.renderAffiliateSelectBoxes($(value).find('.js-affiliateCategories'), { name: 'ジャンルを選択', value: 0 }, res.children.map(function (item) {
                                return {
                                    name: item.child.genreName,
                                    value: item.child.genreId
                                };
                            }), _this.affiliateCategoriesTpl);
                        });
                        break;
                    }
                    default:
                        break;
                }
            });
        };
        IdeaEditAffiliateElementModalView.prototype.renderAffiliateSelectBoxes = function ($target, defaultOption, options, targetTpl) {
            targetTpl.load().done(function () {
                $target.html(targetTpl.render({
                    defaultOption: defaultOption,
                    options: options
                }));
            });
        };
        IdeaEditAffiliateElementModalView.prototype.searchItemMaster = function (event) {
            var _this = this;
            event.preventDefault();
            var $target = $(event.currentTarget);
            var $streamWrapperLimia = $target.find('.js-streamWrapperLimia');
            var $streamLimia = $streamWrapperLimia.find('.js-stream');
            var $loaderLimia = $streamWrapperLimia.find('.js-loader');
            var $beforeSearchIconLimia = $streamWrapperLimia.find('.js-affiliateBeforeSearchIcon');
            $streamLimia.empty();
            var keyword = $target.find('[name="inputAffiliateLimiaSearch"]').val();
            var jan_code = $target.find('[name="inputAffiliateJanCodeSearch"]').val();
            if (keyword === '' && jan_code === '') {
                return false;
            }
            var category = $target.find('[name="selectAffiliateCategories"]').val();
            var site = parseInt($target.parent().data('site'), 10);
            var error = null;
            var autoloaderParamLimia = {
                eventType: 'scroll',
                isPage: true,
                $targetWrapper: $streamWrapperLimia,
                $target: $streamLimia,
                $loader: $loaderLimia,
                limit: null,
                offset: null,
                intervalLoad: null,
                sender: null,
                dataFormat: null,
                tpl: null,
            };
            $beforeSearchIconLimia.hide();
            autoloaderParamLimia.limit = IdeaEditAffiliateElementModalView.LIMIA_ITEM_LOAD_LIMIT;
            autoloaderParamLimia.offset = IdeaEditAffiliateElementModalView.LIMIA_ITEM_LOAD_PAGE;
            autoloaderParamLimia.intervalLoad = IdeaEditAffiliateElementModalView.LIMIA_ITEM_LOAD_INTERVAL;
            autoloaderParamLimia.sender = function (page, limit) {
                return Fily.Api.Product.getItems(Number(category), keyword, jan_code, page).fail(function (e) {
                    if (e.status === 400) {
                        Fily.Field.Err.show($target.find('[name="inputAffiliateJanCodeSearch"]'), Fily.ERR.CODE.INPUT_INVALID, ['JANコード']);
                    }
                });
            };
            autoloaderParamLimia.dataFormat = function (data) {
                return { items: _this.formatLimia(data), length: data.products.length, error: error };
            };
            autoloaderParamLimia.tpl = this.limiaItemStreamTpl;
            delete (this.affiliateAutoLoaderLimia);
            $streamWrapperLimia.off('scroll.autoload');
            this.affiliateAutoLoaderLimia = Fily.AutoLoader.init(autoloaderParamLimia);
            $loaderLimia.show();
            Fily.showLoading($loaderLimia);
            $.when(autoloaderParamLimia.sender(1, 0), autoloaderParamLimia.tpl.load()).done(function (res) {
                if (res.products.length < IdeaEditAffiliateElementModalView.LIMIA_ITEM_LOAD_LIMIT) {
                    error = (res.products.length === 0) ? { keyword: keyword, isNoExactMatches: true } : { isLimitItem: true };
                    delete (_this.affiliateAutoLoaderLimia);
                    $streamWrapperLimia.off('scroll.autoload');
                }
                $streamLimia.html(autoloaderParamLimia.tpl.render({ items: _this.formatLimia(res), error: error }));
            }).always(function () { return Fily.hideLoading($loaderLimia); });
        };
        IdeaEditAffiliateElementModalView.prototype.searchAffiliateItems = function (event) {
            var _this = this;
            event.preventDefault();
            var $target = $(event.currentTarget);
            var $streamWrapperAmazon = $target.find('.js-streamWrapperAmazon');
            var $streamWrapperRakutenIchiba = $target.find('.js-streamWrapperRakutenIchiba');
            var $streamWrapperRakutenProduct = $target.find('.js-streamWrapperRakutenProduct');
            var $streamAmazon = $streamWrapperAmazon.find('.js-stream');
            var $streamRakutenIchiba = $streamWrapperRakutenIchiba.find('.js-stream');
            var $streamRakutenProduct = $streamWrapperRakutenProduct.find('.js-stream');
            var $loaderAmzon = $streamWrapperAmazon.find('.js-loader');
            var $loaderRakutenIchiba = $streamWrapperRakutenIchiba.find('.js-loader');
            var $loaderRakutenProduct = $streamWrapperRakutenProduct.find('.js-loader');
            var $beforeSearchIconAmzon = $streamWrapperAmazon.find('.js-affiliateBeforeSearchIcon');
            var $beforeSearchIconRakutenIchiba = $streamWrapperRakutenIchiba.find('.js-affiliateBeforeSearchIcon');
            var $beforeSearchIconRakutenProduct = $streamWrapperRakutenProduct.find('.js-affiliateBeforeSearchIcon');
            $streamAmazon.empty();
            $streamRakutenIchiba.empty();
            $streamRakutenProduct.empty();
            var keyword = $target.find('[name="inputAffiliateSearch"]').val();
            if (keyword === '') {
                return false;
            }
            var category = $target.find('[name="selectAffiliateCategories"]').val();
            var sortRakutenIchiba = $('.js-affiliate-panel-innerRakutenIchiba').find('[name="selectAffiliateSorts"]').val();
            var sortRakutenProduct = $('.js-affiliate-panel-innerRakutenProduct').find('[name="selectAffiliateSorts"]').val();
            var site = parseInt($target.parent().data('site'), 10);
            var error = null;
            var autoloaderParam = {
                eventType: 'scroll',
                isPage: true,
                $targetWrapper: site === Fily.Api.Idea.Element.AffiliateSite.amazon ? $streamWrapperAmazon : $streamWrapperRakutenIchiba,
                $target: site === Fily.Api.Idea.Element.AffiliateSite.amazon ? $streamAmazon : $streamRakutenIchiba,
                $loader: site === Fily.Api.Idea.Element.AffiliateSite.amazon ? $loaderAmzon : $loaderRakutenIchiba,
                limit: null,
                offset: null,
                intervalLoad: null,
                sender: null,
                dataFormat: null,
                tpl: null,
            };
            var autoloaderParamRakutenProduct = {
                eventType: 'scroll',
                isPage: true,
                $targetWrapper: $streamWrapperRakutenProduct,
                $target: $streamRakutenProduct,
                $loader: $loaderRakutenProduct,
                limit: null,
                offset: null,
                intervalLoad: null,
                sender: null,
                dataFormat: null,
                tpl: null,
            };
            if (site === Fily.Api.Idea.Element.AffiliateSite.amazon) {
                $beforeSearchIconAmzon.hide();
                autoloaderParam.limit = IdeaEditAffiliateElementModalView.AMAZON_ITEM_LOAD_LIMIT;
                autoloaderParam.offset = IdeaEditAffiliateElementModalView.AMAZON_ITEM_LOAD_PAGE;
                autoloaderParam.intervalLoad = IdeaEditAffiliateElementModalView.AMAZON_ITEM_LOAD_INTERVAL;
                autoloaderParam.sender = function (page, limit) {
                    return Fily.Api.Amazon.getItems(keyword, category, page);
                };
                autoloaderParam.dataFormat = function (data) {
                    return { items: _this.formatAmazon(data), length: data.list.length, error: data.error };
                };
                autoloaderParam.tpl = this.amazonItemStreamTpl;
            }
            else if (site === Fily.Api.Idea.Element.AffiliateSite.ichiba) {
                $beforeSearchIconRakutenIchiba.hide();
                autoloaderParam.limit = IdeaEditAffiliateElementModalView.RAKUTEN_ICHIBA_ITEM_LOAD_HITS;
                autoloaderParam.offset = IdeaEditAffiliateElementModalView.RAKUTEN_ICHIBA_ITEM_LOAD_PAGE;
                autoloaderParam.intervalLoad = IdeaEditAffiliateElementModalView.RAKUTEN_ICHIBA_ITEM_LOAD_INTERVAL;
                autoloaderParam.sender = function (page, hits) {
                    return Fily.Api.Rakuten.getIchibaitemSearch(Number(category), keyword, sortRakutenIchiba, page);
                };
                autoloaderParam.dataFormat = function (data, hits) {
                    if (data.list.length < hits) {
                        error = { isLimitItem: true };
                    }
                    return { items: _this.formatRakutenIchiba(data), length: data.list.length, isOfficialUser: _this.isOfficialUser, error: error };
                };
                autoloaderParam.tpl = this.rakutenIchibaItemStreamTpl;
                $beforeSearchIconRakutenProduct.hide();
                autoloaderParamRakutenProduct.limit = IdeaEditAffiliateElementModalView.RAKUTEN_PRODUCT_LOAD_HITS;
                autoloaderParamRakutenProduct.offset = IdeaEditAffiliateElementModalView.RAKUTEN_PRODUCT_LOAD_PAGE;
                autoloaderParamRakutenProduct.intervalLoad = IdeaEditAffiliateElementModalView.RAKUTEN_PRODUCT_LOAD_INTERVAL;
                autoloaderParamRakutenProduct.sender = function (page, hits) {
                    return Fily.Api.Rakuten.getProductSearch(Number(category), keyword, sortRakutenProduct, page);
                };
                autoloaderParamRakutenProduct.dataFormat = function (data, hits) {
                    if (data.Products.length < hits) {
                        error = { isLimitItem: true };
                    }
                    return { products: _this.formatRakutenProducts(data), length: data.Products.length, error: error };
                };
                autoloaderParamRakutenProduct.tpl = this.rakutenProductStreamTpl;
            }
            delete (this.affiliateAutoLoader);
            $streamWrapperAmazon.off('scroll.autoload');
            $streamWrapperRakutenIchiba.off('scroll.autoload');
            $streamWrapperRakutenProduct.off('scroll.autoload');
            this.affiliateAutoLoader = Fily.AutoLoader.init(autoloaderParam);
            this.affiliateAutoLoaderRakutenProduct = Fily.AutoLoader.init(autoloaderParamRakutenProduct);
            $loaderAmzon.show();
            $loaderRakutenIchiba.show();
            $loaderRakutenProduct.show();
            Fily.showLoading($loaderAmzon);
            Fily.showLoading($loaderRakutenIchiba);
            Fily.showLoading($loaderRakutenProduct);
            if (site === Fily.Api.Idea.Element.AffiliateSite.amazon) {
                $.when(autoloaderParam.sender(1, 0), autoloaderParam.tpl.load()).done(function (res) {
                    if (res.error) {
                        if (res.list.length === 0) {
                            res.error.isParameterOutOfRange = false;
                        }
                        delete (_this.affiliateAutoLoader);
                        $streamWrapperAmazon.off('scroll.autoload');
                    }
                    $streamAmazon.html(autoloaderParam.tpl.render({ items: _this.formatAmazon(res), error: res.error }));
                }).always(function () { return Fily.hideLoading($loaderAmzon); });
            }
            else if (site === Fily.Api.Idea.Element.AffiliateSite.ichiba) {
                $.when(autoloaderParam.sender(1, 0), autoloaderParamRakutenProduct.sender(1, 0), autoloaderParam.tpl.load(), autoloaderParamRakutenProduct.tpl.load()).done(function (res, resProduct) {
                    if (res.list.length < IdeaEditAffiliateElementModalView.RAKUTEN_ICHIBA_ITEM_LOAD_HITS) {
                        error = (res.list.length === 0) ? { keyword: keyword, isNoExactMatches: true } : { isLimitItem: true };
                        delete (_this.affiliateAutoLoader);
                        delete (_this.affiliateAutoLoaderRakutenProduct);
                        $streamWrapperRakutenIchiba.off('scroll.autoload');
                        $streamWrapperRakutenProduct.off('scroll.autoload');
                    }
                    $streamRakutenIchiba.html(autoloaderParam.tpl.render({ items: _this.formatRakutenIchiba(res), isOfficialUser: _this.isOfficialUser, error: error }));
                    if (resProduct.Products.length < IdeaEditAffiliateElementModalView.RAKUTEN_PRODUCT_LOAD_HITS) {
                        error = (resProduct.Products.length === 0) ? { keyword: keyword, isNoExactMatches: true } : { isLimitItem: true };
                        delete (_this.affiliateAutoLoader);
                        $streamWrapperRakutenProduct.off('scroll.autoload');
                    }
                    $streamRakutenProduct.html(autoloaderParamRakutenProduct.tpl.render({ products: _this.formatRakutenProducts(resProduct), error: error }));
                }).always(function () { return Fily.hideLoading($loaderRakutenIchiba); }, function () { return Fily.hideLoading($loaderRakutenProduct); });
            }
            return false;
        };
        IdeaEditAffiliateElementModalView.prototype.formatLimia = function (data) {
            var _this = this;
            return {
                products: data.products.map(function (item) {
                    if (Object.keys(item).length) {
                        item.price = _this.formatPrice(item.price);
                        if (item.discount_price !== null) {
                            item.discount_price = _this.formatPrice(item.discount_price);
                        }
                    }
                    return item;
                })
            };
        };
        IdeaEditAffiliateElementModalView.prototype.formatAmazon = function (data) {
            return data;
        };
        IdeaEditAffiliateElementModalView.prototype.formatRakutenIchiba = function (data) {
            var _this = this;
            return {
                list: data.list.map(function (item) {
                    item.Item.itemPrice = _this.formatPrice(item.Item.itemPrice);
                    if (item.Item.endTime !== '') {
                        item.Item.endTime = item.Item.endTime.replace(/-/g, '/');
                    }
                    return item;
                })
            };
        };
        IdeaEditAffiliateElementModalView.prototype.formatRakutenProducts = function (data) {
            var _this = this;
            return data.Products.filter(function (item) { return item.mediumImageUrl !== ''; })
                .map(function (item) {
                item.minPrice = _this.formatPrice(item.minPrice);
                item.resizedMediumImageUrl = item.mediumImageUrl.replace('ex=128x128', 'ex=160x160');
                return item;
            });
        };
        IdeaEditAffiliateElementModalView.prototype.formatPrice = function (price) {
            return price.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
        };
        IdeaEditAffiliateElementModalView.prototype.backToProductList = function (event) {
            this.$productsListWrapper = $('.js-productsListWrapper');
            this.$productDetailWrapper = $('.js-productDetailWrapper');
            this.$productDetailWrapper.html(this.productDetailTpl.render());
            this.$productDetailWrapper.hide();
            this.$productsListWrapper.show();
        };
        IdeaEditAffiliateElementModalView.prototype.affiliateItemDetail = function (event) {
            var _this = this;
            var $target = $(event.currentTarget);
            var productId = $target.data('product-id');
            var isFeatures = null;
            this.$productsListWrapper = $('.js-productsListWrapper');
            this.$productDetailWrapper = $('.js-productDetailWrapper');
            this.$productsListWrapper.hide();
            this.$productDetailWrapper.show();
            Fily.Api.Product.getItemDetail(productId).done(function (res) {
                if (res.features.length !== 0) {
                    isFeatures = { isFeatures: true };
                }
                if (res.addition !== null && res.addition.pr_link) {
                    res.is_pr_link = true;
                }
                res.representative_items.map(function (item) {
                    if (item.platform.id === '0') {
                        res.shop_name = item.store_name;
                    }
                    return res;
                });
                _this.productDetailTpl.load().done(function () {
                    _this.$productDetailWrapper.html(_this.productDetailTpl.render({ product: res, isFeatures: isFeatures }));
                });
            });
        };
        IdeaEditAffiliateElementModalView.prototype.checkAll = function (event) {
            var $target = $(event.currentTarget);
            var isCheckAll = $target.prop("checked");
            $target.parents(".js-productFeatureField").find("[name='productCheckedFeatureid']").each(function (_, value) {
                $(value).prop("checked", isCheckAll);
            });
        };
        IdeaEditAffiliateElementModalView.prototype.done = function (event) {
            var _this = this;
            var $target = $(event.currentTarget);
            if ($target.data('product-table-order-direction')) {
                var productTableParam = {
                    order_direction: parseInt($target.data('product-table-order-direction')),
                };
                Fily.showFullLoading();
                Fily.Api.Idea.Element.create(this.ideaId, Fily.Api.Idea.Element.Type.product_table, {
                    product_table: productTableParam
                }, this.params.position)
                    .done(function (res) {
                    return _super.prototype.done.call(_this, res);
                })
                    .always(function () {
                    $target.prop('disabled', false);
                    Fily.hideFullLoading();
                });
            }
            else if (this.params.id && this.params.isEdit) {
                if (this.params.elementType === Fily.Api.Idea.Element.Type.affiliate) {
                    var caption_1 = this.$caption ? this.$caption.val() : '';
                    if (!this.captionCounter.checkLength()) {
                        $target.removeClass('is-error').addClass('is-error');
                        Fily.Field.Err.show(this.$caption, Fily.ERR.CODE.INPUT_MAX_LENGTH_INVALID, ['説明文', this.captionCounter.maxLength.toString()]);
                        this.removeError($target);
                        return;
                    }
                    Fily.showFullLoading();
                    Fily.Ngword.checkInput([this.$caption]).done(function () {
                        Fily.Api.Idea.Element.edit(_this.ideaId, _this.params.id, { caption: caption_1 })
                            .done(function (res) {
                            res.isAddChild = res.content.affiliate.length > 0 && res.content.affiliate.length < 2;
                            _super.prototype.done.call(_this, res);
                        }).always(function () { return Fily.hideFullLoading(); });
                    }).fail(function () { return Fily.hideFullLoading(); });
                    this.removeError($target);
                }
                else {
                    var productDescription = this.$productDescription ? this.$productDescription.val() : '';
                    var $targetItem = $target.parents('.js-editProduct');
                    var $inputProductDescription = $targetItem.find('[name="inputProductDescription"]').val();
                    if (productDescription !== '' && productDescription !== 'PR' && !productDescription.match(/^\d+位$/)) {
                        $target.parents('.js-editFeatures').find('.js-InputField').addClass('is-error');
                        return;
                    }
                    if ($inputProductDescription == 'PR') {
                        if (!$targetItem.find('.js-InputField').data('is-pr-link')) {
                            alert('PRリンクが登録されていないため追加できません。さぽつでPRリンクを登録してください。');
                            return;
                        }
                    }
                    var productCheckedFeatureids_1 = [];
                    var officialLinkCheckedFlagVal_1 = 0;
                    $target.parents('.js-editFeatures').find('[name="productCheckedFeatureid"]:checked').each(function (key, value) {
                        productCheckedFeatureids_1.push($(value).val());
                    });
                    $target.parents('.js-editFeatures').find('[name="officialLinkCheckedFlagVal"]:checked').each(function (key, value) {
                        officialLinkCheckedFlagVal_1 = $(value).val();
                    });
                    if ($inputProductDescription == 'PR' && officialLinkCheckedFlagVal_1 != 0) {
                        alert('PRラベルの商品コンポーネントでは公式リンクは表示できません。PRラベルを外すか、公式リンクを表示しないに変更してください。');
                        return;
                    }
                    Fily.showFullLoading();
                    Fily.Api.Idea.Element.edit(this.ideaId, this.params.id, {
                        description: productDescription,
                        child_material_ids: productCheckedFeatureids_1,
                        official_link_flag: officialLinkCheckedFlagVal_1
                    })
                        .done(function (res) {
                        _super.prototype.done.call(_this, res);
                    }).always(function () { return Fily.hideFullLoading(); });
                }
            }
            else {
                if ($target.parents('.js-streamItem').data('site') === 4) {
                    var $streamItem = $target.parents('.js-streamItem');
                    var $inputProductDescription = $streamItem.find('[name="inputProductDescription"]').val();
                    if ($inputProductDescription !== '' && $inputProductDescription !== 'PR' && !$inputProductDescription.match(/^\d+位$/)) {
                        $streamItem.find('.js-InputField').addClass('is-error');
                        return;
                    }
                    if ($inputProductDescription == 'PR') {
                        if (!$streamItem.find('.js-InputField').data('is-pr-link')) {
                            alert('PRリンクが登録されていないため追加できません。さぽつでPRリンクを登録してください。');
                            return;
                        }
                    }
                    var productCheckedFeatureids_2 = [];
                    var officialLinkCheckedFlagVal_2 = 0;
                    $streamItem.find('[name="productCheckedFeatureid"]:checked').each(function (key, value) {
                        productCheckedFeatureids_2.push($(value).val());
                    });
                    $streamItem.find('[name="officialLinkCheckedFlagVal"]:checked').each(function (key, value) {
                        officialLinkCheckedFlagVal_2 = $(value).val();
                    });
                    if ($inputProductDescription == 'PR' && officialLinkCheckedFlagVal_2 != 0) {
                        alert('PRラベルの商品コンポーネントでは公式リンクは表示できません。PRラベルを外すか、公式リンクを表示しないに変更してください。');
                        return;
                    }
                    $target.prop('disabled', true);
                    Fily.showFullLoading();
                    var productParam = {
                        id: parseInt($streamItem.data('product-id'), 10),
                        description: $inputProductDescription,
                        feature_ids: productCheckedFeatureids_2,
                        official_link_flag: officialLinkCheckedFlagVal_2
                    };
                    Fily.Api.Idea.Element.create(this.ideaId, Fily.Api.Idea.Element.Type.product, {
                        product: productParam
                    }, this.params.position)
                        .done(function (res) {
                        return _super.prototype.done.call(_this, res);
                    })
                        .always(function () {
                        $target.prop('disabled', false);
                        Fily.hideFullLoading();
                    });
                }
                else {
                    $target.prop('disabled', true);
                    Fily.showFullLoading();
                    var $streamItem = $target.parents('.js-streamItem');
                    var affiliateParam = {
                        site: parseInt($streamItem.data('site'), 10),
                        url: $streamItem.data('url'),
                        title: $streamItem.data('title'),
                        caption: $streamItem.data('caption'),
                        description: $streamItem.data('description'),
                        image: $streamItem.data('image'),
                        thumbnail: $streamItem.data('thumbnail'),
                    };
                    if (this.params.id) {
                        Fily.Api.Idea.Element.createChild(this.ideaId, this.params.id, Fily.Api.Idea.Element.Type.affiliateLink, affiliateParam)
                            .done(function (res) {
                            res.isAddChild = res.content.affiliate.length > 0 && res.content.affiliate.length < 2;
                            _super.prototype.done.call(_this, res);
                        })
                            .always(function () {
                            $target.prop('disabled', false);
                            Fily.hideFullLoading();
                        });
                    }
                    else {
                        Fily.Api.Idea.Element.create(this.ideaId, Fily.Api.Idea.Element.Type.affiliate, {
                            affiliate: [
                                affiliateParam,
                            ]
                        }, this.params.position)
                            .done(function (res) {
                            res.isAddChild = true;
                            return _super.prototype.done.call(_this, res);
                        })
                            .always(function () {
                            $target.prop('disabled', false);
                            Fily.hideFullLoading();
                        });
                    }
                }
            }
        };
        IdeaEditAffiliateElementModalView.prototype.removeError = function ($target) {
            setTimeout(function () {
                $target.removeClass('is-error');
            }, 500);
        };
        IdeaEditAffiliateElementModalView.LIMIA_ITEM_LOAD_LIMIT = 50;
        IdeaEditAffiliateElementModalView.LIMIA_ITEM_LOAD_PAGE = 2;
        IdeaEditAffiliateElementModalView.LIMIA_ITEM_LOAD_INTERVAL = 150;
        IdeaEditAffiliateElementModalView.AMAZON_ITEM_LOAD_LIMIT = 10;
        IdeaEditAffiliateElementModalView.AMAZON_ITEM_LOAD_PAGE = 2;
        IdeaEditAffiliateElementModalView.AMAZON_ITEM_LOAD_INTERVAL = 150;
        IdeaEditAffiliateElementModalView.RAKUTEN_ICHIBA_ITEM_LOAD_HITS = 28;
        IdeaEditAffiliateElementModalView.RAKUTEN_ICHIBA_ITEM_LOAD_PAGE = 2;
        IdeaEditAffiliateElementModalView.RAKUTEN_ICHIBA_ITEM_LOAD_INTERVAL = 300;
        IdeaEditAffiliateElementModalView.RAKUTEN_PRODUCT_LOAD_HITS = 28;
        IdeaEditAffiliateElementModalView.RAKUTEN_PRODUCT_LOAD_PAGE = 2;
        IdeaEditAffiliateElementModalView.RAKUTEN_PRODUCT_LOAD_INTERVAL = 300;
        return IdeaEditAffiliateElementModalView;
    }(Fily.ModalView));
    Fily.IdeaEditAffiliateElementModalView = IdeaEditAffiliateElementModalView;
})(Fily || (Fily = {}));
