var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var Fily;
(function (Fily) {
    var IdeaEditMovieElementModalView = (function (_super) {
        __extends(IdeaEditMovieElementModalView, _super);
        function IdeaEditMovieElementModalView(ideaId, options) {
            var _this = _super.call(this, options) || this;
            _this.ideaId = ideaId;
            return _this;
        }
        IdeaEditMovieElementModalView.prototype.render = function () {
            var _this = this;
            _super.prototype.render.call(this);
            this.$inputUrl = this.$body.find('input[name=movieurl]');
            this.$caption = this.$body.find('textarea[name=caption]');
            this.captionCounter = new Fily.InputCounter(this.$caption, this.$body.find('.js-counter'));
            if (!this.params.id) {
                setTimeout(function () {
                    _this.$inputUrl.focus();
                }, 0);
            }
        };
        IdeaEditMovieElementModalView.prototype.done = function (event) {
            var _this = this;
            var $button = $(event.currentTarget);
            var val = this.$inputUrl.val();
            var caption = this.$caption ? this.$caption.val() : '';
            if (!this.params.id) {
                if (!Fily.Movie.Youtube.isValidUrl(val)) {
                    $button.removeClass('is-error').addClass('is-error');
                    Fily.Field.Err.show(this.$inputUrl, Fily.ERR.CODE.MOVIE_URL_INVALID);
                    this.removeError($button);
                    return;
                }
                if (!this.captionCounter.checkLength()) {
                    $button.removeClass('is-error').addClass('is-error');
                    Fily.Field.Err.show(this.$caption, Fily.ERR.CODE.INPUT_MAX_LENGTH_INVALID, ['説明文', this.captionCounter.maxLength.toString()]);
                    this.removeError($button);
                    return;
                }
                Fily.showFullLoading();
                Fily.Ngword.checkInput([this.$caption]).done(function () {
                    Fily.Api.Idea.Element.create(_this.ideaId, Fily.Api.Idea.Element.Type.movie, {
                        movie_id: Fily.Movie.Youtube.getId(val),
                        movie_type: '1',
                        caption: caption
                    }, _this.params.position)
                        .done(function (res) { return _super.prototype.done.call(_this, res); })
                        .always(function () { return Fily.hideFullLoading(); });
                })
                    .fail(function () { return Fily.hideFullLoading(); });
            }
            else {
                if (!this.captionCounter.checkLength()) {
                    $button.removeClass('is-error').addClass('is-error');
                    Fily.Field.Err.show(this.$caption, Fily.ERR.CODE.INPUT_MAX_LENGTH_INVALID, ['説明文', this.captionCounter.maxLength.toString()]);
                    this.removeError($button);
                    return;
                }
                Fily.showFullLoading();
                Fily.Ngword.checkInput([this.$caption]).done(function () {
                    Fily.Api.Idea.Element.edit(_this.ideaId, _this.params.id, {
                        movie_id: val,
                        movie_type: '1',
                        caption: caption
                    })
                        .done(function (res) { return _super.prototype.done.call(_this, res); })
                        .always(function () { return Fily.hideFullLoading(); });
                })
                    .fail(function () { return Fily.hideFullLoading(); });
            }
            this.removeError($button);
        };
        IdeaEditMovieElementModalView.prototype.removeError = function ($button) {
            setTimeout(function () {
                $button.removeClass('is-error');
            }, 500);
        };
        return IdeaEditMovieElementModalView;
    }(Fily.ModalView));
    Fily.IdeaEditMovieElementModalView = IdeaEditMovieElementModalView;
})(Fily || (Fily = {}));
