var Fily;
(function (Fily) {
    var Ngword;
    (function (Ngword) {
        var check = function (texts) {
            return Fily.Api.Ngword.validCheck(texts);
        };
        Ngword.checkInput = function ($inputAreas) {
            var params = [];
            $inputAreas.forEach(function ($inputArea) { return params.push($inputArea.val()); });
            var d = $.Deferred();
            if (!params.length) {
                d.resolve();
                return d.promise();
            }
            ;
            check(params).then(function (res) {
                var isAllOK = true;
                res.forEach(function (result, i) {
                    feedback($inputAreas[i], result);
                    if (!result)
                        isAllOK = false;
                });
                $('.js-error-message').toggle(!isAllOK);
                isAllOK ? d.resolve() : d.reject();
            });
            return d.promise();
        };
        var feedback = function ($inputArea, valid) {
            Fily.Field.Err.toggle($inputArea, !valid, Fily.ERR.CODE.INPUT_NGWORD);
        };
    })(Ngword = Fily.Ngword || (Fily.Ngword = {}));
})(Fily || (Fily = {}));
