var Fily;
(function (Fily) {
    var FA;
    (function (FA) {
        FA.logLimiaEvent = function (category, action, label, value) {
            logEvent('pv_count', { 'category': category, 'action': action, 'label': label, 'value': value });
        };
        var logEvent = function (name, params) {
            if (!name) {
                return null;
            }
            if (window.AnalyticsWebInterface) {
                window.AnalyticsWebInterface.logEvent(name, JSON.stringify(params));
            }
            else if (window.webkit
                && window.webkit.messageHandlers
                && window.webkit.messageHandlers.firebase) {
                var message = {
                    command: 'logEvent',
                    name: name,
                    parameters: params
                };
                window.webkit.messageHandlers.firebase.postMessage(message);
            }
            else {
                console.log("No native APIs found.");
            }
        };
        FA.setUserProperty = function (name, value) {
            if (!Fily.Config.isAndroidWebview || !name || !value) {
                return;
            }
            if (window.AnalyticsWebInterface) {
                window.AnalyticsWebInterface.setUserProperty(name, value);
            }
            else if (window.webkit
                && window.webkit.messageHandlers
                && window.webkit.messageHandlers.firebase) {
                var message = {
                    command: 'setUserProperty',
                    name: name,
                    value: value
                };
                window.webkit.messageHandlers.firebase.postMessage(message);
            }
            else {
                console.log("No native APIs found.");
            }
        };
    })(FA = Fily.FA || (Fily.FA = {}));
})(Fily || (Fily = {}));
