var Fily;
(function (Fily) {
    var Insidemaps = (function () {
        function Insidemaps() {
            this.insidemapsList = {};
            this.setInsidemapsList();
        }
        Insidemaps.init = function () {
            if (!this._instance) {
                this._instance = new Insidemaps();
            }
            return this._instance;
        };
        Insidemaps.prototype.setInsidemapsHeight = function () {
            var _this = this;
            Object.keys(this.insidemapsList).forEach(function (key) {
                _this.setAspectPxByRatio({ 'width': 3, 'height': 2 }, { 'width': _this.insidemapsList[key].clientWidth, 'height': 0 });
                $(_this.insidemapsList[key]).attr('height', _this.aspectPx['height'].toFixed(2));
            });
        };
        Insidemaps.prototype.setInsidemapsList = function () {
            var _this = this;
            var $insidempas = $('.Post-insidemaps').find('.js-Element-insidemaps');
            if ($insidempas.length) {
                [].forEach.call($insidempas, function (value, index) {
                    _this.insidemapsList[index] = value;
                });
            }
        };
        Insidemaps.prototype.setAspectPxByRatio = function (ratio, px) {
            this.aspectPx = Fily.Util.getCalculatedPxByAspectRatio(ratio, px);
        };
        return Insidemaps;
    }());
    Fily.Insidemaps = Insidemaps;
})(Fily || (Fily = {}));
