var Fily;
(function (Fily) {
    var ERR;
    (function (ERR) {
        var CODE;
        (function (CODE) {
            CODE[CODE["CONNECTION"] = 0] = "CONNECTION";
            CODE[CODE["CONNECTION_FAILED"] = 1] = "CONNECTION_FAILED";
            CODE[CODE["CONNECTION_REFUSED"] = 2] = "CONNECTION_REFUSED";
            CODE[CODE["FORM_ERROR"] = 100] = "FORM_ERROR";
            CODE[CODE["INPUT_EMPTY"] = 101] = "INPUT_EMPTY";
            CODE[CODE["INPUT_INVALID"] = 102] = "INPUT_INVALID";
            CODE[CODE["INPUT_FORMAT_INVALID"] = 103] = "INPUT_FORMAT_INVALID";
            CODE[CODE["INPUT_NGWORD"] = 104] = "INPUT_NGWORD";
            CODE[CODE["INPUT_MIN_MAX_LENGTH_INVALID"] = 105] = "INPUT_MIN_MAX_LENGTH_INVALID";
            CODE[CODE["INPUT_MAX_LENGTH_INVALID"] = 106] = "INPUT_MAX_LENGTH_INVALID";
            CODE[CODE["INPUT_KEYWORD_INVALID"] = 107] = "INPUT_KEYWORD_INVALID";
            CODE[CODE["SET_MAX_ITEM_INVALID"] = 108] = "SET_MAX_ITEM_INVALID";
            CODE[CODE["VALUE_NOTFOUND"] = 109] = "VALUE_NOTFOUND";
            CODE[CODE["SELECT_INVALID"] = 110] = "SELECT_INVALID";
            CODE[CODE["CANNOT_USE"] = 111] = "CANNOT_USE";
            CODE[CODE["EVALUATE_STAR"] = 112] = "EVALUATE_STAR";
            CODE[CODE["IMAGE_SIZE_INVALID"] = 113] = "IMAGE_SIZE_INVALID";
            CODE[CODE["ZIPCODE_EMPTY"] = 114] = "ZIPCODE_EMPTY";
            CODE[CODE["ZIPCODE_INVALID"] = 115] = "ZIPCODE_INVALID";
            CODE[CODE["MAILADDRESSS_INVALID"] = 116] = "MAILADDRESSS_INVALID";
            CODE[CODE["MOVIE_URL_INVALID"] = 117] = "MOVIE_URL_INVALID";
            CODE[CODE["TWITTER_URL_INVALID"] = 118] = "TWITTER_URL_INVALID";
            CODE[CODE["INSTAGRAM_URL_INVALID"] = 119] = "INSTAGRAM_URL_INVALID";
            CODE[CODE["ID_INVALID"] = 120] = "ID_INVALID";
            CODE[CODE["CONTENTS_ERROR"] = 200] = "CONTENTS_ERROR";
            CODE[CODE["DENIED_PHOTO_URL"] = 201] = "DENIED_PHOTO_URL";
            CODE[CODE["MESSAGE_ERROR"] = 300] = "MESSAGE_ERROR";
            CODE[CODE["INVALID_INCLUDE_URL"] = 301] = "INVALID_INCLUDE_URL";
        })(CODE = ERR.CODE || (ERR.CODE = {}));
        var MESSAGE;
        (function (MESSAGE) {
            MESSAGE[MESSAGE["\u901A\u4FE1\u306B\u5931\u6557\u3057\u307E\u3057\u305F\u3002"] = 1] = "\u901A\u4FE1\u306B\u5931\u6557\u3057\u307E\u3057\u305F\u3002";
            MESSAGE[MESSAGE["\u4E0D\u9069\u5207\u306A\u30D1\u30E9\u30E1\u30FC\u30BF\u30FC\u3067\u3059"] = 100] = "\u4E0D\u9069\u5207\u306A\u30D1\u30E9\u30E1\u30FC\u30BF\u30FC\u3067\u3059";
            MESSAGE[MESSAGE["{0}\u3092\u5165\u529B\u3057\u3066\u304F\u3060\u3055\u3044"] = 101] = "{0}\u3092\u5165\u529B\u3057\u3066\u304F\u3060\u3055\u3044";
            MESSAGE[MESSAGE["{0}\u3092\u6B63\u3057\u304F\u5165\u529B\u3057\u3066\u304F\u3060\u3055\u3044"] = 102] = "{0}\u3092\u6B63\u3057\u304F\u5165\u529B\u3057\u3066\u304F\u3060\u3055\u3044";
            MESSAGE[MESSAGE["\u4E0D\u9069\u5207\u306A{0}\u3067\u3059"] = 103] = "\u4E0D\u9069\u5207\u306A{0}\u3067\u3059";
            MESSAGE[MESSAGE["\u767B\u9332\u3067\u304D\u306A\u3044\u6587\u5B57\u304C\u542B\u307E\u308C\u3066\u3044\u307E\u3059<br>\u5165\u529B\u5185\u5BB9\u3092\u3054\u78BA\u8A8D\u304F\u3060\u3055\u3044"] = 104] = "\u767B\u9332\u3067\u304D\u306A\u3044\u6587\u5B57\u304C\u542B\u307E\u308C\u3066\u3044\u307E\u3059<br>\u5165\u529B\u5185\u5BB9\u3092\u3054\u78BA\u8A8D\u304F\u3060\u3055\u3044";
            MESSAGE[MESSAGE["{0}\u3092{1}~{2}\u6587\u5B57\u4EE5\u5185\u3067\u5165\u529B\u3057\u3066\u304F\u3060\u3055\u3044"] = 105] = "{0}\u3092{1}~{2}\u6587\u5B57\u4EE5\u5185\u3067\u5165\u529B\u3057\u3066\u304F\u3060\u3055\u3044";
            MESSAGE[MESSAGE["{0}\u3092{1}\u6587\u5B57\u4EE5\u5185\u3067\u5165\u529B\u3057\u3066\u304F\u3060\u3055\u3044"] = 106] = "{0}\u3092{1}\u6587\u5B57\u4EE5\u5185\u3067\u5165\u529B\u3057\u3066\u304F\u3060\u3055\u3044";
            MESSAGE[MESSAGE["1\u6587\u5B57\u76EE\u306B\u534A\u89D2\u8A18\u53F7\u3092\u4F7F\u7528\u3059\u308B\u3053\u3068\u306F\u3067\u304D\u307E\u305B\u3093\u3002"] = 107] = "1\u6587\u5B57\u76EE\u306B\u534A\u89D2\u8A18\u53F7\u3092\u4F7F\u7528\u3059\u308B\u3053\u3068\u306F\u3067\u304D\u307E\u305B\u3093\u3002";
            MESSAGE[MESSAGE["{0}\u3092{1}\u4EE5\u5185\u3067\u8A2D\u5B9A\u3057\u3066\u304F\u3060\u3055\u3044"] = 108] = "{0}\u3092{1}\u4EE5\u5185\u3067\u8A2D\u5B9A\u3057\u3066\u304F\u3060\u3055\u3044";
            MESSAGE[MESSAGE["{0}\u304C\u3042\u308A\u307E\u305B\u3093"] = 109] = "{0}\u304C\u3042\u308A\u307E\u305B\u3093";
            MESSAGE[MESSAGE["{0}\u3092\u9078\u629E\u3057\u3066\u304F\u3060\u3055\u3044"] = 110] = "{0}\u3092\u9078\u629E\u3057\u3066\u304F\u3060\u3055\u3044";
            MESSAGE[MESSAGE["{0}\u306F\u4F7F\u7528\u3067\u304D\u307E\u305B\u3093"] = 111] = "{0}\u306F\u4F7F\u7528\u3067\u304D\u307E\u305B\u3093";
            MESSAGE[MESSAGE["\u661F\u3092\u30AF\u30EA\u30C3\u30AF\u3057\u3066\u8A55\u4FA1\u3057\u3066\u304F\u3060\u3055\u3044"] = 112] = "\u661F\u3092\u30AF\u30EA\u30C3\u30AF\u3057\u3066\u8A55\u4FA1\u3057\u3066\u304F\u3060\u3055\u3044";
            MESSAGE[MESSAGE["\u30A2\u30C3\u30D7\u30ED\u30FC\u30C9\u3067\u304D\u308B\u5199\u771F\u306E\u30D5\u30A1\u30A4\u30EB\u30B5\u30A4\u30BA\u306F20MB\u307E\u3067\u3067\u3059"] = 113] = "\u30A2\u30C3\u30D7\u30ED\u30FC\u30C9\u3067\u304D\u308B\u5199\u771F\u306E\u30D5\u30A1\u30A4\u30EB\u30B5\u30A4\u30BA\u306F20MB\u307E\u3067\u3067\u3059";
            MESSAGE[MESSAGE["\u90F5\u4FBF\u756A\u53F7\u3092\u5165\u529B\u3057\u3066\u304F\u3060\u3055\u3044"] = 114] = "\u90F5\u4FBF\u756A\u53F7\u3092\u5165\u529B\u3057\u3066\u304F\u3060\u3055\u3044";
            MESSAGE[MESSAGE["\u90F5\u4FBF\u756A\u53F7\u3092\u6B63\u3057\u304F\u5165\u529B\u3057\u3066\u304F\u3060\u3055\u3044"] = 115] = "\u90F5\u4FBF\u756A\u53F7\u3092\u6B63\u3057\u304F\u5165\u529B\u3057\u3066\u304F\u3060\u3055\u3044";
            MESSAGE[MESSAGE["\u30E1\u30FC\u30EB\u30A2\u30C9\u30EC\u30B9\u3092\u6B63\u3057\u304F\u5165\u529B\u3057\u3066\u304F\u3060\u3055\u3044"] = 116] = "\u30E1\u30FC\u30EB\u30A2\u30C9\u30EC\u30B9\u3092\u6B63\u3057\u304F\u5165\u529B\u3057\u3066\u304F\u3060\u3055\u3044";
            MESSAGE[MESSAGE["\u6B63\u3057\u3044\u52D5\u753B\u306EURL\u3092\u5165\u529B\u3057\u3066\u304F\u3060\u3055\u3044"] = 117] = "\u6B63\u3057\u3044\u52D5\u753B\u306EURL\u3092\u5165\u529B\u3057\u3066\u304F\u3060\u3055\u3044";
            MESSAGE[MESSAGE["\u5165\u529B\u3044\u305F\u3060\u3044\u305FURL\u306E\u5199\u771F\u306F\u5229\u7528\u3067\u304D\u307E\u305B\u3093\u3002"] = 201] = "\u5165\u529B\u3044\u305F\u3060\u3044\u305FURL\u306E\u5199\u771F\u306F\u5229\u7528\u3067\u304D\u307E\u305B\u3093\u3002";
            MESSAGE[MESSAGE["\u30E1\u30C3\u30BB\u30FC\u30B8\u306BURL\u3092\u5165\u529B\u3059\u308B\u3053\u3068\u306F\u3067\u304D\u307E\u305B\u3093\u3002"] = 301] = "\u30E1\u30C3\u30BB\u30FC\u30B8\u306BURL\u3092\u5165\u529B\u3059\u308B\u3053\u3068\u306F\u3067\u304D\u307E\u305B\u3093\u3002";
            MESSAGE[MESSAGE["Twitter\u306E\u6295\u7A3FURL\u3092\u5165\u529B\u3057\u3066\u304F\u3060\u3055\u3044\u3002"] = 118] = "Twitter\u306E\u6295\u7A3FURL\u3092\u5165\u529B\u3057\u3066\u304F\u3060\u3055\u3044\u3002";
            MESSAGE[MESSAGE["Instagram\u306E\u6295\u7A3FURL\u3092\u5165\u529B\u3057\u3066\u304F\u3060\u3055\u3044\u3002"] = 119] = "Instagram\u306E\u6295\u7A3FURL\u3092\u5165\u529B\u3057\u3066\u304F\u3060\u3055\u3044\u3002";
            MESSAGE[MESSAGE["\u5B58\u5728\u3057\u306A\u3044ID\u3067\u3059"] = 120] = "\u5B58\u5728\u3057\u306A\u3044ID\u3067\u3059";
        })(MESSAGE = ERR.MESSAGE || (ERR.MESSAGE = {}));
        ERR.toString = function (code, params) {
            if (params === void 0) { params = []; }
            if (!code)
                return '';
            return MESSAGE[code].replace(/\{(\d)\}/g, function (m, n) { return params[parseInt(n)] ? params[parseInt(n)] : ''; });
        };
    })(ERR = Fily.ERR || (Fily.ERR = {}));
})(Fily || (Fily = {}));
