var Fily;
(function (Fily) {
    var Api;
    (function (Api) {
        var User;
        (function (User) {
            function get(alias) {
                return Api.send('user.' + alias, {}, 'GET');
            }
            User.get = get;
            function uploadProfilePhoto(base64) {
                return Api.send('user.setting.profile', { profile_photo: base64 }, 'POST');
            }
            User.uploadProfilePhoto = uploadProfilePhoto;
            function uploadCoverPhoto(base64) {
                return Api.send('user.setting.profile', { cover_photo: base64 }, 'POST');
            }
            User.uploadCoverPhoto = uploadCoverPhoto;
            function updateBasicProfile(nickname, introduction, website, instagram, twitter, facebook) {
                return Api.send('user.setting.profile', {
                    nickname: nickname,
                    introduction: introduction,
                    website_url: website,
                    instagram_url: instagram,
                    twitter_url: twitter,
                    facebook_url: facebook,
                }, 'POST');
            }
            User.updateBasicProfile = updateBasicProfile;
            function follow(alias, id) {
                if (!Fily.Config.isAndroidWebview) {
                    Fily.GA.sendEvent('フォロー', '追加', id, 1);
                }
                return Api.send('user.' + alias + '.follow', {}, 'POST');
            }
            User.follow = follow;
            function follows(myalias, userlist) {
                var aliaslist = userlist.map(function (u) { return u.alias; });
                var userIds = userlist.map(function (u) { return u.id; }).join(',');
                Fily.GA.sendEvent('フォロー', '追加', userIds, 1);
                return Api.send('user.' + myalias + '.follows', { user_alias_list: aliaslist }, 'POST');
            }
            User.follows = follows;
            function unfollow(alias) {
                if (!Fily.Config.isAndroidWebview) {
                    Fily.GA.setDimension(Fily.Myself.id, 8);
                }
                return Api.send('user.' + alias + '.unfollow', {}, 'POST');
            }
            User.unfollow = unfollow;
            function goodToFollow(offset, limit) {
                if (offset === void 0) { offset = 0; }
                if (limit === void 0) { limit = 10; }
                return Api.send('user.recommend', {
                    offset: offset,
                    limit: limit
                }, 'GET');
            }
            User.goodToFollow = goodToFollow;
            var Contents;
            (function (Contents) {
                function get(userAlias, offset, limit) {
                    if (offset === void 0) { offset = 0; }
                    if (limit === void 0) { limit = 10; }
                    return Api.send('user.' + userAlias + '.contents', {
                        offset: offset,
                        limit: limit
                    }, 'GET');
                }
                Contents.get = get;
            })(Contents = User.Contents || (User.Contents = {}));
            var Idea;
            (function (Idea) {
                function get(userAlias, offset, limit, isPrivate) {
                    if (offset === void 0) { offset = 0; }
                    if (limit === void 0) { limit = 10; }
                    if (isPrivate === void 0) { isPrivate = false; }
                    var apiName = 'user.' + userAlias + '.idea' + (isPrivate ? '?private=1' : '');
                    return Api.send(apiName, {
                        offset: offset,
                        limit: limit
                    }, 'GET');
                }
                Idea.get = get;
            })(Idea = User.Idea || (User.Idea = {}));
            var Photo;
            (function (Photo) {
                function get(userAlias, offset, limit) {
                    if (offset === void 0) { offset = 0; }
                    if (limit === void 0) { limit = 10; }
                    return Api.send('user.' + userAlias + '.photo', {
                        offset: offset,
                        limit: limit
                    }, 'GET');
                }
                Photo.get = get;
            })(Photo = User.Photo || (User.Photo = {}));
            var LikesIdea;
            (function (LikesIdea) {
                function get(userAlias, offset, limit) {
                    if (offset === void 0) { offset = 0; }
                    if (limit === void 0) { limit = 10; }
                    return Api.send('user.' + userAlias + '.likes/idea', {
                        offset: offset,
                        limit: limit
                    }, 'GET');
                }
                LikesIdea.get = get;
            })(LikesIdea = User.LikesIdea || (User.LikesIdea = {}));
            var LikesPhoto;
            (function (LikesPhoto) {
                function get(userAlias, offset, limit) {
                    if (offset === void 0) { offset = 0; }
                    if (limit === void 0) { limit = 10; }
                    return Api.send('user.' + userAlias + '.likes/photo', {
                        offset: offset,
                        limit: limit
                    }, 'GET');
                }
                LikesPhoto.get = get;
            })(LikesPhoto = User.LikesPhoto || (User.LikesPhoto = {}));
            var Follow;
            (function (Follow) {
                function get(userAlias, offset, limit) {
                    if (offset === void 0) { offset = 0; }
                    if (limit === void 0) { limit = 10; }
                    return Api.send('user.' + userAlias + '.follows', {
                        offset: offset,
                        limit: limit
                    }, 'GET');
                }
                Follow.get = get;
            })(Follow = User.Follow || (User.Follow = {}));
            var Follower;
            (function (Follower) {
                function get(userAlias, offset, limit) {
                    if (offset === void 0) { offset = 0; }
                    if (limit === void 0) { limit = 10; }
                    return Api.send('user.' + userAlias + '.followers', {
                        offset: offset,
                        limit: limit
                    }, 'GET');
                }
                Follower.get = get;
            })(Follower = User.Follower || (User.Follower = {}));
        })(User = Api.User || (Api.User = {}));
    })(Api = Fily.Api || (Fily.Api = {}));
})(Fily || (Fily = {}));
