var Fily;
(function (Fily) {
    var GTM;
    (function (GTM) {
        GTM.set = function (options) {
            var params = options.trackPageview ? {
                event: options.event,
                trackPageview: options.trackPageview,
                affiliate: options.affiliate
            } : {
                event: options.event
            };
            gtmWrapper(params);
        };
        var gtmWrapper = function (params) {
            window['dataLayer'].push(params);
        };
    })(GTM = Fily.GTM || (Fily.GTM = {}));
})(Fily || (Fily = {}));
