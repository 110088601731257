var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var Fily;
(function (Fily) {
    var WidgetAnalytics = (function (_super) {
        __extends(WidgetAnalytics, _super);
        function WidgetAnalytics() {
            return _super.call(this, {}) || this;
        }
        WidgetAnalytics.prototype.events = function () {
            return {
                'click / .js-widgetTrigger': this.emit,
                'click / .js-save-profile': this.saveProfile
            };
        };
        WidgetAnalytics.prototype.emit = function (e) {
            var $target = $(e.currentTarget);
            console.assert(!!$target.data('category'), 'WidgetAnalytics: `category` is required but undefined');
            console.assert(!!$target.data('action'), 'WidgetAnalytics: `Action` is required but undefined');
            console.assert(!!$target.data('type'), 'WidgetAnalytics: `Target` is required but undefined');
            console.assert(typeof $target.data('category') === 'string', 'Undefined WidgetAnalytics: `category`');
            console.assert(typeof $target.data('action') === 'string', 'Undefined WidgetAnalytics: `Action`');
            console.assert(typeof $target.data('type') === 'string', 'Undefined WidgetAnalytics: `Target`');
            Fily.GA.sendEvent($target.data('category'), $target.data('action'), $target.data('type'), 1);
            if (Fily.Config.isAndroidWebview) {
                var href = $target.parents('.js-widgetTriggerParentsHref').data('href');
                if (href) {
                    open(href, '_blank');
                }
            }
        };
        WidgetAnalytics.prototype.saveProfile = function (e) {
            if ($('.js-profile-text').val()) {
                var $target = $(e.currentTarget);
                Fily.GA.sendEvent($target.data('category'), $target.data('action'), $target.data('type'), 1);
            }
        };
        return WidgetAnalytics;
    }(Fily.BaseView));
    Fily.WidgetAnalytics = WidgetAnalytics;
})(Fily || (Fily = {}));
