var Fily;
(function (Fily) {
    var Api;
    (function (Api) {
        var Request;
        (function (Request) {
            function get(alias) {
                return Api.send("request." + alias, {}, 'GET');
            }
            Request.get = get;
            function confirm(params) {
                return Api.send("request.confirm", params, 'POST');
            }
            Request.confirm = confirm;
            function create(params) {
                return Api.send("request.entry", params, 'POST');
            }
            Request.create = create;
        })(Request = Api.Request || (Api.Request = {}));
    })(Api = Fily.Api || (Fily.Api = {}));
})(Fily || (Fily = {}));
