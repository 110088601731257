var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var Fily;
(function (Fily) {
    var Drawermenu = (function (_super) {
        __extends(Drawermenu, _super);
        function Drawermenu() {
            return _super !== null && _super.apply(this, arguments) || this;
        }
        Drawermenu.prototype.events = function () {
            return {
                'touchend click / .js-menuToggle': Drawermenu.toggle,
                'touchend click / #js-closeDrawerMenu': Drawermenu.close
            };
        };
        Drawermenu.toggle = function (flag) {
            if (flag === void 0) { flag = null; }
            var $html = $('html');
            var $menu = $('.js-drawerMenu');
            var draweropenClass = 'is-drawermenu-open';
            var isShowClass = 'is-show';
            if ($html.hasClass(draweropenClass) || (flag !== null && !flag)) {
                $html.removeClass(draweropenClass);
                $menu.removeClass(isShowClass).prop('aria-hidden', true);
            }
            else {
                $menu.addClass(isShowClass).prop('aria-hidden', false);
                $html.addClass(draweropenClass);
                setTimeout(function () {
                    Fily.GA.sendEvent('操作', 'ハンバーガー', '(not set)');
                }, 50);
            }
            return false;
        };
        Drawermenu.close = function (e) {
            e.preventDefault();
            Drawermenu.toggle(false);
        };
        return Drawermenu;
    }(Fily.BaseView));
    Fily.Drawermenu = Drawermenu;
})(Fily || (Fily = {}));
