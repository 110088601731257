var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var Fily;
(function (Fily) {
    var InsidemapsElementModalView = (function (_super) {
        __extends(InsidemapsElementModalView, _super);
        function InsidemapsElementModalView(ideaId, options) {
            var _this = _super.call(this, options) || this;
            _this.ideaId = ideaId;
            return _this;
        }
        InsidemapsElementModalView.prototype.render = function () {
            _super.prototype.render.call(this);
            this.$caption = this.$body.find('textarea[name=caption]');
            this.captionCounter = new Fily.InputCounter(this.$caption, this.$body.find('.js-counter'));
        };
        InsidemapsElementModalView.prototype.done = function (event) {
            var _this = this;
            var $button = $(event.currentTarget);
            var caption = this.$caption ? this.$caption.val() : '';
            if (!this.captionCounter.checkLength()) {
                $button.removeClass('is-error').addClass('is-error');
                Fily.Field.Err.show(this.$caption, Fily.ERR.CODE.INPUT_MAX_LENGTH_INVALID, ['説明文', this.captionCounter.maxLength.toString()]);
                this.removeError($button);
                return;
            }
            Fily.showFullLoading();
            Fily.Ngword.checkInput([this.$caption]).done(function () {
                Fily.Api.Idea.Element.edit(_this.ideaId, _this.params.id, {
                    caption: caption
                }).done(function (res) {
                    res['content']['insidemaps']['height'] = _this.params.height;
                    _super.prototype.done.call(_this, res);
                }).always(function () { return Fily.hideFullLoading(); });
            }).fail(function () { return Fily.hideFullLoading(); });
            this.removeError($button);
        };
        InsidemapsElementModalView.prototype.removeError = function ($button) {
            setTimeout(function () {
                $button.removeClass('is-error');
            }, 500);
        };
        return InsidemapsElementModalView;
    }(Fily.ModalView));
    Fily.InsidemapsElementModalView = InsidemapsElementModalView;
})(Fily || (Fily = {}));
