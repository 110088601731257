var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var Fily;
(function (Fily) {
    var SocialAnalytics = (function (_super) {
        __extends(SocialAnalytics, _super);
        function SocialAnalytics() {
            var _this = _super.call(this, {}) || this;
            Fily.Twitter.setCallback('tweet');
            return _this;
        }
        SocialAnalytics.prototype.events = function () {
            return {
                'click / .js-socialTrigger': this.emit
            };
        };
        SocialAnalytics.prototype.emit = function (e) {
            var $target = $(e.currentTarget);
            console.assert(!!$target.data('network'), 'Social Param: `Network` is required but undefined');
            console.assert(!!$target.data('action'), 'Social Param: `Action` is required but undefined');
            console.assert(!!$target.data('target'), 'Social Param: `Target` is required but undefined');
            console.assert(typeof $target.data('network') === 'string', 'Undefined Social Param: `Network`');
            console.assert(typeof $target.data('action') === 'string', 'Undefined Social Param: `Action`');
            console.assert(typeof $target.data('target') === 'string', 'Undefined Social Param: `Target`');
            Fily.GA.sendSocial($target.data('network'), $target.data('action'), $target.data('target'));
        };
        return SocialAnalytics;
    }(Fily.BaseView));
    Fily.SocialAnalytics = SocialAnalytics;
})(Fily || (Fily = {}));
