var Fily;
(function (Fily) {
    var Api;
    (function (Api) {
        var Rakuten;
        (function (Rakuten) {
            function getIchibagenreSearch(genre_id) {
                if (genre_id === void 0) { genre_id = 0; }
                return Api.send("rakuten.ichibagenre." + genre_id + ".search", {}, 'GET');
            }
            Rakuten.getIchibagenreSearch = getIchibagenreSearch;
            function getIchibasortSearch(isOfficialUser) {
                if (isOfficialUser) {
                    return [
                        { name: '標準', value: 'standard' },
                        { name: '料率順が高い順', value: '-affiliateRate' },
                        { name: '料率順が低い順', value: '+affiliateRate' },
                        { name: 'レビュー評価順', value: '-reviewAverage' },
                        { name: 'レビュー件数順', value: '-reviewCount' },
                        { name: '価格が安い順', value: '+itemPrice' },
                        { name: '価格が高い順', value: '-itemPrice' },
                        { name: '新着順', value: '-updateTimestamp' }
                    ];
                }
                else {
                    return [
                        { name: '標準', value: 'standard' },
                        { name: 'レビュー評価順', value: '-reviewAverage' },
                        { name: 'レビュー件数順', value: '-reviewCount' },
                        { name: '価格が安い順', value: '+itemPrice' },
                        { name: '価格が高い順', value: '-itemPrice' },
                        { name: '新着順', value: '-updateTimestamp' }
                    ];
                }
            }
            Rakuten.getIchibasortSearch = getIchibasortSearch;
            function getIchibaitemSearch(genre_id, keyword, sort, page) {
                if (page === void 0) { page = 1; }
                return Api.send('rakuten.ichibaitem.search', {
                    genre_id: genre_id,
                    keyword: keyword,
                    sort: sort,
                    page: page
                }, 'GET');
            }
            Rakuten.getIchibaitemSearch = getIchibaitemSearch;
            function getProductsortSearch() {
                return [
                    { name: '標準', value: 'standard' },
                    { name: '満足順', value: '-satisfied' },
                    { name: '売上順', value: '-seller' },
                    { name: '新着順（発売日順）', value: '-releaseDate' }
                ];
            }
            Rakuten.getProductsortSearch = getProductsortSearch;
            function getProductSearch(genre_id, keyword, sort, page) {
                if (page === void 0) { page = 1; }
                return Api.send('rakuten.product.search', {
                    genre_id: genre_id,
                    keyword: keyword,
                    sort: sort,
                    page: page
                }, 'GET');
            }
            Rakuten.getProductSearch = getProductSearch;
        })(Rakuten = Api.Rakuten || (Api.Rakuten = {}));
    })(Api = Fily.Api || (Fily.Api = {}));
})(Fily || (Fily = {}));
