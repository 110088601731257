var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var Fily;
(function (Fily) {
    var FollowView = (function (_super) {
        __extends(FollowView, _super);
        function FollowView() {
            var _this = _super.call(this, { delegate: false }) || this;
            _super.prototype.delegateEvent.call(_this);
            return _this;
        }
        FollowView.prototype.events = function () {
            return {
                'click / .js-followButton': FollowView.toggleFollow
            };
        };
        FollowView.follow = function (alias, id, doneCallback) {
            Fily.Api.User.follow(alias, id).done(function (res) {
                doneCallback(res);
            });
        };
        FollowView.unfollow = function (alias, doneCallback) {
            Fily.Api.User.unfollow(alias).done(function (res) {
                doneCallback(res);
            });
        };
        FollowView.toggleFollow = function (event) {
            var followingClass = 'is-following';
            var $target = $(event.currentTarget);
            var id = $target.data('user-id');
            var alias = $target.data('user-alias');
            if (!alias || !Fily.Myself.isLogin()) {
                location.href = '/signup/';
                return;
            }
            if ($target.hasClass(followingClass)) {
                FollowView.unfollow(alias, function (res) {
                    $target.removeClass(followingClass);
                });
            }
            else {
                FollowView.follow(alias, id, function (res) {
                    $target.addClass(followingClass);
                });
            }
        };
        return FollowView;
    }(Fily.BaseView));
    Fily.FollowView = FollowView;
})(Fily || (Fily = {}));
