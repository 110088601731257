var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var Fily;
(function (Fily) {
    var UserView = (function (_super) {
        __extends(UserView, _super);
        function UserView() {
            var _this = _super.call(this, { delegate: false }) || this;
            _super.prototype.delegateEvent.call(_this);
            Fily.FollowView.load();
            return _this;
        }
        UserView.prototype.events = function () {
            return {
                'click / .js-bioToggle': function (e) {
                    var $this = $(e.currentTarget);
                    var $bio = $('.js-ProfileCanopy-bio');
                    var fullText = $this.data('message-full').replace(/\n/g, '<br>');
                    var excerptText = $this.data('message-excerpt').replace(/\n/g, '<br>');
                    var isExpanded = $bio.attr('aria-expanded') === 'true';
                    if (!isExpanded) {
                        $bio.attr('aria-expanded', String(!isExpanded)).html(fullText);
                    }
                },
                'click / .js-showModal--createIdea': function (event) {
                    event.preventDefault();
                    var $showModalCreateIdea = $(event.currentTarget);
                    var id = $showModalCreateIdea.data('id');
                    var type = $showModalCreateIdea.data('type');
                    var alias = $showModalCreateIdea.data('user-alias');
                    new IdeaCreateModalView(id, type, alias).show();
                },
                'change / .js-upload-photo': function (event) {
                    event.preventDefault();
                    var $uploadPhoto = $(event.currentTarget);
                    if (!$uploadPhoto.prop('files')[0])
                        return;
                    new Fily.PhotoUploadModalView().showWithPhoto($uploadPhoto);
                },
                'change / .js-select-category': function (event) {
                    var $target = $(event.currentTarget);
                    if ($target.val() == 0) {
                        Fily.Field.Err.show($target, Fily.ERR.CODE.SELECT_INVALID, ['カテゴリ']);
                    }
                    else {
                        Fily.Field.Err.hide($target);
                    }
                }
            };
        };
        return UserView;
    }(Fily.BaseView));
    Fily.UserView = UserView;
    var IdeaCreateModalView = (function (_super) {
        __extends(IdeaCreateModalView, _super);
        function IdeaCreateModalView(id, type, alias) {
            var _this = _super.call(this) || this;
            _this.id = id;
            _this.type = type;
            _this.alias = alias;
            _this.bodyTpl = new Fily.MustacheTpl('idea/create_modal');
            return _this;
        }
        IdeaCreateModalView.prototype.render = function () {
            _super.prototype.render.call(this);
            this.$title = $('form [name=title]', this.$body);
            this.titleCounter = new Fily.InputCounter(this.$title, $('.js-counter--ideatitle', this.$body));
            this.$category = $('form [name=category]', this.$body);
            this.$category.html(Fily.Categories.getInitialOption());
        };
        IdeaCreateModalView.prototype.isUnSelectedCategory = function () {
            return this.$category.val() == 0;
        };
        IdeaCreateModalView.prototype.done = function (event) {
            var _this = this;
            var $button = $(event.currentTarget).addClass('is-disabled');
            if (!this.titleCounter.checkCount() || this.isUnSelectedCategory()) {
                $button.removeClass('is-disabled').addClass('is-error');
                if (!this.titleCounter.checkRequire()) {
                    Fily.Field.Err.show(this.$title, Fily.ERR.CODE.INPUT_EMPTY, ['タイトル']);
                }
                else if (!this.titleCounter.checkLength()) {
                    Fily.Field.Err.show(this.$title, Fily.ERR.CODE.INPUT_MAX_LENGTH_INVALID, ['タイトル', this.titleCounter.maxLength.toString()]);
                }
                if (this.isUnSelectedCategory()) {
                    Fily.Field.Err.show(this.$category, Fily.ERR.CODE.SELECT_INVALID, ['カテゴリ']);
                }
                setTimeout(function () {
                    $button.removeClass('is-error');
                }, 1000);
                return;
            }
            var categoryId = this.$category.val();
            var publish = $('form [name=published]', this.$body).prop('checked');
            Fily.showFullLoading();
            Fily.Api.Idea.create(this.$title.val(), categoryId, publish, this.alias).done(function (res) {
                location.href = '/idea/' + res['id'] + '/edit/';
                _this.hide();
            }).fail(function (err) {
                Fily.Ngword.checkInput([_this.$title]).done(function () { return alert(Fily.ERR.toString(Fily.ERR.CODE.FORM_ERROR)); });
                $button.removeClass('is-disabled').addClass('is-error');
                setTimeout(function () {
                    $button.removeClass('is-error');
                }, 1000);
            }).always(function () { return Fily.hideFullLoading(); });
        };
        return IdeaCreateModalView;
    }(Fily.ModalView));
})(Fily || (Fily = {}));
