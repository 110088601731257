var Fily;
(function (Fily) {
    var Api;
    (function (Api) {
        var Photo;
        (function (Photo) {
            function like(id, flag) {
                if (flag === void 0) { flag = true; }
                if (flag) {
                    return Api.send('photo.' + id + '.like', {}, 'POST').done(function () {
                        Fily.GA.setDimension('pht_' + id);
                        Fily.GA.sendEvent('レスポンス', 'いいね', '追加', 1);
                    });
                }
                return Api.send('photo.' + id + '.like', {}, 'DELETE').done(function () {
                    Fily.GA.setDimension('pht_' + id);
                    Fily.GA.sendEvent('レスポンス', 'いいね', '削除', 0);
                });
            }
            Photo.like = like;
            function likes(id, limit, offset) {
                if (limit === void 0) { limit = 10; }
                if (offset === void 0) { offset = 0; }
                return Api.send('photo.' + id + '.likes', {
                    limit: limit,
                    offset: offset
                }, 'GET');
            }
            Photo.likes = likes;
            function clip(ideaId, element) {
                return Api.Idea.Element.create(ideaId, Api.Idea.Element.Type.photo, element).done(function () {
                    Fily.GA.setDimension('pht_' + ideaId);
                    Fily.GA.sendEvent('レスポンス', 'クリップ', '追加', 1);
                });
            }
            Photo.clip = clip;
            function upload(params) {
                return Api.send('photo.entry', [params], 'POST').done(function () {
                    Fily.GA.sendEvent('コンテンツ', 'フォト', 'オープン:作成', 1);
                    if (params.contest_id) {
                        Fily.GA.sendEvent('コンテスト', params.contest_id, '応募', 1);
                    }
                });
            }
            Photo.upload = upload;
            function edit(id, params) {
                return Api.send('photo.' + id, params, 'PUT');
            }
            Photo.edit = edit;
            function remove(id) {
                return Api.send('photo.' + id, {}, 'DELETE').done(function () {
                    Fily.GA.sendEvent('コンテンツ', 'フォト', 'クローズ:削除', 0);
                });
            }
            Photo.remove = remove;
            function getCategories() {
                return Api.send('photo.categories', 'GET');
            }
            Photo.getCategories = getCategories;
            function getAreas() {
                return Api.send('photo.areas', 'GET');
            }
            Photo.getAreas = getAreas;
        })(Photo = Api.Photo || (Api.Photo = {}));
    })(Api = Fily.Api || (Fily.Api = {}));
})(Fily || (Fily = {}));
