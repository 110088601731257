var Fily;
(function (Fily) {
    var AutoLoader = (function () {
        function AutoLoader(params) {
            this.eventType = params.eventType;
            this.eventName = params.eventName ? params.eventName : 'scroll.autoload';
            this.limit = params.limit;
            this.offset = params.offset;
            this.isPage = params.isPage ? params.isPage : false;
            this.intervalLoad = params.intervalLoad ? params.intervalLoad : 0;
            this.$targetWrapper = params.$targetWrapper ? params.$targetWrapper : $(window);
            this.$target = params.$target;
            this.$syncTarget = params.$syncTarget ? params.$syncTarget : null;
            this.$loader = params.$loader;
            this.sender = params.sender;
            this.dataFormat = params.dataFormat;
            this.tpl = params.tpl;
            this.syncTpl = params.syncTpl ? params.syncTpl : null;
            this.hasNext = true;
            this.isLoading = false;
            this.delegateEvent(params.eventType);
        }
        AutoLoader.init = function (params) {
            return new AutoLoader(params);
        };
        AutoLoader.prototype.delegateEvent = function (eventType) {
            var _this = this;
            switch (eventType) {
                case 'scroll':
                    this.$targetWrapper.on(this.eventName, function () {
                        var targetWrapperBody = _this.$targetWrapper.get(0);
                        if (_this.isLoadMore(targetWrapperBody.scrollHeight - targetWrapperBody.offsetHeight - _this.intervalLoad, targetWrapperBody.scrollTop)) {
                            _this.loadMore();
                        }
                    });
                    break;
                case 'scrollWindowAndSync':
                    this.$targetWrapper.on(this.eventName, function () {
                        if (_this.isLoadMore(($(document).innerHeight() - _this.$targetWrapper.innerHeight()), _this.$targetWrapper.scrollTop())) {
                            _this.loadMorAndSync();
                        }
                    });
                    break;
            }
        };
        AutoLoader.prototype.isLoadMore = function (value, scrollTop) {
            if (value >= 0) {
                if (value <= scrollTop) {
                    return true;
                }
            }
            return false;
        };
        AutoLoader.prototype.loadMore = function () {
            var _this = this;
            if (!this.hasNext || this.isLoading) {
                return;
            }
            this.isLoading = true;
            Fily.showLoading(this.$loader);
            $.when(this.sender(this.offset, this.limit), this.tpl.load()).done(function (res) {
                var formattedResponse = _this.dataFormat(res, _this.limit);
                _this.$target.append(_this.tpl.render(formattedResponse));
                Fily.hideLoading(_this.$loader);
                if (_this.isPage) {
                    _this.offset++;
                }
                else {
                    _this.offset += _this.limit;
                }
                if (formattedResponse.length < _this.limit) {
                    _this.hasNext = false;
                    _this.$loader.hide();
                }
                _this.isLoading = false;
            });
        };
        AutoLoader.prototype.loadMorAndSync = function () {
            var _this = this;
            if (!this.hasNext || this.isLoading) {
                return;
            }
            this.isLoading = true;
            Fily.showLoading(this.$loader);
            $.when(this.sender(this.offset, this.limit), this.tpl.load(), this.syncTpl.load()).done(function (res) {
                var formattedResponse = _this.dataFormat(res, _this.limit, _this.offset);
                _this.$target.append(_this.tpl.render(formattedResponse));
                _this.$syncTarget.append(_this.syncTpl.render(formattedResponse));
                Fily.hideLoading(_this.$loader);
                if (_this.isPage) {
                    _this.offset++;
                }
                else {
                    _this.offset += _this.limit;
                }
                if (formattedResponse.length < _this.limit) {
                    _this.hasNext = false;
                }
                _this.isLoading = false;
            });
        };
        return AutoLoader;
    }());
    Fily.AutoLoader = AutoLoader;
})(Fily || (Fily = {}));
