var Fily;
(function (Fily) {
    var AdLazyLoader;
    (function (AdLazyLoader) {
        var AdDocument = (function () {
            function AdDocument() {
            }
            AdDocument.init = function () {
                if (!this._instance) {
                    this._instance = new AdDocument();
                }
                return this._instance;
            };
            AdDocument.prototype.lazyLoad = function (adElementId, waitMs) {
                if (waitMs === void 0) { waitMs = 0; }
                var adElm = document.querySelector("[data-ad=\"" + adElementId + "\"]");
                if (!adElm)
                    return;
                adElm.removeAttribute('data-ad');
                adElm.setAttribute('id', adElementId);
                this.execGoogletagDisplay(adElementId);
            };
            AdDocument.prototype.execGoogletagDisplay = function (adElementId) {
                if (!window.googletag)
                    return;
                window.googletag.cmd.push(function () {
                    window.googletag.display(adElementId);
                });
                if (!Fily.Config.isProduction)
                    console.log("display: " + adElementId);
            };
            return AdDocument;
        }());
        AdLazyLoader.AdDocument = AdDocument;
    })(AdLazyLoader = Fily.AdLazyLoader || (Fily.AdLazyLoader = {}));
})(Fily || (Fily = {}));
