var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var Fily;
(function (Fily) {
    var GoodToFollowView = (function (_super) {
        __extends(GoodToFollowView, _super);
        function GoodToFollowView() {
            var _this = _super.call(this, { delegate: false }) || this;
            _this.showCount = 5;
            _this.offset = 0;
            _this.limit = 10;
            _this.clickFlag = false;
            _super.prototype.delegateEvent.call(_this);
            _this.tpl = new Fily.MustacheTpl('user/good_to_follow-item');
            _this.$section = $('#js-recommendUsers');
            _this.getUsers();
            return _this;
        }
        GoodToFollowView.prototype.events = function () {
            return {
                'click / .js-followGoodToFollow': this.follow
            };
        };
        GoodToFollowView.prototype.getUsers = function () {
            var _this = this;
            Fily.Api.User.goodToFollow(this.offset, this.limit).done(function (res) {
                if (!res)
                    return;
                _this.offset += res.length;
                if (!_this.userlist) {
                    _this.userlist = Fily.Util.Array.shuffle(res);
                    _this.restUserCount = _this.userlist.length;
                }
                else {
                    _this.userlist = _this.userlist.concat(Fily.Util.Array.shuffle(res));
                }
                _this.tpl.load().done(function () {
                    _this.showedUsersIndex = _this.gaIndex = _this.showCount;
                    _this.$section.html(_this.userlist.slice(0, _this.showCount).map(function (u, i) {
                        u['ga_index'] = i + 1;
                        return _this.tpl.render(u);
                    }).join(''));
                });
            });
        };
        GoodToFollowView.prototype.getNext = function () {
            var _this = this;
            Fily.Api.User.goodToFollow(this.offset, 1).done(function (res) {
                if (!res)
                    return;
                var userIndex = Fily.Util.Array.findIndex(_this.userlist, function (u) {
                    return !!u && u.alias === res[0].alias;
                });
                _this.offset += 1;
                if (userIndex > -1)
                    return;
                _this.userlist = _this.userlist.concat(Fily.Util.Array.shuffle(res[0]));
            });
        };
        GoodToFollowView.prototype.follow = function (e) {
            var _this = this;
            if (!Fily.Myself.isLogin()) {
                location.href = '/signup/';
                return;
            }
            if (this.clickFlag)
                return;
            this.clickFlag = true;
            var followingClass = 'is-following';
            var $target = $(e.currentTarget);
            var id = $target.data('user-id');
            var alias = $target.data('user-alias');
            var userIndex = Fily.Util.Array.findIndex(this.userlist, function (u) {
                return u.alias === alias;
            });
            if (userIndex < 0)
                return;
            $target.addClass(followingClass);
            this.getNext();
            Fily.FollowView.follow(alias, id, function (res) {
                $target.parents('li').fadeOut('normal', function () {
                    var u = _this.userlist[_this.showedUsersIndex];
                    _this.gaIndex += 1;
                    u['ga_index'] = _this.gaIndex;
                    _this.$section.append(_this.tpl.render(u));
                    _this.restUserCount -= 1;
                    _this.clickFlag = false;
                    _this.userlist = _this.userlist.filter(function (u) {
                        return u.alias !== alias;
                    });
                });
            });
        };
        return GoodToFollowView;
    }(Fily.BaseView));
    Fily.GoodToFollowView = GoodToFollowView;
})(Fily || (Fily = {}));
