var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var Fily;
(function (Fily) {
    var NotificationType;
    (function (NotificationType) {
        NotificationType[NotificationType["FOLLOW"] = 1] = "FOLLOW";
        NotificationType[NotificationType["LIKE_MY_PHOTO"] = 2] = "LIKE_MY_PHOTO";
        NotificationType[NotificationType["LIKE_MY_IDEA"] = 4] = "LIKE_MY_IDEA";
        NotificationType[NotificationType["COMMENT_MY_IDEA"] = 5] = "COMMENT_MY_IDEA";
        NotificationType[NotificationType["COMMENT_MY_PHOTO"] = 6] = "COMMENT_MY_PHOTO";
        NotificationType[NotificationType["RE_COMMENT_IDEA"] = 7] = "RE_COMMENT_IDEA";
        NotificationType[NotificationType["RE_COMMENT_PHOTO"] = 8] = "RE_COMMENT_PHOTO";
        NotificationType[NotificationType["CLIP_MY_PHOTO"] = 9] = "CLIP_MY_PHOTO";
        NotificationType[NotificationType["CLIP_MY_IDEA"] = 11] = "CLIP_MY_IDEA";
        NotificationType[NotificationType["LIKE_MY_COMMENT"] = 12] = "LIKE_MY_COMMENT";
        NotificationType[NotificationType["POST_IDEA"] = 13] = "POST_IDEA";
        NotificationType[NotificationType["POST_PHOTO"] = 14] = "POST_PHOTO";
        NotificationType[NotificationType["LIKE_MY_COMMENT_IDEA"] = 15] = "LIKE_MY_COMMENT_IDEA";
        NotificationType[NotificationType["LIKE_MY_COMMENT_PHOTO"] = 16] = "LIKE_MY_COMMENT_PHOTO";
        NotificationType[NotificationType["COMMENT_COMMENTED_CONTENT_IDEA"] = 17] = "COMMENT_COMMENTED_CONTENT_IDEA";
        NotificationType[NotificationType["COMMENT_COMMENTED_CONTENT_PHOTO"] = 18] = "COMMENT_COMMENTED_CONTENT_PHOTO";
        NotificationType[NotificationType["COMMENT_MY_CONTENT_IDEA"] = 19] = "COMMENT_MY_CONTENT_IDEA";
        NotificationType[NotificationType["COMMENT_MY_CONTENT_PHOTO"] = 20] = "COMMENT_MY_CONTENT_PHOTO";
        NotificationType[NotificationType["RE_COMMENT_MY_COMMENT_IDEA"] = 21] = "RE_COMMENT_MY_COMMENT_IDEA";
        NotificationType[NotificationType["RE_COMMENT_MY_COMMENT_PHOTO"] = 22] = "RE_COMMENT_MY_COMMENT_PHOTO";
    })(NotificationType = Fily.NotificationType || (Fily.NotificationType = {}));
    var Notification = (function (_super) {
        __extends(Notification, _super);
        function Notification() {
            var _this = _super.call(this, { delegate: false }) || this;
            _this.$notification = $('#notifications');
            _this.$notify = $('.js-notify');
            if (Fily.Myself.isLogin()) {
                _this.$activitySwitch = $('.js-activity-notification');
                _this.$serviceSwitch = $('.js-service-notification');
            }
            _this.init();
            _this.delegateEvent();
            _this.getNewCount();
            return _this;
        }
        Notification.prototype.events = function () {
            var _this = this;
            return {
                'click / .js-show--notifications, .js-close--notificationsBg': function (event) {
                    _this.toggleNotification();
                    if (_this.$notification.hasClass('is-active')) {
                        _this.init();
                        if (Fily.Myself.isLogin()) {
                            _this.read();
                            _this.loadNotifications(_this.activities);
                            _this.loadNotifications(_this.services);
                        }
                        else {
                            _this.loadNotifications(_this.services);
                        }
                    }
                },
                'click keypress / .js-notifyTab': function (event) {
                    event.stopPropagation();
                    if (typeof event.keyCode === 'undefined' || event.keyCode === 13) {
                        var $target = $(event.currentTarget);
                        _this.show($target.parent());
                    }
                }
            };
        };
        Notification.prototype.show = function ($switchItem) {
            if ($switchItem === void 0) { $switchItem = this.$activitySwitch; }
            if (Fily.Myself.isLogin()) {
                this.$activitySwitch.removeClass('is-current');
                this.$serviceSwitch.removeClass('is-current');
                $switchItem.addClass('is-current').removeClass('is-new');
                var panelId = '#' + $switchItem.find('.js-notifyTab').attr('aria-controls');
                $('.js-notifyPanel').hide().filter(panelId).show();
            }
            else {
                $('#notifications-panel-service').show();
            }
        };
        Notification.prototype.getNewCount = function () {
            var _this = this;
            if (Fily.Myself.isLogin()) {
                Fily.Api.Notification.getNewCount().done(function (res) {
                    var activityCount = parseInt(res['new_count']);
                    var serviceCount = parseInt(res['service_count']);
                    var totalCount = activityCount + serviceCount;
                    if (activityCount)
                        _this.$activitySwitch.addClass('is-new');
                    if (serviceCount)
                        _this.$serviceSwitch.addClass('is-new');
                    if (totalCount) {
                        $('.js-notification-count').html(totalCount > 99 ? '99+' : totalCount.toString());
                        _this.$notify.removeClass('is-read');
                    }
                });
            }
        };
        Notification.prototype.init = function () {
            if (Fily.Myself.isLogin()) {
                this.activities = {
                    notices: [],
                    hasNext: true,
                    isLoading: false,
                    $list: $('#notifications-panel-activity'),
                    api: Fily.Api.Notification.getActivity,
                    tpl: new Fily.MustacheTpl('notification')
                };
                this.services = {
                    notices: [],
                    hasNext: true,
                    isLoading: false,
                    $list: $('#notifications-panel-service'),
                    api: Fily.Api.Notification.getService,
                    tpl: new Fily.MustacheTpl('notification_service')
                };
            }
            else {
                this.services = {
                    notices: [],
                    hasNext: true,
                    isLoading: false,
                    $list: $('#notifications-panel-service'),
                    api: Fily.Api.Notification.getService,
                    tpl: new Fily.MustacheTpl('notification_service')
                };
            }
            $('.js-notifyPanel').empty().html($('#js-loaderTpl').html());
        };
        Notification.prototype.toggleNotification = function () { };
        Notification.prototype.read = function () {
            if (!this.$notify.hasClass('is-read')) {
                this.$notify.addClass('is-read');
                Fily.Api.Notification.read();
            }
        };
        Notification.prototype.loadNotifications = function (notifications) {
            var _this = this;
            if (notifications.isLoading || !notifications.hasNext)
                return;
            notifications.isLoading = true;
            $.when(notifications.api(notifications.notices.length), notifications.tpl.load())
                .always(function () {
                notifications.isLoading = false;
                if (!notifications.notices.length)
                    notifications.$list.empty();
            })
                .done(function (res) {
                notifications.hasNext = res['has_next'];
                var notices = res['notices'];
                var noticeTpls = notices.map(function (n, i) {
                    n['url'] = _this.getNotificationLink(n);
                    n['ga_index'] = i + 1;
                    return notifications.tpl.render(n);
                });
                Array.prototype.push.apply(notifications.notices, notices);
                notifications.$list.append(noticeTpls.join(''));
                if (!notifications.notices.length)
                    notifications.$list.html('<div class="Notifications-empty">お知らせはありません</div>');
            });
        };
        Notification.prototype.getNotificationLink = function (notification) {
            if ('url' in notification)
                return notification['url'];
            switch (parseInt(notification['type'])) {
                case NotificationType.FOLLOW:
                    return '/user/' + notification['user']['alias'] + '/';
                case NotificationType.COMMENT_MY_IDEA:
                case NotificationType.RE_COMMENT_IDEA:
                case NotificationType.COMMENT_COMMENTED_CONTENT_IDEA:
                case NotificationType.COMMENT_MY_CONTENT_IDEA:
                case NotificationType.RE_COMMENT_MY_COMMENT_IDEA:
                    return '/idea/' + notification['material_id'] + '/#Comment';
                case NotificationType.LIKE_MY_IDEA:
                case NotificationType.CLIP_MY_IDEA:
                case NotificationType.POST_IDEA:
                    return '/idea/' + notification['material_id'] + '/';
                case NotificationType.LIKE_MY_PHOTO:
                case NotificationType.CLIP_MY_PHOTO:
                case NotificationType.POST_PHOTO:
                    return '/photo/' + notification['material_id'] + '/';
                case NotificationType.COMMENT_MY_PHOTO:
                case NotificationType.RE_COMMENT_PHOTO:
                case NotificationType.COMMENT_COMMENTED_CONTENT_PHOTO:
                case NotificationType.COMMENT_MY_CONTENT_PHOTO:
                case NotificationType.RE_COMMENT_MY_COMMENT_PHOTO:
                    return '/photo/' + notification['material_id'] + '/#Comment';
                case NotificationType.LIKE_MY_COMMENT:
                case NotificationType.LIKE_MY_COMMENT_IDEA:
                case NotificationType.LIKE_MY_COMMENT_PHOTO:
                    return '/' + notification['target_content_type'] + '/' + notification['target_id'] + '/#Comment';
                default:
                    return '';
            }
        };
        return Notification;
    }(Fily.BaseView));
    Fily.Notification = Notification;
})(Fily || (Fily = {}));
