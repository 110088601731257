var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var Fily;
(function (Fily) {
    var Expander = (function (_super) {
        __extends(Expander, _super);
        function Expander() {
            var _this = _super.call(this, { delegate: false }) || this;
            _this.expanderViewContentHight = 17.874374 * 2;
            _this.delegateEvent();
            _this.initExpanderTrigger();
            return _this;
        }
        Expander.prototype.initExpanderTrigger = function () {
            var _this = this;
            var $targetlist = this.$body.find('.js-expanderTarget');
            [].forEach.call($targetlist, function (target) {
                var $t = $(target);
                if ($t.find('.js-expanderContent').height() > _this.expanderViewContentHight) {
                    setTimeout(function () {
                        $t.siblings('.js-expanderTrigger').addClass('is-active');
                    }, 10);
                }
                else {
                    setTimeout(function () {
                        $t.siblings('.js-expanderTrigger').remove();
                    }, 10);
                }
            });
        };
        Expander.prototype.events = function () {
            return {
                'mousedown / .js-expanderTrigger': function (e) {
                    var $target = $(e.currentTarget).siblings('.js-expanderTarget');
                    var targetContentHeight = $target.find('.js-expanderContent').height();
                    setTimeout(function () {
                        $target.addClass('is-expanded');
                    }, 300);
                    $target.height(targetContentHeight);
                }
            };
        };
        return Expander;
    }(Fily.BaseView));
    Fily.Expander = Expander;
})(Fily || (Fily = {}));
