var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var Fily;
(function (Fily) {
    var HotKeywordView = (function (_super) {
        __extends(HotKeywordView, _super);
        function HotKeywordView(params) {
            var _this = _super.call(this, {}) || this;
            _this.simpleTpl = new Fily.MustacheTpl('keyword/simple_item');
            var htmlId = params.id ? params.id : 'js-headerkeyword';
            $.when(Fily.Api.Keywords.getRecommend(), _this.simpleTpl.load())
                .done(function (res) {
                if (res) {
                    $("#" + htmlId).html(res.map(function (item, i) {
                        item['noindex'] = (item['noindex'] == 0) ? null : item['noindex'];
                        return _this.simpleTpl.render(_.extend({ ga_label: '話題のキーワード', ga_index: i + 1 }, item));
                    }).join(''));
                }
            });
            return _this;
        }
        return HotKeywordView;
    }(Fily.BaseView));
    Fily.HotKeywordView = HotKeywordView;
})(Fily || (Fily = {}));
