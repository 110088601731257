var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var Fily;
(function (Fily) {
    var IdeaEditOfficialElementModalView = (function (_super) {
        __extends(IdeaEditOfficialElementModalView, _super);
        function IdeaEditOfficialElementModalView(ideaId, options) {
            var _this = _super.call(this, options) || this;
            _this.ideaId = ideaId;
            _this.maxListElements = 20;
            _this.isOfficialUser = (_this.params.userClassification && _this.params.userClassification === 'official');
            return _this;
        }
        IdeaEditOfficialElementModalView.prototype.events = function () {
            return $.extend(_super.prototype.events.call(this), {
                'change / .js-upload-avatar': 'uploadAvator',
                'change / .js-select-table-csv': 'selectTableStructureCsv',
                'click / .js-addList': 'addList',
                'click / .js-removeList': 'removeList',
            });
        };
        IdeaEditOfficialElementModalView.prototype.render = function () {
            var _this = this;
            _super.prototype.render.call(this);
            this.$inputSpeakerTitle = $('[name=speakertitle]', this.$body);
            this.$inputSpeakerName = $('[name=speakername]', this.$body);
            this.$inputBubbleTitle = $('[name=bubbletitle]', this.$body);
            this.$inputBubbleDescription = $('[name=bubbledescription]', this.$body);
            this.$inputCreditUrl = $('[name=crediturl]', this.$body);
            this.$radioBubblePosition = $('[name=bubbleposition]', this.$body);
            this.$uploadAvatar = $('[name=data]', this.$body);
            this.$listType = $('[name=listtype]', this.$body);
            this.$list = $('[name=list]', this.$body);
            this.$listsBlock = $('.js-listsBlock', this.$body);
            this.$listBlock = $('.js-listBlock', this.$body);
            if (this.params.id) {
                if (this.params.element_type === Fily.Api.Idea.Element.Type.speech_bubble) {
                    this.$inputSpeakerTitle.val(this.params.speaker_title);
                    this.$inputSpeakerName.val(this.params.speaker_name);
                    this.$inputBubbleTitle.val(this.params.title);
                    this.$inputBubbleDescription.val(this.params.description);
                    this.$inputCreditUrl.val(this.params.credit_url);
                    this.$radioBubblePosition.val([this.params.display_position]);
                    $('.js-upload-photoarea').html('<img src="' + this.params.speaker_photo + '">');
                    this.speakerTitleCounter = new Fily.InputCounter(this.$inputSpeakerTitle, $('.js-counter-speakertitle', this.$body));
                    this.speakerNameCounter = new Fily.InputCounter(this.$inputSpeakerName, $('.js-counter-speakername', this.$body));
                    this.bubbleTitleCounter = new Fily.InputCounter(this.$inputBubbleTitle, $('.js-counter-bubbletitle', this.$body));
                    this.bubbleDescriptionCounter = new Fily.InputCounter(this.$inputBubbleDescription, $('.js-counter-bubbledescription', this.$body));
                    this.crediturlCounter = new Fily.InputCounter(this.$inputCreditUrl, $('.js-counter-crediturl', this.$body));
                    var $preview = $('.js-upload-photoarea');
                    this.uploader = new Fily.PhotoUploader($preview);
                    this.sumBytes = 0;
                }
                else if (this.params.element_type === Fily.Api.Idea.Element.Type.list) {
                    this.$listType.val([this.params.list_type]);
                    this.$list.val(this.params.items[0]);
                    if (this.params.items.length === this.maxListElements) {
                        $('.js-addList').hide();
                    }
                    $.each(this.params.items, function (i, val) {
                        if (i > 0) {
                            var cloneBlock = _this.$listBlock.clone(true);
                            _this.$listsBlock.append(cloneBlock);
                            _this.$listBlock.find('.js-removeButton').show();
                            cloneBlock.find('.js-removeButton').show();
                            cloneBlock.find($('input[name="list"]')).attr('name', 'list' + i).val(val);
                            cloneBlock.find($('.js-counter-list')).removeClass('js-counter-list').addClass('js-counter-list' + i);
                            new Fily.InputCounter($('input[name="list' + i + '"]'), $('.js-counter-list' + i, _this.$body));
                        }
                    });
                    this.listCounter = new Fily.InputCounter(this.$list, $('.js-counter-list', this.$body));
                }
            }
            else {
                this.speakerTitleCounter = new Fily.InputCounter(this.$inputSpeakerTitle, $('.js-counter-speakertitle', this.$body));
                this.speakerNameCounter = new Fily.InputCounter(this.$inputSpeakerName, $('.js-counter-speakername', this.$body));
                this.bubbleTitleCounter = new Fily.InputCounter(this.$inputBubbleTitle, $('.js-counter-bubbletitle', this.$body));
                this.bubbleDescriptionCounter = new Fily.InputCounter(this.$inputBubbleDescription, $('.js-counter-bubbledescription', this.$body));
                this.crediturlCounter = new Fily.InputCounter(this.$inputCreditUrl, $('.js-counter-crediturl', this.$body));
                this.listCounter = new Fily.InputCounter(this.$list, $('.js-counter-list', this.$body));
                var $preview = $('.js-upload-photoarea');
                this.uploader = new Fily.PhotoUploader($preview);
                this.sumBytes = 0;
            }
        };
        IdeaEditOfficialElementModalView.prototype.done = function (event) {
            var _this = this;
            var $target = $(event.currentTarget);
            if ($target.prop('disabled'))
                return;
            var invalid = false;
            if ($target.hasClass('js-speechBubbleDone')) {
                var $selectPhoto = $('.js-upload-avatar');
                Fily.Field.Err.hide($selectPhoto);
                Fily.Field.Err.hide(this.$inputCreditUrl);
                if (!this.params.id && !this.uploader.uploadImageDataString) {
                    Fily.Field.Err.show($selectPhoto, Fily.ERR.CODE.SELECT_INVALID, ['画像ファイル']);
                    invalid = true;
                }
                else if (!this.params.id && !Fily.Util.checkImageFileSize($selectPhoto.prop('files')[0])) {
                    Fily.Field.Err.show($selectPhoto, Fily.ERR.CODE.IMAGE_SIZE_INVALID);
                    invalid = true;
                }
                if (this.$inputCreditUrl.val() && !Fily.Util.isUrl(this.$inputCreditUrl.val())) {
                    Fily.Field.Err.show(this.$inputCreditUrl, Fily.ERR.CODE.INPUT_INVALID, ['出典（URL）']);
                    invalid = true;
                }
                else if (Fily.Denied.isDeniedPhotoUrl(this.$inputCreditUrl.val())) {
                    Fily.Field.Err.show(this.$inputCreditUrl, Fily.ERR.CODE.DENIED_PHOTO_URL);
                    invalid = true;
                }
                if (!this.crediturlCounter.checkLength()) {
                    Fily.Field.Err.show(this.$inputCreditUrl, Fily.ERR.CODE.INPUT_MAX_LENGTH_INVALID, ['出典（URL）', this.crediturlCounter.maxLength.toString()]);
                    invalid = true;
                }
                if (!this.speakerTitleCounter.checkLength()) {
                    Fily.Field.Err.show(this.$inputSpeakerTitle, Fily.ERR.CODE.INPUT_MAX_LENGTH_INVALID, ['職種・肩書', this.speakerTitleCounter.maxLength.toString()]);
                    invalid = true;
                }
                if (!this.speakerNameCounter.checkLength()) {
                    Fily.Field.Err.show(this.$inputSpeakerName, Fily.ERR.CODE.INPUT_MAX_LENGTH_INVALID, ['名前', this.speakerNameCounter.maxLength.toString()]);
                    invalid = true;
                }
                if (!this.bubbleTitleCounter.checkLength()) {
                    Fily.Field.Err.show(this.$inputBubbleTitle, Fily.ERR.CODE.INPUT_MAX_LENGTH_INVALID, ['コメント見出し', this.bubbleTitleCounter.maxLength.toString()]);
                    invalid = true;
                }
                if (!this.bubbleDescriptionCounter.checkLength()) {
                    Fily.Field.Err.show(this.$inputBubbleDescription, Fily.ERR.CODE.INPUT_MIN_MAX_LENGTH_INVALID, ['コメント本文', this.bubbleDescriptionCounter.minLength.toString(), this.bubbleDescriptionCounter.maxLength.toString()]);
                    invalid = true;
                }
                if (invalid) {
                    $target.addClass('is-error');
                    setTimeout(function () {
                        $target.removeClass('is-error');
                    }, 1000);
                    return;
                }
                $target.prop('disabled', true);
                Fily.showFullLoading();
                this.$radioBubblePosition = $('[name=bubbleposition]:checked', this.$body);
                var speechBubbleParam = {
                    speaker_photo: this.uploader.uploadImageDataString,
                    speaker_name: this.$inputSpeakerName.val(),
                    speaker_title: this.$inputSpeakerTitle.val(),
                    title: this.$inputBubbleTitle.val(),
                    description: this.$inputBubbleDescription.val(),
                    credit_url: this.$inputCreditUrl.val(),
                    display_position: this.$radioBubblePosition.val(),
                };
                var sendApi = void 0;
                if (this.params.id) {
                    sendApi = Fily.Api.Idea.Element.edit(this.ideaId, this.params.id, {
                        speech_bubble: speechBubbleParam
                    });
                }
                else {
                    sendApi = Fily.Api.Idea.Element.create(this.ideaId, Fily.Api.Idea.Element.Type.speech_bubble, {
                        speech_bubble: speechBubbleParam
                    }, this.params.position);
                }
                sendApi.done(function (res) {
                    _super.prototype.done.call(_this, res);
                }).fail(function () { return Fily.Ngword.checkInput([_this.$inputSpeakerTitle, _this.$inputSpeakerName, _this.$inputBubbleTitle, _this.$inputBubbleDescription, _this.$inputCreditUrl]); })
                    .always(function () {
                    $target.prop('disabled', false);
                    Fily.hideFullLoading();
                });
            }
            else if ($target.hasClass('js-addListDone')) {
                var listItems_1 = [];
                $.each($('.js-listsBlock li'), function (i, val) {
                    var listInput = $(val).find($('.js-listInput'));
                    var listInputVal = { "body": listInput.val() };
                    _this.listInputCounter = new Fily.InputCounter(listInput, $(val).find($('.js-counter-list' + i)));
                    if (!_this.listInputCounter.checkLength()) {
                        Fily.Field.Err.show(listInput, Fily.ERR.CODE.INPUT_MIN_MAX_LENGTH_INVALID, ['リスト項目', _this.listInputCounter.minLength.toString(), _this.listInputCounter.maxLength.toString()]);
                        invalid = true;
                    }
                    listItems_1.push(listInputVal);
                });
                if (invalid) {
                    $target.addClass('is-error');
                    setTimeout(function () {
                        $target.removeClass('is-error');
                    }, 1000);
                    return;
                }
                $target.prop('disabled', true);
                Fily.showFullLoading();
                this.$listType = $('[name=listtype]:checked', this.$body);
                var listParam = {
                    list_type: Number(this.$listType.val()),
                    items: listItems_1,
                };
                var sendApi = void 0;
                if (this.params.id) {
                    sendApi = Fily.Api.Idea.Element.edit(this.ideaId, this.params.id, {
                        list: listParam
                    });
                }
                else {
                    sendApi = Fily.Api.Idea.Element.create(this.ideaId, Fily.Api.Idea.Element.Type.list, {
                        list: listParam
                    }, this.params.position);
                }
                sendApi.done(function (res) {
                    _super.prototype.done.call(_this, res);
                }).fail(function () {
                    $.each($('.js-listsBlock li'), function (i, val) {
                        var listInput = $(val).find($('.js-listInput'));
                        Fily.Ngword.checkInput([listInput]);
                    });
                })
                    .always(function () {
                    $target.prop('disabled', false);
                    Fily.hideFullLoading();
                });
            }
            else if ($target.hasClass('js-addTableDone')) {
                $target.prop('disabled', true);
                Fily.showFullLoading();
                var file = $('.js-select-table-csv').prop('files')[0];
                var fileReader_1 = new FileReader();
                if (typeof file !== "undefined") {
                    fileReader_1.readAsText(file);
                }
                var formattedArrayData_1 = [];
                var tableHeadColorArray_1;
                fileReader_1.onload = function () {
                    formattedArrayData_1 = $["csv"].toArrays(fileReader_1.result, { onPreParse: function (csv, state) {
                            var lines = csv.split(/[\r]*\n/);
                            tableHeadColorArray_1 = lines.shift().split(",");
                            return lines.join("\n");
                        }
                    });
                    if (formattedArrayData_1.length === 0 || formattedArrayData_1[0].length <= 1) {
                        Fily.Field.Err.show($target, Fily.ERR.CODE.INPUT_INVALID, ['CSV内容が1列のみのための登録できません。CSV内容']);
                        $target.prop('disabled', true);
                        Fily.hideFullLoading();
                        return;
                    }
                    var tableStructureParam;
                    if (tableHeadColorArray_1[0] && tableHeadColorArray_1[1]) {
                        tableStructureParam = {
                            col_background_color_code: null,
                            row_background_color_code: tableHeadColorArray_1[0] ? tableHeadColorArray_1[0] : null,
                            content: formattedArrayData_1,
                        };
                    }
                    else {
                        tableStructureParam = {
                            col_background_color_code: tableHeadColorArray_1[1] ? tableHeadColorArray_1[1] : null,
                            row_background_color_code: tableHeadColorArray_1[0] ? tableHeadColorArray_1[0] : null,
                            content: formattedArrayData_1,
                        };
                    }
                    var sendApi;
                    if (_this.params.id) {
                        sendApi = Fily.Api.Idea.Element.edit(_this.ideaId, _this.params.id, {
                            table_structure: tableStructureParam
                        });
                    }
                    else {
                        sendApi = Fily.Api.Idea.Element.create(_this.ideaId, Fily.Api.Idea.Element.Type.table_structure, {
                            table_structure: tableStructureParam
                        }, _this.params.position);
                    }
                    sendApi.done(function (res) {
                        _super.prototype.done.call(_this, res);
                    }).fail(function () {
                        Fily.Field.Err.show($target, Fily.ERR.CODE.INPUT_INVALID, ['CSV内容']);
                    }).always(function () {
                        $target.prop('disabled', false);
                        Fily.hideFullLoading();
                    });
                };
            }
        };
        IdeaEditOfficialElementModalView.prototype.selectTableStructureCsv = function (event) {
            var file = event.target.files[0];
            if (typeof file !== "undefined") {
                $('.js-addTableDone').prop('disabled', false);
            }
        };
        IdeaEditOfficialElementModalView.prototype.uploadAvator = function (event) {
            this.uploader.renderPhoto($(event.target).prop('files'));
            Fily.Field.Err.hide(this.$uploadAvatar);
        };
        IdeaEditOfficialElementModalView.prototype.addList = function (event) {
            var _this = this;
            var cloneBlock = this.$listBlock.clone(true);
            $('.js-listsBlock').append(cloneBlock).ready(function () {
                var listLength = $('.js-listBlock').length;
                if (listLength >= 1) {
                    $('.js-listBlock').find('.js-removeButton').show();
                }
                if (listLength <= _this.maxListElements) {
                    cloneBlock.find('.js-listInput').val('').focus();
                    new Fily.InputCounter(cloneBlock.find('.js-listInput'), cloneBlock.find($('.js-counter-list')));
                }
                if (listLength === _this.maxListElements) {
                    $(event.currentTarget).hide();
                }
            });
        };
        IdeaEditOfficialElementModalView.prototype.removeList = function (event) {
            var listLength = $('.js-listBlock').length;
            var target = $(event.target).closest('.js-listBlock');
            if (listLength <= this.maxListElements) {
                this.$body.find('.js-addList').show();
            }
            if (listLength === 2) {
                $(event.target).parents('.js-listsBlock').find('.js-removeButton').hide();
            }
            if (listLength >= 1) {
                target.remove();
            }
        };
        return IdeaEditOfficialElementModalView;
    }(Fily.ModalView));
    Fily.IdeaEditOfficialElementModalView = IdeaEditOfficialElementModalView;
})(Fily || (Fily = {}));
