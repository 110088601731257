var Fily;
(function (Fily) {
    var StreamAutoLoader = (function () {
        function StreamAutoLoader(params) {
            this.hasNext = true;
            this.isLoading = false;
            this.$streamWrapper = params.$streamWrapper ? params.$streamWrapper : $('#js-stream-wrapper');
            this.intervalLoad = params.intervalLoad ? params.intervalLoad : 300;
            this.delegateEvent();
            this.$loader = params.$loader;
            this.$stream = params.$stream ? params.$stream : $('#js-stream');
            this.offset = params.page ? params.page : params.limit;
            this.limit = params.limit;
            this.sender = params.sender;
            this.tpl = params.tpl;
            if (params.page) {
                this.page = params.page;
            }
            if (params.dataFormat) {
                this.dataFormat = params.dataFormat;
            }
        }
        StreamAutoLoader.init = function (params) {
            return new StreamAutoLoader(params);
        };
        StreamAutoLoader.prototype.delegateEvent = function () {
            var _this = this;
            this.$streamWrapper.on('scroll.streamload', function (event) {
                var body = _this.$streamWrapper.get(0);
                if (body.scrollHeight - body.offsetHeight - _this.intervalLoad < body.scrollTop) {
                    _this.loadmore();
                }
            });
        };
        StreamAutoLoader.prototype.loadmore = function () {
            var _this = this;
            if (!this.hasNext || this.isLoading)
                return;
            this.isLoading = true;
            Fily.showLoading(this.$loader);
            $.when(this.sender(this.offset, this.limit), this.tpl.load()).done(function (res) {
                _this.$stream.append(_this.responseToRender(res));
                if (res.list.length < _this.limit) {
                    _this.hasNext = false;
                    Fily.hideLoading(_this.$loader);
                }
                if (_this.page) {
                    _this.offset++;
                }
                else {
                    _this.offset += _this.limit;
                }
                _this.isLoading = false;
            });
        };
        StreamAutoLoader.prototype.dataFormat = function (data) {
            return data;
        };
        StreamAutoLoader.prototype.responseToRender = function (data) {
            return this.tpl.render(this.dataFormat(data));
        };
        return StreamAutoLoader;
    }());
    Fily.StreamAutoLoader = StreamAutoLoader;
})(Fily || (Fily = {}));
