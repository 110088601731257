var Fily;
(function (Fily) {
    var Api;
    (function (Api) {
        var Recipeblog;
        (function (Recipeblog) {
            function getRecipeblog(keyword, limit, offset) {
                if (limit === void 0) { limit = 40; }
                if (offset === void 0) { offset = 0; }
                return Api.send('recipeblog', {
                    q: keyword,
                    limit: limit,
                    offset: offset
                }, 'GET');
            }
            Recipeblog.getRecipeblog = getRecipeblog;
        })(Recipeblog = Api.Recipeblog || (Api.Recipeblog = {}));
    })(Api = Fily.Api || (Fily.Api = {}));
})(Fily || (Fily = {}));
