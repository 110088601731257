var Fily;
(function (Fily) {
    var Api;
    (function (Api) {
        var LifeService;
        (function (LifeService) {
            function areas() {
                return Api.send('life-service.areas', {}, 'GET');
            }
            LifeService.areas = areas;
        })(LifeService = Api.LifeService || (Api.LifeService = {}));
    })(Api = Fily.Api || (Fily.Api = {}));
})(Fily || (Fily = {}));
