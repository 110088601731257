var Fily;
(function (Fily) {
    var Api;
    (function (Api) {
        var Search;
        (function (Search) {
            function getSearch(keyword, content_type, limit, offset) {
                if (limit === void 0) { limit = 40; }
                if (offset === void 0) { offset = 0; }
                return Api.send('search', {
                    q: keyword,
                    content_type: content_type,
                    limit: limit,
                    offset: offset
                }, 'GET');
            }
            Search.getSearch = getSearch;
        })(Search = Api.Search || (Api.Search = {}));
    })(Api = Fily.Api || (Fily.Api = {}));
})(Fily || (Fily = {}));
