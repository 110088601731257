var Fily;
(function (Fily) {
    var PhotoUploader = (function () {
        function PhotoUploader($preview) {
            this.rotation = 1;
            console.assert($preview.length === 1, '$preview element is invalid', $preview.length);
            this.$preview = $preview;
            this.uploadImageDataString = '';
        }
        PhotoUploader.prototype.renderPhoto = function (imageFile) {
            var _this = this;
            var file = imageFile[0] || imageFile;
            if (!file)
                return;
            var img = new Image;
            img.src = Fily.Util.createObjectURL(file);
            img.onload = function () {
                var uri = Fily.Img.orient(img, 1);
                var i = new Image;
                i.src = uri;
                _this.$preview.html("<img src=\"" + uri + "\">").data('original', img);
                _this.uploadImageDataString = uri.split(',')[1];
            };
        };
        PhotoUploader.prototype.renderPhotoBase64 = function (base64Image) {
            var _this = this;
            var img = new Image;
            img.src = "data:image/jpeg;base64," + base64Image;
            img.onload = function () {
                var uri = Fily.Img.orient(img, 1);
                var i = new Image;
                i.src = uri;
                _this.$preview.html("<img src=\"" + uri + "\">").data('original', img);
                _this.uploadImageDataString = uri.split(',')[1];
            };
        };
        PhotoUploader.prototype.rotatePreview = function () {
            var rotation = this.rotation > 3 ? 1 : this.rotation + 1;
            console.assert(rotation > 0 && rotation < 5, 'rotation number is invalid');
            this.$preview.find('img').css(Fily.Util.Css.addVendorPrefix('transform'), "rotate(" + (rotation - 1) * 90 + "deg)");
            this.rotation = rotation;
        };
        PhotoUploader.prototype.rotateUploadPhoto = function () {
            var _this = this;
            var d = $.Deferred();
            var img = this.$preview.data('original');
            setTimeout(function () {
                var dataUri = Fily.Img.orient(img, _this.rotation);
                var i = new Image;
                i.src = dataUri;
                _this.uploadImageDataString = dataUri.split(',')[1];
                d.resolve(_this.uploadImageDataString);
            }, 30);
            return d.promise();
        };
        Object.defineProperty(PhotoUploader.prototype, "uploadImageDataString", {
            get: function () {
                return this._uploadImageDataString;
            },
            set: function (data) {
                this._uploadImageDataString = data;
            },
            enumerable: true,
            configurable: true
        });
        return PhotoUploader;
    }());
    Fily.PhotoUploader = PhotoUploader;
})(Fily || (Fily = {}));
