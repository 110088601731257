var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var Fily;
(function (Fily) {
    var PC;
    (function (PC) {
        var Notification = (function (_super) {
            __extends(Notification, _super);
            function Notification() {
                return _super !== null && _super.apply(this, arguments) || this;
            }
            Notification.prototype.delegateEvent = function () {
                _super.prototype.delegateEvent.call(this);
                if (Fily.Myself.isLogin()) {
                    this.delegateScrollEvent(this.activities);
                    this.delegateScrollEvent(this.services);
                }
                else {
                    this.delegateScrollEvent(this.services);
                }
            };
            Notification.prototype.delegateScrollEvent = function (notifications) {
                var _this = this;
                notifications.$list.on('scroll', function (event) {
                    var list = notifications.$list.get(0);
                    if (list.scrollHeight - list.offsetHeight - 200 < list.scrollTop) {
                        _this.loadNotifications(notifications);
                    }
                    ;
                });
            };
            Notification.prototype.toggleNotification = function () {
                this.$notification.toggleClass('is-active');
                this.$body.focus();
                this.show();
            };
            return Notification;
        }(Fily.Notification));
        PC.Notification = Notification;
    })(PC = Fily.PC || (Fily.PC = {}));
})(Fily || (Fily = {}));
