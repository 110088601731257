var Fily;
(function (Fily) {
    var Api;
    (function (Api) {
        var Amazon;
        (function (Amazon) {
            function getSearchIndex() {
                return Api.send('amazon.search_index', {}, 'GET');
            }
            Amazon.getSearchIndex = getSearchIndex;
            function getItems(keyword, search_index, page) {
                if (search_index === void 0) { search_index = 'All'; }
                if (page === void 0) { page = 1; }
                return Api.send('amazon.itemsweb', {
                    keyword: keyword,
                    search_index: search_index,
                    page: page
                }, 'GET');
            }
            Amazon.getItems = getItems;
        })(Amazon = Api.Amazon || (Api.Amazon = {}));
    })(Api = Fily.Api || (Fily.Api = {}));
})(Fily || (Fily = {}));
