var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var Fily;
(function (Fily) {
    var KeywordEditorView = (function (_super) {
        __extends(KeywordEditorView, _super);
        function KeywordEditorView() {
            var _this = _super.call(this, {}) || this;
            _this.keywordTpl = new Fily.MustacheTpl('keyword/edit/item');
            _this.hasRecommend = false;
            _this.keywordTpl.load();
            return _this;
        }
        KeywordEditorView.prototype.initRecommend = function () {
            this.$recommend = $('#js-recommendKeywords');
            this.hasRecommend = this.$recommend.length > 0;
        };
        KeywordEditorView.prototype.events = function () {
            return {
                'keypress / .js-keyword-input': 'addKeyword',
                'click / .js-keyword-item': 'removeKeyword',
                'click / .js-keyword-add': 'addRecommend'
            };
        };
        KeywordEditorView.prototype.addKeyword = function (event) {
            var _this = this;
            if (event.keyCode !== 13)
                return;
            var $input = $(event.currentTarget);
            var keyword = $input.val().trim();
            if (!keyword)
                return;
            if (Fily.Util.Str.isHalfwidthSymbol(keyword[0])) {
                Fily.Field.Err.show($input, Fily.ERR.CODE.INPUT_KEYWORD_INVALID);
                return;
            }
            else {
                Fily.Field.Err.hide($input);
            }
            var MAX_KEYWORD_LENGTH = $input.data('maxlength');
            if (keyword.length > MAX_KEYWORD_LENGTH) {
                Fily.Field.Err.show($input, Fily.ERR.CODE.INPUT_MAX_LENGTH_INVALID, ['キーワード', MAX_KEYWORD_LENGTH]);
                return;
            }
            var MAX_KEYWORD_NUM = 30;
            if (this.keywords().length >= MAX_KEYWORD_NUM) {
                Fily.Field.Err.show($input, Fily.ERR.CODE.SET_MAX_ITEM_INVALID, ['キーワード', '30ワード']);
                return;
            }
            this.keywordTpl.load().done(function () {
                $('.js-keyword-input--form', _this.$body).before(_this.keywordTpl.render({ name: keyword }));
                $input.val('');
            });
            this.initRecommend();
            if (this.hasRecommend) {
                this.$recommend.find("[data-keyword-name=\"" + keyword + "\"]").addClass('is-added');
            }
            return;
        };
        KeywordEditorView.prototype.removeKeyword = function (event) {
            var $this = $(event.currentTarget);
            this.initRecommend();
            if (this.hasRecommend) {
                this.$recommend.find('[data-keyword-id="' + $this.data('keyword-id') + '"]').removeClass('is-added');
            }
            $this.remove();
        };
        KeywordEditorView.prototype.addRecommend = function (event) {
            var _this = this;
            var $this = $(event.currentTarget);
            if ($this.hasClass('is-added'))
                return;
            this.keywordTpl.load().done(function () {
                $('.js-keyword-input--form', _this.$body).before(_this.keywordTpl.render({ id: $this.data('keyword-id'), name: $this.data('keyword-name') }));
            });
            $this.addClass('is-added');
        };
        KeywordEditorView.prototype.keywords = function () {
            return Array.prototype.map.call($('.js-keyword-item'), function (dom) { return $(dom).data('keyword-name'); });
        };
        return KeywordEditorView;
    }(Fily.BaseView));
    Fily.KeywordEditorView = KeywordEditorView;
})(Fily || (Fily = {}));
