var Fily;
(function (Fily) {
    var Denied;
    (function (Denied) {
        Denied.PHOTO_URL = _.sortedUniq([
            'motoyamayuki.com',
            'eatpia.com',
            'cafe.crafttown.jp'
        ]);
        Denied.EXTERNAL_PHOTO_URL = _.sortedUniq([
            'ameba.jp',
            'ameblo.jp'
        ]);
        Denied.isDeniedPhotoUrl = function (url) {
            var result = false;
            for (var i = 0; i < Denied.PHOTO_URL.length; i++) {
                if (url.indexOf(Denied.PHOTO_URL[i]) > -1) {
                    result = true;
                    break;
                }
            }
            return result;
        };
        Denied.isDeniedExternalPhotoUrl = function (url) {
            var result = false;
            for (var i = 0; i < Denied.EXTERNAL_PHOTO_URL.length; i++) {
                if (url.indexOf(Denied.EXTERNAL_PHOTO_URL[i]) > -1) {
                    result = true;
                    break;
                }
            }
            return result;
        };
    })(Denied = Fily.Denied || (Fily.Denied = {}));
})(Fily || (Fily = {}));
