var Fily;
(function (Fily) {
    var fullSelector = '.js-loadingFull';
    var active = 'is-active';
    var isShow = false;
    Fily.showFullLoading = function (waitTime) {
        if (waitTime === void 0) { waitTime = 500; }
        isShow = true;
        setTimeout(function () {
            if (isShow)
                $(fullSelector).addClass(active);
        }, waitTime);
    };
    Fily.hideFullLoading = function () {
        isShow = false;
        $(fullSelector).removeClass(active);
    };
    Fily.showLoading = function ($loader) {
        $loader.addClass(active);
    };
    Fily.hideLoading = function ($loader) {
        $loader.removeClass(active);
    };
})(Fily || (Fily = {}));
