var Fily;
(function (Fily) {
    var Instagram;
    (function (Instagram) {
        Instagram.embed = function () {
            if (typeof window.instgrm !== 'undefined')
                window.instgrm.Embeds.process();
        };
        Instagram.idRegExp = /^https?:\/\/([\w\.]*)instagram.com\/p\/([a-zA-Z0-9_-])+([\/ ])?/;
        Instagram.isValidUrl = function (url) { return Instagram.idRegExp.test(url); };
        Instagram.replaceUrl = function (url) {
            var matches = url.match(/^https:\/\/(www\.)?instagram\.com\/p\/([a-zA-Z0-9_-])+([\/ ])?/);
            if (matches === null) {
                return '';
            }
            else {
                return matches[0];
            }
        };
    })(Instagram = Fily.Instagram || (Fily.Instagram = {}));
})(Fily || (Fily = {}));
