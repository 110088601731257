var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var Fily;
(function (Fily) {
    var SpecialistView = (function (_super) {
        __extends(SpecialistView, _super);
        function SpecialistView() {
            var _this = _super.call(this, { delegate: false }) || this;
            _this.delegateEvent();
            Fily.FollowView.load();
            return _this;
        }
        SpecialistView.prototype.delegateEvent = function () {
            _super.prototype.delegateEvent.call(this);
            Fily.ContentsView.load();
        };
        SpecialistView.prototype.events = function () {
            return {
                'click keypress / .js-accordionTab': Fily.Accordion.toggle,
                'change / #js-specialistArea': function (e) {
                    return location.href = $(e.target).data('specialist-url') + e.target.value;
                }
            };
        };
        return SpecialistView;
    }(Fily.BaseView));
    Fily.SpecialistView = SpecialistView;
})(Fily || (Fily = {}));
