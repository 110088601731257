var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var Fily;
(function (Fily) {
    var Flick = (function (_super) {
        __extends(Flick, _super);
        function Flick(params) {
            var _this = _super.call(this, { delegate: false }) || this;
            _this.touchable = 'ontouchend' in document;
            _this.windowWidth = $(window).width();
            _this.itemWidthList = [];
            _this.setProperty(params);
            _this.initCss();
            _this.initTransform();
            _this.delegateEvent();
            return _this;
        }
        Flick.prototype.setProperty = function (params) {
            this.flickAreaSelector = params.flickAreaSelector;
            this.selector = params.selector;
            this.itemSelector = params.itemSelector;
            this.currentItem = params.defaultItem || 0;
            this.pointClass = params.pointClass || 'active';
            this.doneCallback = params.doneCallback;
            this.$el = $(this.selector);
            this.selectItems();
            var itemTotalWidth = 0;
            this.itemWidthList = Array.prototype.map.call(this.$item, function (dom) {
                var width = $(dom).width();
                itemTotalWidth += width;
                return width;
            });
            var dist = itemTotalWidth - this.windowWidth;
            this.maxTransformDist = dist > 0 ? dist : 0;
        };
        Flick.prototype.initCss = function () {
            this.transform();
        };
        Flick.prototype.initTransform = function () {
            this.transforming = false;
            this.touchPositionX = 0;
            this.touchPositionY = 0;
        };
        Flick.prototype.events = function () {
            var events = {};
            if (this.touchable) {
                events['touchstart / ' + this.flickAreaSelector] = 'touchStart';
                events['touchmove / ' + this.flickAreaSelector] = 'touchMove';
                events['touchend / ' + this.flickAreaSelector] = 'touchEnd';
            }
            else {
                events['mousedown / ' + this.flickAreaSelector] = 'touchStart';
                events['mousemove / ' + this.flickAreaSelector] = 'touchMove';
                events['mouseup / ' + this.flickAreaSelector] = 'touchEnd';
            }
            return events;
        };
        Flick.prototype.getTouchPositionX = function (event) {
            var ev = this.touchable ? event.originalEvent.touches[0] : event;
            return ev.clientX;
        };
        Flick.prototype.getTouchPositionY = function (event) {
            var ev = this.touchable ? event.originalEvent.touches[0] : event;
            return ev.clientY;
        };
        Flick.prototype.touchStart = function (event) {
            this.touchPositionX = this.getTouchPositionX(event);
            this.touchPositionY = this.getTouchPositionY(event);
        };
        Flick.prototype.touchMove = function (event) {
            if (Math.abs(this.touchPositionX - this.getTouchPositionX(event)) > 10) {
                event.preventDefault();
            }
            if (this.transforming || !this.touchPositionX)
                return;
            var moveX = this.getTouchPositionX(event) - this.touchPositionX;
            var moveY = this.getTouchPositionY(event) - this.touchPositionY;
            var moveR = moveY / moveX;
            if (Math.abs(moveR) > Math.tan(30 * Math.PI / 180))
                return;
            if (Math.abs(moveX) > 10) {
                this.transforming = true;
                moveX > 0 ? this.toPrev() : this.toNext();
            }
        };
        Flick.prototype.touchEnd = function (event) {
            this.initTransform();
        };
        Flick.prototype.toNext = function () { };
        Flick.prototype.toPrev = function () { };
        Flick.prototype.toItem = function (index) {
            this.currentItem = index;
            this.transform();
            this.point();
            this.doneCallback(index);
        };
        Flick.prototype.transition = function (second) {
            if (second === void 0) { second = '.3'; }
            this.$el.css(Fily.Util.Css.addVendorPrefix('transition'), Fily.Util.Css.addVendorPrefix('transform') + ' ' + second + 's ease-in-out');
        };
        Flick.prototype.transform = function (dist) {
            if (dist === void 0) { dist = null; }
            this.positionX = this.calcPositionX(dist);
            this.$el.css(Fily.Util.Css.addVendorPrefix('transform'), 'translate3d(' + this.positionX + 'px, 0, 0)');
        };
        Flick.prototype.point = function () {
            var current = this.currentItem;
            this.$item.removeClass(this.pointClass);
            this.getCurrentItem().addClass(this.pointClass);
        };
        Flick.prototype.calcPositionX = function (dist) {
            this.windowWidth = $(window).width();
            if (!dist) {
                var current = this.currentItem;
                var currentIndex_1 = this.$item.index(this.getCurrentItem());
                var $befores = this.$item.filter(function (i) {
                    return i < currentIndex_1;
                });
                var beforeWidth = [].map.call($befores, function (item) {
                    return $(item).width();
                });
                var allW = beforeWidth.reduce(function (w, next) {
                    return w + next;
                });
                return -(allW - (this.windowWidth / 2) + this.getCurrentItem().width() / 2);
            }
            else {
                return dist > this.maxTransformDist ? -this.maxTransformDist : (dist > 0 ? -dist : 0);
            }
        };
        Flick.prototype.getCurrentItem = function () {
            var current = this.currentItem;
            return this.$item.filter(function () {
                return $(this).data('current-index') === current;
            });
        };
        Flick.prototype.selectItems = function () {
            this.$item = $(this.itemSelector, this.$el);
        };
        return Flick;
    }(Fily.BaseView));
    var SmoothFlick = (function (_super) {
        __extends(SmoothFlick, _super);
        function SmoothFlick() {
            var _this = _super !== null && _super.apply(this, arguments) || this;
            _this.transformDist = _this.windowWidth / 2;
            return _this;
        }
        SmoothFlick.prototype.toNext = function () {
            this.transform(-this.positionX + this.transformDist);
        };
        SmoothFlick.prototype.toPrev = function () {
            this.transform(-this.positionX - this.transformDist);
        };
        return SmoothFlick;
    }(Flick));
    Fily.SmoothFlick = SmoothFlick;
    var StepwiseFlick = (function (_super) {
        __extends(StepwiseFlick, _super);
        function StepwiseFlick() {
            return _super !== null && _super.apply(this, arguments) || this;
        }
        StepwiseFlick.prototype.setProperty = function (params) {
            _super.prototype.setProperty.call(this, params);
            this.itemLength = this.$item.length;
            this.$currentBar = $(params.currentBar);
            this.$currentBar.width(this.itemWidthList[this.currentItem]);
            if (this.currentItem < 3) {
                var last3_1 = this.itemLength - 4;
                var $last3 = this.$item.filter(function (index) {
                    return index > last3_1;
                });
                var $left = this.$item.filter(function (index) {
                    return index <= last3_1;
                });
                this.$el.html('').append($last3.add($left));
            }
            else if (this.currentItem > (this.itemLength - 2)) {
                var first3_1 = 3;
                var $first3 = this.$item.filter(function (index) {
                    return index < first3_1;
                });
                var $left = this.$item.filter(function (index) {
                    return index >= first3_1;
                });
                this.$el.html('').append($left.add($first3));
            }
            this.selectItems();
        };
        StepwiseFlick.prototype.toNext = function () {
            var _this = this;
            var $first = this.$item.eq(0);
            this.$el.css('margin-left', $first.width());
            this.transition('.3');
            this.$el.append($first);
            if (this.currentItem >= (this.itemLength - 1)) {
                this.currentItem = 0;
            }
            else {
                this.currentItem += 1;
            }
            this.$currentBar.width(this.itemWidthList[this.currentItem]);
            this.toItem(this.currentItem);
            setTimeout(function () {
                _this.transition('0');
                _this.$el.css('margin-left', 0);
                _this.transform();
            }, 300);
            this.selectItems();
        };
        StepwiseFlick.prototype.toPrev = function () {
            var _this = this;
            var $last = this.$item.eq(this.itemLength - 1);
            this.transition('.3');
            this.$el.css('margin-left', -$last.width());
            this.$item.eq(0).before($last);
            if (this.currentItem < 1) {
                this.currentItem = (this.itemLength - 1);
            }
            else {
                this.currentItem -= 1;
            }
            this.$currentBar.width(this.itemWidthList[this.currentItem]);
            this.toItem(this.currentItem);
            setTimeout(function () {
                _this.transition('0');
                _this.$el.css('margin-left', 0);
                _this.transform();
            }, 300);
            this.selectItems();
        };
        return StepwiseFlick;
    }(Flick));
    Fily.StepwiseFlick = StepwiseFlick;
})(Fily || (Fily = {}));
