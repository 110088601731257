var Fily;
(function (Fily) {
    var Util;
    (function (Util) {
        Util.isFunction = function (fnOrNot) { return typeof fnOrNot === "function"; };
        Util.isUrl = function (url) {
            var reg = /^https?:\/\/[!-~]+$/;
            return reg.test(url);
        };
        Util.isIncludeUrl = function (str) {
            var reg = /https?:\/\/[!-~]+/;
            return reg.test(str);
        };
        Util.createObjectURL = function (file) {
            var url = window['URL'] || window['webkitURL'];
            return url.createObjectURL(file);
        };
        Util.checkImagePath = function (imagePath, done, fail) {
            $('<img/>')
                .on('load', function () {
                done();
            })
                .on('error', function () {
                fail();
            })
                .attr('src', imagePath);
        };
        Util.centeredPopUpPosition = function (w, h) {
            return {
                left: (window.innerWidth / 2) - (w / 2),
                top: (window.innerHeight / 2) - (h / 2)
            };
        };
        Util.checkImageSize = function (size, maxMB) {
            if (maxMB === void 0) { maxMB = 20; }
            return size <= maxMB * 1024 * 1024;
        };
        Util.checkImageFileSize = function (file, maxMB) {
            if (maxMB === void 0) { maxMB = 20; }
            return Util.checkImageSize(file.size, maxMB);
        };
        Util.byteSizeFormat = function (bytes) {
            var BYTES_UNITS = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
            var KB = 1024;
            if (bytes === 0)
                return '0 Byte';
            var i = Math.floor(Math.log(bytes) / Math.log(KB));
            return Math.ceil(bytes * 10 / Math.pow(KB, i)) / 10 + " " + BYTES_UNITS[i];
        };
        var Str;
        (function (Str) {
            Str.count = function (text) {
                var surrogatePairs = text.match(/[\uD800-\uDBFF][\uDC00-\uDFFF]/g) || [];
                var expectedLength = text.length - surrogatePairs.length;
                return text ? expectedLength : 0;
            };
            Str.isZipcode = function (text) { return /(^\d{7}$)/.test(text); };
            Str.isHalfwidthSymbol = function (text) { return !!text.match(/[!-/:-@≠\[-`{-~]/); };
            Str.hiraganaToFullWidthKatakana = function (text) {
                return text.replace(/[\u3041-\u3096]/g, function (match) { return String.fromCharCode(match.charCodeAt(0) + 0x60); });
            };
            Str.katakanaToFullWidthHiragana = function (text) {
                return text.replace(/[\u30a1-\u30f6]/g, function (match) { return String.fromCharCode(match.charCodeAt(0) - 0x60); });
            };
            Str.replaceToBold = function (str, target) {
                var katakanaUpper = Str.hiraganaToFullWidthKatakana(str).toUpperCase();
                var hiraganaLower = Str.katakanaToFullWidthHiragana(str).toLowerCase();
                var re = new RegExp("(" + katakanaUpper + ")|(" + hiraganaLower + ")", 'gi');
                return target.replace(re, '<span m-text="bold">$1$2</span>');
            };
        })(Str = Util.Str || (Util.Str = {}));
        var Array;
        (function (Array) {
            Array.shuffle = function (arr) {
                var n = arr.length;
                var temp;
                var i;
                while (n) {
                    i = Math.floor(Math.random() * n--);
                    temp = arr[n];
                    arr[n] = arr[i];
                    arr[i] = temp;
                }
                return arr;
            };
            Array.findIndex = function (arr, predicate) {
                var length = arr.length;
                var index = -1;
                while (index++ < length) {
                    if (predicate(arr[index], index, arr)) {
                        return index;
                    }
                }
                return -1;
            };
            Array.strUniq = function (arr) {
                return arr.filter(function (x, i, self) { return self.indexOf(x) === i; });
            };
        })(Array = Util.Array || (Util.Array = {}));
        var Css;
        (function (Css) {
            var getVendorPrefix = function () {
                var vendorPrefixes = {
                    'transition': '',
                    'webkitTransition': '-webkit-',
                    'MozTransition': '-moz-',
                    'OTransition': '-o-',
                    'msTransition': '-ms-'
                };
                var style = document.createElement('div').style;
                var prefix = '';
                var keys = Object.keys(vendorPrefixes);
                for (var i = 0; i < keys.length; i++) {
                    if (keys[i] in style) {
                        return vendorPrefixes[keys[i]];
                    }
                }
            };
            Css.vendorPrefix = getVendorPrefix();
            Css.addVendorPrefix = function (cssStyle) { return Css.vendorPrefix + cssStyle; };
            Css.isSupportAppearance = function () {
                var input = document.createElement('input');
                return 'appearance' in input.style ||
                    '-webkit-appearance' in input.style ||
                    '-moz-appearance' in input.style;
            };
        })(Css = Util.Css || (Util.Css = {}));
        var Device;
        (function (Device) {
            Device.ua = navigator.userAgent.toLowerCase();
            Device.isAndroid = function () {
                return Device.ua.indexOf('android') > -1;
            };
            Device.isIOs = function () {
                return Device.ua.indexOf('iphone') > -1 ||
                    Device.ua.indexOf('ipod') > -1 ||
                    Device.ua.indexOf('ipad') > -1;
            };
            Device.isTablet = function () {
                return Device.ua.indexOf('ipad') > -1 ||
                    Device.ua.indexOf('android') > -1 &&
                        Device.ua.indexOf('mobile') === -1;
            };
            Device.isLineBrowser = function () {
                return Device.ua.indexOf('line') > -1 &&
                    Device.ua.indexOf('safari') > -1;
            };
        })(Device = Util.Device || (Util.Device = {}));
        var NativeBridge;
        (function (NativeBridge) {
            NativeBridge.bridgeIos = function (bridgeType) {
                location.href = "limia-bridge://" + bridgeType;
            };
            NativeBridge.bridgeAndroid = function (bridgeType) {
                location.href = "limia-bridge://" + bridgeType;
            };
        })(NativeBridge = Util.NativeBridge || (Util.NativeBridge = {}));
        Util.getCalculatedPxByAspectRatio = function (ratio, px) {
            var calculatedPx = { width: 0, height: 0 };
            if (px.width !== 0 && px.height !== 0) {
                calculatedPx.width = px.width;
                calculatedPx.height = px.height;
            }
            else if (px.width !== 0 && px.height === 0) {
                calculatedPx.width = px.width;
                calculatedPx.height = px.width / ratio.width * ratio.height;
            }
            else if (px.width === 0 && px.height !== 0) {
                calculatedPx.width = px.height / ratio.height * ratio.width;
                calculatedPx.height = px.height;
            }
            else {
                calculatedPx.width = 0;
                calculatedPx.height = 0;
            }
            return calculatedPx;
        };
    })(Util = Fily.Util || (Fily.Util = {}));
})(Fily || (Fily = {}));
