var Fily;
(function (Fily) {
    var Api;
    (function (Api) {
        var Category;
        (function (Category) {
            function get(categoryName, offset, limit) {
                if (offset === void 0) { offset = 0; }
                if (limit === void 0) { limit = 10; }
                return Api.send('categories.contents.' + categoryName, {
                    offset: offset,
                    limit: limit
                }, 'GET');
            }
            Category.get = get;
            var Idea;
            (function (Idea) {
                function get(categoryName, offset, limit, sort) {
                    if (offset === void 0) { offset = 0; }
                    if (limit === void 0) { limit = 10; }
                    if (sort === void 0) { sort = 0; }
                    return Api.send('categories.ideas.' + categoryName, {
                        offset: offset,
                        limit: limit,
                        sort: sort
                    }, 'GET');
                }
                Idea.get = get;
            })(Idea = Category.Idea || (Category.Idea = {}));
            var Photo;
            (function (Photo) {
                function get(categoryName, offset, limit, sort) {
                    if (offset === void 0) { offset = 0; }
                    if (limit === void 0) { limit = 10; }
                    if (sort === void 0) { sort = 0; }
                    return Api.send('categories.photos.' + categoryName, {
                        offset: offset,
                        limit: limit,
                        sort: sort
                    }, 'GET');
                }
                Photo.get = get;
            })(Photo = Category.Photo || (Category.Photo = {}));
        })(Category = Api.Category || (Api.Category = {}));
    })(Api = Fily.Api || (Fily.Api = {}));
})(Fily || (Fily = {}));
