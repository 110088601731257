var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var Fily;
(function (Fily) {
    var ContentsView = (function (_super) {
        __extends(ContentsView, _super);
        function ContentsView() {
            return _super !== null && _super.apply(this, arguments) || this;
        }
        ContentsView.prototype.events = function () {
            return {
                'click / .js-like': 'toggleLike',
                'click / .js-clip': 'clip',
                'click / .js-tweet': 'shareTwitter',
                'click / .js-shareFb': 'shareFacebook',
                'click / .js-hatenabookmark': 'shareHatena'
            };
        };
        ContentsView.prototype.toggleLike = function (event) {
            event.stopPropagation();
            event.preventDefault();
            if (!Fily.Myself.isLogin()) {
                location.href = '/signup/';
                return false;
            }
            var $target = $(event.currentTarget);
            var id = $target.data('id');
            var type = $target.data('type');
            var $button = $('.js-like[data-id=' + id + '][data-type=' + type + ']');
            var flagClass = 'is-added';
            if ($button.hasClass(flagClass)) {
                $button.removeClass(flagClass);
                if (type == 'photo') {
                    Fily.Api.Photo.like(id, false).fail(function () { return $button.addClass(flagClass); });
                }
                else if (type == 'idea') {
                    Fily.Api.Idea.like(id, false).fail(function () { return $button.addClass(flagClass); });
                }
            }
            else {
                $button.addClass(flagClass);
                if (type == 'photo') {
                    Fily.Api.Photo.like(id).fail(function () { return $button.removeClass(flagClass); });
                }
                else if (type == 'idea') {
                    Fily.Api.Idea.like(id).fail(function () { return $button.removeClass(flagClass); });
                }
            }
        };
        ContentsView.prototype.clip = function (event) {
            event.stopPropagation();
            event.preventDefault();
            if (!Fily.Myself.isLogin()) {
                location.href = '/signup/';
                return false;
            }
            var $target = $(event.currentTarget);
            var id = $target.data('id');
            var type = $target.data('type');
            new IdeaClipModalView(id, type).show();
        };
        ContentsView.prototype.shareTwitter = function (event) {
            var $target = $(event.currentTarget);
            var w = 550;
            var h = 300;
            var pos = Fily.Util.centeredPopUpPosition(w, h);
            return window.open("https://twitter.com/share?url=" + $target.data('url') + "&counturl=" + $target.data('url') + "&text=" + $target.data('title') + "&via=limiajp", 'tweet_window', "width=" + w + ", height=" + h + ", left=" + pos.left + ", top=" + pos.top + ", toolbar=0, location=0, menubar=0, scrollbars=0");
        };
        ContentsView.prototype.shareFacebook = function (event) {
            var $target = $(event.currentTarget);
            var w = 611;
            var h = 368;
            var pos = Fily.Util.centeredPopUpPosition(w, h);
            return window.open("https://www.facebook.com/sharer/sharer.php?u=" + $target.data('url') + "&t=" + $target.data('title'), 'sharefb_window', "width=" + w + ", height=" + h + ", left=" + pos.left + ", top=" + pos.top + ", toolbar=0, location=0, menubar=0, scrollbars=0");
        };
        ContentsView.prototype.shareHatena = function (event) {
            var $target = $(event.currentTarget);
            var w = 520;
            var h = 350;
            var pos = Fily.Util.centeredPopUpPosition(w, h);
            return window.open("http://b.hatena.ne.jp/add?mode=confirm&url=" + $target.data('url') + "&title=" + $target.data('title'), 'sharefb_window', "width=" + w + ", height=" + h + ", left=" + pos.left + ", top=" + pos.top + ", toolbar=0, location=0, menubar=0, scrollbars=0");
        };
        return ContentsView;
    }(Fily.BaseView));
    Fily.ContentsView = ContentsView;
    var IdeaClipModalView = (function (_super) {
        __extends(IdeaClipModalView, _super);
        function IdeaClipModalView(id, type) {
            var _this = _super.call(this) || this;
            _this.id = id;
            _this.type = type;
            _this.bodyTpl = new Fily.MustacheTpl('idea/select_modal');
            _this.delegateEvent();
            return _this;
        }
        IdeaClipModalView.prototype.events = function () {
            return $.extend(_super.prototype.events.call(this), {
                'change / select[name=ideas]': 'clipSelectIdea'
            });
        };
        IdeaClipModalView.prototype.show = function () {
            var _this = this;
            Fily.Api.Idea.getMyself(1000, 0, null, true).done(function (res) {
                _this.params = { ideas: res };
                _super.prototype.show.call(_this);
            });
        };
        IdeaClipModalView.prototype.render = function () {
            _super.prototype.render.call(this);
            this.$ideaSelect = $('select[name=ideas]', this.$body);
            this.$title = $('input[name=title]', this.$body);
            this.titleCounter = new Fily.InputCounter(this.$title, $('.js-counter--ideatitle', this.$body));
            this.$category = $('select[name=category]', this.$body);
            this.$category.html(Fily.Categories.getInitialOption());
        };
        IdeaClipModalView.prototype.isSelectedNewIdea = function () {
            return this.$ideaSelect.val() == 'new';
        };
        IdeaClipModalView.prototype.isUnSelectedIdea = function () {
            return this.$ideaSelect.val() == 0;
        };
        IdeaClipModalView.prototype.isUnSelectedCategory = function () {
            return this.$category.val() == 0;
        };
        IdeaClipModalView.prototype.clipSelectIdea = function (event) {
            var $target = $(event.currentTarget);
            var $newIdeaInfo = $('#js-create-clip-idea');
            if (this.isSelectedNewIdea()) {
                $newIdeaInfo.show();
            }
            else {
                $newIdeaInfo.hide();
                Fily.Field.Err.hide($target);
            }
            if ($target.val() == 0) {
                Fily.Field.Err.show($target, Fily.ERR.CODE.SELECT_INVALID, ['アイデア']);
            }
            else {
                Fily.Field.Err.hide($target);
            }
        };
        IdeaClipModalView.prototype.done = function (event) {
            var _this = this;
            this.$button = $(event.currentTarget).addClass('is-disabled');
            if (this.isUnSelectedIdea()) {
                this.$button.removeClass('is-disabled').addClass('is-error');
                Fily.Field.Err.show(this.$ideaSelect, Fily.ERR.CODE.SELECT_INVALID, ['アイデア']);
                setTimeout(function () {
                    _this.$button.removeClass('is-error');
                }, 1000);
                return;
            }
            else if (this.isSelectedNewIdea()) {
                if (!this.titleCounter.checkCount() || this.isUnSelectedCategory()) {
                    this.$button.removeClass('is-disabled').addClass('is-error');
                    if (!this.titleCounter.checkRequire()) {
                        Fily.Field.Err.show(this.$title, Fily.ERR.CODE.INPUT_EMPTY, ['タイトル']);
                    }
                    else if (!this.titleCounter.checkLength()) {
                        Fily.Field.Err.show(this.$title, Fily.ERR.CODE.INPUT_MAX_LENGTH_INVALID, ['タイトル', this.titleCounter.maxLength.toString()]);
                    }
                    if (this.isUnSelectedCategory()) {
                        Fily.Field.Err.show(this.$category, Fily.ERR.CODE.SELECT_INVALID, ['カテゴリ']);
                    }
                    setTimeout(function () {
                        _this.$button.removeClass('is-error');
                    }, 1000);
                    return;
                }
            }
            Fily.showFullLoading();
            if (this.isSelectedNewIdea()) {
                Fily.Api.Idea.create(this.$title.val(), this.$category.val()).done(function (res) {
                    _this.clip(res['id'], res['title']);
                }).fail(function () { return Fily.hideFullLoading(); });
            }
            else {
                this.clip(this.$ideaSelect.val(), this.$ideaSelect.find('option:selected').text());
            }
        };
        IdeaClipModalView.prototype.clip = function (ideaId, ideaName) {
            var _this = this;
            var $button = this.$button;
            var sendApi;
            if (this.type == 'photo') {
                sendApi = Fily.Api.Photo.clip(ideaId, this.apiParams());
            }
            else {
                sendApi = Fily.Api.Idea.clip(ideaId, this.apiParams());
            }
            sendApi.done(function (res) {
                new Fily.Obi().show(false, ideaName + ' にクリップしました。');
                _this.hide();
                if ($button.hasClass('js-edit--idea')) {
                    location.href = '/idea/' + ideaId + '/edit/';
                }
            }).always(function () { return Fily.hideFullLoading(); });
        };
        IdeaClipModalView.prototype.apiParams = function () {
            if (this.type == 'photo') {
                return {
                    photo_id: this.id,
                    layout: 1
                };
            }
            else if (this.type == 'idea') {
                return { idea_id: this.id };
            }
            return {};
        };
        return IdeaClipModalView;
    }(Fily.ModalView));
    Fily.IdeaClipModalView = IdeaClipModalView;
})(Fily || (Fily = {}));
