var Fily;
(function (Fily) {
    var Config;
    (function (Config) {
        Config.instance = JSON.parse($('#js-config').html());
        Config.device = Config.instance['device'];
        Config.csrfToken = Config.instance['csrf_token'];
        Config.serverProtocol = Config.instance['server_protocol'];
        Config.httpHost = Config.instance['http_host'];
        Config.fbId = Config.instance['fb_app_id'];
        Config.recaptchaSiteKey = Config.instance['recaptcha_site_key'];
        Config.isProduction = Config.instance['is_production'] ? true : false;
        Config.isWebview = (Config.device === 'ios' || Config.device === 'android') ? true : false;
        Config.isIOSWebview = (Config.device === 'ios') ? true : false;
        Config.isAndroidWebview = (Config.device === 'android') ? true : false;
    })(Config = Fily.Config || (Fily.Config = {}));
})(Fily || (Fily = {}));
