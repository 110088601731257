var Fily;
(function (Fily) {
    var Api;
    (function (Api) {
        var Specialist;
        (function (Specialist) {
            function get(category, prefecture, offset, limit) {
                if (prefecture === void 0) { prefecture = null; }
                if (offset === void 0) { offset = 0; }
                if (limit === void 0) { limit = 10; }
                return Api.send('specialist.' + category + (prefecture ? '.' + prefecture : ''), {
                    offset: offset,
                    limit: limit
                }, 'GET');
            }
            Specialist.get = get;
        })(Specialist = Api.Specialist || (Api.Specialist = {}));
    })(Api = Fily.Api || (Fily.Api = {}));
})(Fily || (Fily = {}));
