var Fily;
(function (Fily) {
    var ZipSearch = (function () {
        function ZipSearch() {
            this.results = {};
        }
        ZipSearch.prototype.request = function (val) {
            var _this = this;
            var d = $.Deferred();
            if (!Fily.Util.Str.isZipcode(val)) {
                d.reject({
                    code: Fily.ERR.CODE.INPUT_INVALID
                });
                return d.promise();
            }
            if (this.results[val]) {
                d.resolve(this.results[val]);
                return d.promise();
            }
            Fily.Api.ZipSearch.findAll(val)
                .done(function (res) {
                if (res) {
                    _this.results[val] = res;
                    d.resolve(_this.results[val]);
                }
                else {
                    d.reject({
                        code: Fily.ERR.CODE.CONNECTION_FAILED
                    });
                }
            })
                .fail(function () { return d.reject({
                code: Fily.ERR.CODE.CONNECTION_FAILED
            }); });
            return d.promise();
        };
        ZipSearch.prototype.getUniqChoices = function (list) {
            return Fily.Util.Array.strUniq(list);
        };
        ZipSearch.prototype.getPrefecture = function (addresslist) {
            return this.getUniqChoices(addresslist.map(function (address) { return address.prefecture; }));
        };
        ZipSearch.prototype.getCity = function (addresslist) {
            return this.getUniqChoices(addresslist.map(function (address) { return address.city; }));
        };
        ZipSearch.prototype.getTown = function (addresslist) {
            return this.getUniqChoices(addresslist.map(function (address) { return address.town; }));
        };
        return ZipSearch;
    }());
    Fily.ZipSearch = ZipSearch;
})(Fily || (Fily = {}));
