var Fily;
(function (Fily) {
    var Movie;
    (function (Movie) {
        var Youtube;
        (function (Youtube) {
            Youtube.idRegExp = /(?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/ ]{11})/i;
            Youtube.getId = function (url) {
                var matches = url.match(Youtube.idRegExp);
                if (!matches) {
                    throw new Error('YouTube の URL形式ではありません。');
                }
                return matches[1];
            };
            Youtube.isValidUrl = function (url) { return Youtube.idRegExp.test(url); };
        })(Youtube = Movie.Youtube || (Movie.Youtube = {}));
    })(Movie = Fily.Movie || (Fily.Movie = {}));
})(Fily || (Fily = {}));
