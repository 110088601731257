var Fily;
(function (Fily) {
    var GetOwnerAlias;
    (function (GetOwnerAlias) {
        var instance = $('#js-owner').html() ? JSON.parse($('#js-owner').html()) : null;
        function get() {
            return instance;
        }
        GetOwnerAlias.get = get;
        GetOwnerAlias.alias = instance ? instance['alias'] : '';
    })(GetOwnerAlias = Fily.GetOwnerAlias || (Fily.GetOwnerAlias = {}));
})(Fily || (Fily = {}));
