var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var Fily;
(function (Fily) {
    var IdeaEditView = (function (_super) {
        __extends(IdeaEditView, _super);
        function IdeaEditView() {
            var _this = _super.call(this, { delegate: false }) || this;
            _this.$body = $('.js-Idea');
            _this.targetClass = '.js-FieldEditor-target';
            _this.textCountClass = '.js-textCount';
            _this.currentTextCountClass = '.js-currentTextCount';
            _this.maxTextCountClass = '.js-maxTextCount';
            _this.appliedContest = null;
            IdeaEditView.instance = _this;
            _this.delegateEvent();
            _this.ideaId = _this.$body.data('idea-id');
            _this.isRewrite = _this.$body.data('is-rewrite');
            _this.originId = _this.$body.data('origin-id');
            _this.isOfficial = $('.js-Idea').data('iam-official-user');
            Fily.Api.Contest.getEnterableIdea(_this.ideaId).done(function (res) {
                if (res) {
                    $('#js-contest-info').fadeIn();
                }
            });
            Fily.Api.Contest.getPosted(_this.ideaId).done(function (res) {
                if (res) {
                    _this.appliedContest = res;
                    $('#js-contest-title').show().find('p').html(res.title);
                }
            });
            if (_this.isOfficial) {
                _this.insertTextSum();
            }
            Fily.Insidemaps.init().setInsidemapsHeight();
            return _this;
        }
        IdeaEditView.delegateEvent = function () {
            if (!IdeaEditView.instance)
                new IdeaEditView();
            IdeaEditView.instance.delegateEvent();
        };
        ;
        IdeaEditView.prototype.delegateEvent = function () {
            _super.prototype.delegateEvent.call(this);
            new IdeaEditElementPanelView();
            new IdeaEditElementInsertPanelView();
            new IdeaEditElementView();
        };
        IdeaEditView.prototype.events = function () {
            var _this = this;
            return {
                'click / .js-showModal--editIdeaInfo': function () {
                    var mainPhotoId = _this.$body.find('.js-idea-mainPhoto').attr('data-mainPhoto-id') || '0';
                    new Fily.IdeaEditInfoModalView(_this.ideaId, {
                        bodyTpl: new Fily.MustacheTpl('idea/edit/info_modal'),
                        params: {
                            published: $('.js-idea-published', _this.$body).hasClass('is-published'),
                            title: $('.js-idea-title', _this.$body).text(),
                            description: $('.js-idea-description', _this.$body).text().trim(),
                            categoryId: $('.js-idea-category', _this.$body).data('category-id'),
                            keywords: $.map($('.js-idea-keyword', _this.$body), function (dom) {
                                return {
                                    id: $(dom).data('keyword-id'),
                                    name: $(dom).data('keyword-name')
                                };
                            }),
                            mainPhotoId: mainPhotoId,
                            photos: [].map.call($('.js-Element-photo', $('.js-Idea-Elements')), function (dom) {
                                var imgPath = $('img', $(dom)).attr('src') || $('img', $(dom)).attr('data-src');
                                var replaced = imgPath ? imgPath.replace('resize/o3', 'resize/sm1') : '';
                                var elementId = $(dom).parents('.js-FieldEditor-target').data('element-id');
                                return {
                                    id: elementId,
                                    external: $(dom).data('photo-external'),
                                    url: replaced,
                                    isMainPhoto: (elementId === Number(mainPhotoId))
                                };
                            })
                                .filter(function (item) {
                                return !item.external;
                            }),
                            keywordEditor: new Fily.KeywordEditorView(),
                            enabledApplyContest: !_this.appliedContest,
                            commentRefusal: $('.js-idea-commentRefusal', _this.$body).hasClass('is-commentRefusal'),
                            isRewrite: _this.isRewrite,
                            originId: _this.originId
                        },
                        doneCallback: function ($info, hide, res) {
                            var tpl = new Fily.MustacheTpl('idea/edit/info');
                            tpl.load().done(function () {
                                hide();
                                $('.js-Idea-Info', _this.$body).html(tpl.render(res));
                                if (_this.appliedContest) {
                                    $('#js-contest-title').show().find('p').html(_this.appliedContest.title);
                                }
                            });
                        },
                    }).show();
                },
                'click / .js-save--order': function () {
                    var elements = $.map($('.js-FieldEditor-target'), function (dom) { return $(dom).data('element-id'); });
                    Fily.showFullLoading();
                    Fily.Api.Idea.edit(_this.ideaId, { element_order: elements }).done(function () {
                        $('.js-bottomBar--order').removeClass('is-active');
                    }).always(function () { return Fily.hideFullLoading(); });
                },
                'click / .js-delete--idea': function () {
                    if (window.confirm('このアイデアを削除しますが、よろしいですか？')) {
                        Fily.showFullLoading();
                        Fily.Api.Idea.remove(_this.ideaId).done(function () {
                            location.href = '/user/' + Fily.Myself.alias + '/';
                        }).always(function () { return Fily.hideFullLoading(); });
                    }
                },
                'click / .js-countBodyText': function (event) {
                    event.preventDefault();
                    _this.insertBodyTextSum();
                }
            };
        };
        IdeaEditView.prototype.insertBodyTextSum = function () {
            var _this = this;
            var $targetElementList = this.$body.find(this.targetClass);
            var bodyTextNumList = [];
            [].forEach.call($targetElementList, function (l, i) {
                var elementType = _this.getElementType(l);
                var textCountEl = l.querySelector(_this.currentTextCountClass);
                if (elementType === Fily.Api.Idea.Element.Type.body) {
                    bodyTextNumList.push(parseInt(textCountEl.textContent));
                }
            });
            var bodyTextSum = bodyTextNumList.reduce(function (x, y) { return x += y; }, 0);
            $('.js-countBodyTextTotal', $('.js-totalText')).html(bodyTextSum);
        };
        IdeaEditView.prototype.insertTextSum = function () {
            var _this = this;
            var MAX60 = 60;
            var MAX1000 = 1000;
            var MAX800 = 800;
            var $targetElementList = this.$body.find(this.targetClass);
            [].forEach.call($targetElementList, function (l, i) {
                var elementType = _this.getElementType(l);
                var headingEl = l.querySelector('.js-Element-title');
                var subHeadingEl = l.querySelector('.js-Element-subTitle');
                var smallHeadingEl = l.querySelector('.js-Element-smallTitle');
                var bodyEl = l.querySelector('.js-Element-body');
                var linkEl = l.querySelector('.js-Element-link');
                var photoCaptionEl = l.querySelector('.js-Element-photo--caption');
                var ideaCaptionEl = l.querySelector('.js-Element-idea--caption');
                var affiliateCaptionEl = l.querySelector('.js-Element-affiliate--caption');
                var movieCaptionEl = l.querySelector('.js-Element-movie--caption');
                if (elementType === Fily.Api.Idea.Element.Type.title) {
                    _this.appendElementTextCount(l, headingEl, MAX60);
                }
                else if (elementType === Fily.Api.Idea.Element.Type.subheadline) {
                    _this.appendElementTextCount(l, subHeadingEl, MAX60);
                }
                else if (elementType === Fily.Api.Idea.Element.Type.smallheadline) {
                    _this.appendElementTextCount(l, smallHeadingEl, MAX60);
                }
                else if (elementType === Fily.Api.Idea.Element.Type.body) {
                    _this.appendElementTextCount(l, bodyEl, MAX1000);
                }
                else if (elementType === Fily.Api.Idea.Element.Type.link) {
                    _this.appendElementTextCount(l, linkEl, MAX60);
                }
                else if (elementType === Fily.Api.Idea.Element.Type.photo) {
                    _this.appendElementTextCount(l, photoCaptionEl, MAX1000);
                }
                else if (elementType === Fily.Api.Idea.Element.Type.idea) {
                    _this.appendElementTextCount(l, ideaCaptionEl, MAX1000);
                }
                else if (elementType === Fily.Api.Idea.Element.Type.affiliate) {
                    _this.appendElementTextCount(l, affiliateCaptionEl, MAX1000);
                }
                else if (elementType === Fily.Api.Idea.Element.Type.movie) {
                    _this.appendElementTextCount(l, movieCaptionEl, MAX800);
                }
                else if (elementType === Fily.Api.Idea.Element.Type.sns) {
                    l.querySelector(_this.textCountClass).setAttribute('hidden', '');
                }
                else if (elementType === Fily.Api.Idea.Element.Type.insidemaps) {
                    l.querySelector(_this.textCountClass).setAttribute('hidden', '');
                }
            });
            this.insertBodyTextSum();
        };
        IdeaEditView.prototype.appendElementTextCount = function (l, el, maxText) {
            if (el) {
                l.querySelector(this.textCountClass).removeAttribute('hidden');
                l.querySelector(this.maxTextCountClass).append(maxText);
                var textCountEl = l.querySelector(this.currentTextCountClass);
                return textCountEl.append(el.textContent.length);
            }
        };
        IdeaEditView.prototype.getElementType = function (target) {
            return parseInt(target.getAttribute('data-element-type'));
        };
        return IdeaEditView;
    }(Fily.BaseView));
    Fily.IdeaEditView = IdeaEditView;
    var IdeaEditElementPanelView = (function (_super) {
        __extends(IdeaEditElementPanelView, _super);
        function IdeaEditElementPanelView() {
            var _this = _super.call(this, { delegate: false }) || this;
            _this.viewId = 'element-edit-panel';
            _this.$body = $('.js-FieldEditor-panel');
            _this.targetClass = '.js-FieldEditor-target';
            _this.elementClass = '.js-Element';
            _this.textCountClass = '.js-textCount';
            _this.currentTextCountClass = '.js-currentTextCount';
            _this.maxTextCountClass = '.js-maxTextCount';
            _this.delegateEvent();
            _this.ideaId = $('.js-Idea').data('idea-id');
            _this.isOfficial = $('.js-Idea').data('iam-official-user');
            return _this;
        }
        IdeaEditElementPanelView.prototype.events = function () {
            return {
                'click / .js-move-top': 'moveTop',
                'click / .js-move-bottom': 'moveBottom',
                'click / .js-move-up': 'moveUp',
                'click / .js-move-down': 'moveDown',
                'click / .js-edit-element': 'editElement',
                'click / .js-delete-element': 'removeElement',
            };
        };
        IdeaEditElementPanelView.prototype.moveTop = function (event) {
            event.preventDefault();
            var $target = $(event.target).parents(this.targetClass);
            $(this.targetClass).first().before($target);
            this.refreshElement($target);
            this.showSaveBar();
        };
        IdeaEditElementPanelView.prototype.moveBottom = function (event) {
            event.preventDefault();
            var $target = $(event.target).parents(this.targetClass);
            $(this.targetClass).last().after($target);
            this.refreshElement($target);
            this.showSaveBar();
        };
        IdeaEditElementPanelView.prototype.moveUp = function (event) {
            event.preventDefault();
            var $target = $(event.target).parents(this.targetClass);
            $target.prev(this.targetClass).before($target);
            this.refreshElement($target);
            this.showSaveBar();
        };
        IdeaEditElementPanelView.prototype.moveDown = function (event) {
            event.preventDefault();
            var $target = $(event.target).parents(this.targetClass);
            $target.next(this.targetClass).after($target);
            this.refreshElement($target);
            this.showSaveBar();
        };
        IdeaEditElementPanelView.prototype.showSaveBar = function () {
            $('.js-bottomBar--order').addClass('is-active');
        };
        IdeaEditElementPanelView.prototype.editElement = function (event) {
            var _this = this;
            event.preventDefault();
            var $target = $(event.target).parents(this.targetClass);
            var elementType = this.getElementType($target);
            if (elementType === Fily.Api.Idea.Element.Type.title) {
                new Fily.IdeaEditTitleElementModalView(this.ideaId, {
                    bodyTpl: new Fily.MustacheTpl('idea/edit/title_element_modal'),
                    params: {
                        id: $target.data('element-id'),
                        title: $target.find('.js-Element-title').text()
                    },
                    doneCallback: function ($body, hide, res) {
                        var tpl = new Fily.MustacheTpl('idea/element/title', 'pc');
                        tpl.load().done(function () {
                            hide();
                            $target.find(_this.elementClass).html(tpl.render(res));
                            if (_this.isOfficial) {
                                $(_this.currentTextCountClass, $target).html(res['description'].length);
                                Fily.PlagiarismCheckView.load(_this.ideaId);
                            }
                        });
                    }
                }).show();
            }
            else if (elementType === Fily.Api.Idea.Element.Type.subheadline) {
                new Fily.IdeaEditTitleElementModalView(this.ideaId, {
                    bodyTpl: new Fily.MustacheTpl('idea/edit/title_element_modal'),
                    params: {
                        id: $target.data('element-id'),
                        title: $target.find('.js-Element-subTitle').text()
                    },
                    doneCallback: function ($body, hide, res) {
                        var tpl = new Fily.MustacheTpl('idea/element/subheadline', 'pc');
                        tpl.load().done(function () {
                            hide();
                            $target.find(_this.elementClass).html(tpl.render(res));
                            if (_this.isOfficial) {
                                $(_this.currentTextCountClass, $target).html(res['description'].length);
                                Fily.PlagiarismCheckView.load(_this.ideaId);
                            }
                        });
                    }
                }).show();
            }
            else if (elementType === Fily.Api.Idea.Element.Type.smallheadline) {
                new Fily.IdeaEditTitleElementModalView(this.ideaId, {
                    bodyTpl: new Fily.MustacheTpl('idea/edit/title_element_modal'),
                    params: {
                        id: $target.data('element-id'),
                        title: $target.find('.js-Element-smallTitle').text()
                    },
                    doneCallback: function ($body, hide, res) {
                        var tpl = new Fily.MustacheTpl('idea/element/smallheadline', 'pc');
                        tpl.load().done(function () {
                            hide();
                            $target.find(_this.elementClass).html(tpl.render(res));
                            if (_this.isOfficial) {
                                $(_this.currentTextCountClass, $target).html(res['description'].length);
                                Fily.PlagiarismCheckView.load(_this.ideaId);
                            }
                        });
                    }
                }).show();
            }
            else if (elementType == Fily.Api.Idea.Element.Type.body) {
                new Fily.IdeaEditBodyElementModalView(this.ideaId, {
                    bodyTpl: new Fily.MustacheTpl('idea/edit/body_element_modal'),
                    params: {
                        id: $target.data('element-id'),
                        body: $('<div/>').html($('.js-Element-body', $target)
                            .html()
                            .replace(/<b>([\s\S]*?)<\/b>/g, '&lt;b&gt;$1&lt;&#x2F;b&gt;')
                            .replace(/<a .*?href="([\s\S]*?)".*?>([\s\S]*?)<\/a>/g, '&lt;a href=&quot;$1&quot;&gt;$2&lt;&#x2F;a&gt;')).text()
                    },
                    doneCallback: function ($body, hide, res) {
                        var tpl = new Fily.MustacheTpl('idea/element/body', 'pc');
                        tpl.load().done(function () {
                            hide();
                            $(_this.elementClass, $target).html(tpl.render(res));
                            if (_this.isOfficial) {
                                $(_this.currentTextCountClass, $target).html(res['description'].length);
                                Fily.PlagiarismCheckView.load(_this.ideaId);
                            }
                        });
                    }
                }).show();
            }
            else if (elementType == Fily.Api.Idea.Element.Type.photo) {
                new Fily.IdeaEditPhotoElementModalView(this.ideaId, {
                    bodyTpl: new Fily.MustacheTpl('idea/edit/photo_element_modal'),
                    params: {
                        id: $target.data('element-id'),
                        photo_url: $('.js-Element-photo--url', $target).attr('src'),
                        credit_url: $('.js-Element-photo--credit', $target).data('credit_url'),
                        caption: $('.js-Element-photo--caption', $target).text(),
                        is_external: $('.js-Element-photo--credit', $target).data('is_external'),
                        is_avatar: $target.find('.js-Element-photo--avatar').data('is_avatar')
                    },
                    doneCallback: function ($body, hide, res) {
                        var tpl = new Fily.MustacheTpl('idea/element/photo', 'pc');
                        tpl.load().done(function () {
                            hide();
                            $(_this.elementClass, $target).html(tpl.render(res));
                            if (!($target.find('.js-Element-photo--caption').text().length === 0) && _this.isOfficial) {
                                $target.find(_this.textCountClass).removeAttr('hidden');
                                $(_this.currentTextCountClass, $target).html(res['description'].length);
                                $target.find(_this.maxTextCountClass).html('1000');
                                Fily.PlagiarismCheckView.load(_this.ideaId);
                            }
                            else if ($target.find('.js-Element-photo--caption').text().length === 0) {
                                $target.find(_this.textCountClass).attr('hidden', '');
                            }
                            setTimeout(function () {
                                Fily.ImageLoader.AspectRatioPlaceholder.init().findElement().lazyLoad();
                            }, 50);
                        });
                    }
                }).show();
            }
            else if (elementType == Fily.Api.Idea.Element.Type.idea) {
                new Fily.IdeaEditIdeaElementModalView(this.ideaId, {
                    bodyTpl: new Fily.MustacheTpl('idea/edit/idea_element_modal'),
                    params: {
                        id: $target.data('element-id'),
                        title: $('.js-Element-idea--title', $target).html(),
                        description: $('.js-Element-idea--description', $target).html(),
                        main_photo: { photo_url: $('.js-Element-idea--photo', $target).attr('src') },
                        user: { nickname: $('.js-Element-idea--nickname', $target).html() },
                        caption: $('.js-Element-idea--caption', $target).text()
                    },
                    doneCallback: function ($body, hide, res) {
                        var tpl = new Fily.MustacheTpl('idea/element/idea', 'pc');
                        tpl.load().done(function () {
                            hide();
                            $(_this.elementClass, $target).html(tpl.render(res));
                            if (!($target.find('.js-Element-idea--caption').text().length === 0) && _this.isOfficial) {
                                $target.find(_this.textCountClass).removeAttr('hidden');
                                $target.find(_this.currentTextCountClass).html(res['description'].length);
                                $target.find(_this.maxTextCountClass).html('1000');
                            }
                            else if ($target.find('.js-Element-idea--caption').text().length === 0) {
                                $target.find(_this.textCountClass).attr('hidden', '');
                            }
                        });
                    }
                }).show();
            }
            else if (elementType == Fily.Api.Idea.Element.Type.link) {
                new Fily.IdeaEditLinkElementModalView(this.ideaId, {
                    bodyTpl: new Fily.MustacheTpl('idea/edit/link_element_modal'),
                    params: {
                        id: $target.data('element-id'),
                        text: $('.js-Element-link', $target).text(),
                        url: $('.js-Element-link', $target).data('external-url'),
                        layout: 1
                    },
                    doneCallback: function ($body, hide, res) {
                        var tpl = new Fily.MustacheTpl('idea/element/link', 'pc');
                        tpl.load().done(function () {
                            hide();
                            $('.js-Element', $target).html(tpl.render(res));
                            if (_this.isOfficial) {
                                $(_this.currentTextCountClass, $target).html(res['description'].length);
                            }
                        });
                    }
                }).show();
            }
            else if (elementType == Fily.Api.Idea.Element.Type.movie) {
                new Fily.IdeaEditMovieElementModalView(this.ideaId, {
                    bodyTpl: new Fily.MustacheTpl('idea/edit/movie_element_modal'),
                    params: {
                        id: $target.data('element-id'),
                        movie_id: $('.js-Element-movie--id', $target).data('movie-id'),
                        caption: $('.js-Element-movie--caption', $target).text(),
                        layout: 1
                    },
                    doneCallback: function ($body, hide, res) {
                        var tpl = new Fily.MustacheTpl('idea/element/movie', 'pc');
                        tpl.load().done(function () {
                            hide();
                            $('.js-Element', $target).html(tpl.render(res));
                            if (!($target.find('.js-Element-movie--caption').text().length === 0) && _this.isOfficial) {
                                $target.find(_this.textCountClass).removeAttr('hidden');
                                $(_this.currentTextCountClass, $target).html(res['description'].length);
                                $target.find(_this.maxTextCountClass).html('800');
                                Fily.PlagiarismCheckView.load(_this.ideaId);
                            }
                            else if ($target.find('.js-Element-movie--caption').text().length === 0) {
                                $target.find(_this.textCountClass).attr('hidden', '');
                            }
                        });
                    }
                }).show();
            }
            else if (elementType == Fily.Api.Idea.Element.Type.sns) {
                var snsType_1 = parseInt($target.find('.js-Element-sns').data('sns_type'), 10);
                new Fily.IdeaEditSnsElementModalView(this.ideaId, {
                    bodyTpl: new Fily.MustacheTpl('idea/edit/sns_element_modal'),
                    params: {
                        id: $target.data('element-id'),
                        sns_type: snsType_1,
                        sns_name: Fily.Api.Idea.Element.SnsType[snsType_1],
                        url: $target.find('.js-Element-sns').data('url'),
                    },
                    doneCallback: function ($body, hide, res) {
                        var tpl = new Fily.MustacheTpl('idea/element/sns');
                        tpl.load().done(function () {
                            hide();
                            $target.find('.js-Element').html(tpl.render(res));
                            if (snsType_1 === Fily.Api.Idea.Element.SnsType.Instagram)
                                Fily.Instagram.embed();
                        });
                    }
                }).show();
            }
            else if (elementType == Fily.Api.Idea.Element.Type.insidemaps) {
                new Fily.InsidemapsElementModalView(this.ideaId, {
                    bodyTpl: new Fily.MustacheTpl('idea/edit/insidemaps_element_modal'),
                    params: {
                        id: $target.data('element-id'),
                        height: $target.find('.js-Element-insidemaps').attr('height'),
                        caption: $target.find('.js-Element-insidemaps--caption').text(),
                    },
                    doneCallback: function ($body, hide, res) {
                        var tpl = new Fily.MustacheTpl('idea/element/insidemaps');
                        tpl.load().done(function () {
                            hide();
                            $target.find('.js-Element').html(tpl.render(res));
                            if (!($target.find('.js-Element-insidemaps--caption').text().length === 0) && _this.isOfficial) {
                                $target.find(_this.textCountClass).removeAttr('hidden');
                                $(_this.currentTextCountClass, $target).html(res['description'].length);
                                $target.find(_this.maxTextCountClass).html('800');
                            }
                            else if ($target.find('.js-Element-insidemaps--caption').text().length === 0) {
                                $target.find(_this.textCountClass).attr('hidden', '');
                            }
                        });
                    }
                }).show();
            }
            else if (elementType === Fily.Api.Idea.Element.Type.affiliate) {
                new Fily.IdeaEditAffiliateElementModalView(this.ideaId, {
                    bodyTpl: new Fily.MustacheTpl('idea/edit/affiliate_element_modal'),
                    params: {
                        id: $target.data('element-id'),
                        caption: $target.find('.js-Element-affiliate--caption').text(),
                        isEdit: true,
                        elementType: elementType,
                    },
                    doneCallback: function ($body, hide, res) {
                        var tpl = new Fily.MustacheTpl('idea/element/affiliate');
                        tpl.load().done(function () {
                            $target.find('.js-Element').html(tpl.render(res));
                            if (!($target.find('.js-Element-affiliate--caption').text().length === 0) && _this.isOfficial) {
                                $target.find(_this.textCountClass).removeAttr('hidden');
                                $(_this.currentTextCountClass, $target).html(res['description'].length);
                                $target.find(_this.maxTextCountClass).html('1000');
                            }
                            else if ($target.find('.js-Element-affiliate--caption').text().length === 0) {
                                $target.find(_this.textCountClass).attr('hidden', '');
                            }
                            setTimeout(function () {
                                Fily.ImageLoader.AspectRatioPlaceholder.init().findElement().lazyLoad();
                            }, 50);
                            hide();
                        });
                    }
                }).show();
            }
            else if (elementType === Fily.Api.Idea.Element.Type.product) {
                var productId = $target.find('.js-Product-id').data('product-id');
                var elementId_1 = $target.data('element-id');
                var features_1 = [];
                var isFeatures_1 = false;
                var official_link_show_1 = false;
                var official_link_show_only_1 = false;
                Fily.Api.Product.getItemDetail(productId).done(function (res) {
                    var official_link_flag = $(".Post-productInfo.js-Element-id.js-Product-id[data-element-id='" + elementId_1 + "']").data('official-link-flag');
                    switch (official_link_flag) {
                        case 1:
                            official_link_show_1 = true;
                            break;
                        case 2:
                            official_link_show_only_1 = true;
                            break;
                        default:
                            break;
                    }
                    if (res.features.length !== 0) {
                        isFeatures_1 = true;
                        features_1 = res.features;
                        var selectedFeatures_1 = {};
                        $(".Post-productInfo.js-Element-id.js-Product-id[data-element-id='" + elementId_1 + "'] .Post-productInfo-detailtableWrap").each(function (_, e) {
                            selectedFeatures_1[$(e).data("feature-id").toString()] = true;
                        });
                        $.each(features_1, function (_, feature) {
                            feature.checked = selectedFeatures_1[feature.id];
                        });
                    }
                    if (res.addition !== null && res.addition.pr_link) {
                        res.is_pr_link = true;
                    }
                    new Fily.IdeaEditAffiliateElementModalView(_this.ideaId, {
                        bodyTpl: new Fily.MustacheTpl('idea/edit/product_element_modal'),
                        params: {
                            id: $target.data('element-id'),
                            productDescription: $target.find('.js-Element-product--description').text(),
                            isEdit: true,
                            elementType: elementType,
                            features: features_1,
                            isFeatures: isFeatures_1,
                            isPrLink: res.is_pr_link ? true : false,
                            officialLinkShow: official_link_show_1,
                            officialLinkShowOnly: official_link_show_only_1
                        },
                        doneCallback: function ($body, hide, res) {
                            var tpl = new Fily.MustacheTpl('idea/element/product_insert');
                            tpl.load().done(function () {
                                if (res['description'] == '1位') {
                                    res['is1stRank'] = true;
                                }
                                else if (res['description'] == '2位') {
                                    res['is2ndRank'] = true;
                                }
                                else if (res['description'] == '3位') {
                                    res['is3rdRank'] = true;
                                }
                                else if (res['description'] == 'PR') {
                                    res['isPr'] = true;
                                }
                                else {
                                    res['isRank'] = true;
                                }
                                res['content']['product']['features'].length > 0 ? res['content']['product']['is_features'] = true : '';
                                res['content']['product']['representative_items'].map(function (item) {
                                    item.price = _this.formatPrice(item.price);
                                });
                                res['content']['product']['representative_items'].sort(function (x, y) {
                                    return y['platform']['id'] - x['platform']['id'];
                                });
                                switch (res['content']['product']['official_link_flag']) {
                                    case 1:
                                        if (res['content']['product']['official_item']) {
                                            res['content']['product']['representative_items'].push(res['content']['product']['official_item']);
                                        }
                                        break;
                                    case 2:
                                        if (res['content']['product']['official_item']) {
                                            res['content']['product']['representative_items'] = [res['content']['product']['official_item']];
                                            res['content']['product']['rating'] = res['content']['product']['official_item']['rating'] ? res['content']['product']['official_item']['rating'] : 0;
                                            res['content']['product']['review_count'] = res['content']['product']['official_item']['review_count'] ? res['content']['product']['official_item']['review_count'] : 0;
                                        }
                                        break;
                                    default:
                                        break;
                                }
                                res['content']['product']['rating'] > 0 ? res['content']['product']['is_rating'] = true : '';
                                res['content']['product']['price'] = _this.formatPrice(res['content']['product']['price']);
                                if (res['content']['product']['discount_price'] !== null) {
                                    res['content']['product']['discount_price'] = _this.formatPrice(res['content']['product']['discount_price']);
                                }
                                ;
                                var elementId = res['id'];
                                var productId = res['content']['product']['id'];
                                var slideId = elementId + '-' + productId;
                                res['content']['product']['images'].splice(10);
                                res['content']['product']['images'].map(function (image, index) {
                                    if (image['credit_url']) {
                                        image['credit_domain'] = (new URL(image['credit_url'])).hostname;
                                    }
                                    image['display_id'] = slideId + '-' + index;
                                });
                                if (res['description'] == 'PR' && res['content']['product']['addition']['pr_link']) {
                                    res['content']['product']['is_pr_product'] = true;
                                }
                                $target.find('.js-Element').html(tpl.render(res));
                                setTimeout(function () {
                                    new Fily.PhotoSlideView(slideId, true);
                                }, 300);
                                hide();
                            });
                        }
                    }).show();
                });
            }
            else if (elementType === Fily.Api.Idea.Element.Type.product_table) {
                return;
            }
            else if (elementType == Fily.Api.Idea.Element.Type.speech_bubble) {
                new Fily.IdeaEditOfficialElementModalView(this.ideaId, {
                    bodyTpl: new Fily.MustacheTpl('idea/edit/speechbubble_element_modal'),
                    params: {
                        id: $target.data('element-id'),
                        speaker_photo: $('.js-Element-speakerPhotoUrl', $target).attr('src'),
                        speaker_name: $('.js-Element-speakerName', $target).text(),
                        speaker_title: $('.js-Element-speakerTitle', $target).text(),
                        title: $('.js-Element-title', $target).text(),
                        description: $('<div/>').html($('.js-Element-description', $target)
                            .html()
                            .replace(/<b>([\s\S]*?)<\/b>/g, '&lt;b&gt;$1&lt;&#x2F;b&gt;')).text(),
                        credit_url: $('.js-Element-creditUrl', $target).attr('href'),
                        display_position: $('.js-Element-displayPosition', $target).data('display_position'),
                        element_type: elementType,
                    },
                    doneCallback: function ($body, hide, res) {
                        var tpl = new Fily.MustacheTpl('idea/element/speech_bubble', 'pc');
                        tpl.load().done(function () {
                            hide();
                            $(_this.elementClass, $target).html(tpl.render(res));
                            if (!($target.find('.js-Element-photo--caption').text().length === 0) && _this.isOfficial) {
                                $target.find(_this.textCountClass).removeAttr('hidden');
                                $(_this.currentTextCountClass, $target).html(res['description'].length);
                                $target.find(_this.maxTextCountClass).html('1000');
                                Fily.PlagiarismCheckView.load(_this.ideaId);
                            }
                            else if ($target.find('.js-Element-photo--caption').text().length === 0) {
                                $target.find(_this.textCountClass).attr('hidden', '');
                            }
                            setTimeout(function () {
                                Fily.ImageLoader.AspectRatioPlaceholder.init().findElement().lazyLoad();
                            }, 50);
                        });
                    }
                }).show();
            }
            else if (elementType === Fily.Api.Idea.Element.Type.list) {
                var listItems_1 = [];
                $.each($('.js-lists li', $target), function (i, val) {
                    var listText = $(val).find('.js-Element-list').text();
                    listItems_1.push(listText);
                });
                new Fily.IdeaEditOfficialElementModalView(this.ideaId, {
                    bodyTpl: new Fily.MustacheTpl('idea/edit/list_element_modal'),
                    params: {
                        id: $target.data('element-id'),
                        list_type: $('.js-lists', $target).data('list-type'),
                        items: listItems_1,
                        element_type: elementType,
                    },
                    doneCallback: function ($body, hide, res) {
                        var tpl = new Fily.MustacheTpl('idea/element/list', 'pc');
                        tpl.load().done(function () {
                            hide();
                            $(_this.elementClass, $target).html(tpl.render(res));
                            setTimeout(function () {
                                Fily.ImageLoader.AspectRatioPlaceholder.init().findElement().lazyLoad();
                            }, 50);
                        });
                    }
                }).show();
            }
            else if (elementType === Fily.Api.Idea.Element.Type.table_structure) {
                new Fily.IdeaEditOfficialElementModalView(this.ideaId, {
                    bodyTpl: new Fily.MustacheTpl('idea/edit/tablestructure_element_modal'),
                    params: {
                        id: $target.data('element-id'),
                        element_type: elementType,
                    },
                    doneCallback: function ($body, hide, res) {
                        var tpl = new Fily.MustacheTpl('idea/element/table_structure', 'pc');
                        tpl.load().done(function () {
                            location.reload();
                        });
                    }
                }).show();
            }
        };
        IdeaEditElementPanelView.prototype.formatPrice = function (price) {
            return price.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
        };
        IdeaEditElementPanelView.prototype.removeElement = function (event) {
            var $target = $(event.target).parents(this.targetClass);
            var mainPhotoId = $('.js-idea-mainPhoto').data('mainphoto-id');
            if ($target.data('element-id') === mainPhotoId) {
                alert('メイン画像に登録されているため削除できません。選択の画像を削除したい場合はメイン画像を変更してください。');
                return;
            }
            if (window.confirm('削除しますがよろしいですか？')) {
                Fily.Api.Idea.Element.remove(this.ideaId, $target.data('element-id'));
                $target.remove();
            }
        };
        IdeaEditElementPanelView.prototype.refreshElement = function ($target) {
            var elementType = this.getElementType($target);
            if (elementType == Fily.Api.Idea.Element.Type.sns) {
                var $element = $('.js-Element-sns', $target);
                var snsType = $element.data('sns_type');
                if (snsType == Fily.Api.Idea.Element.SnsType.Twitter) {
                    var tweetId = $('iframe', $element).remove().data('tweet-id');
                    Fily.Twitter.embed(tweetId, $element.get(0));
                }
                ;
            }
        };
        IdeaEditElementPanelView.prototype.getElementType = function ($target) {
            return parseInt($target.data('element-type'));
        };
        return IdeaEditElementPanelView;
    }(Fily.BaseView));
    var IdeaEditElementInsertPanelView = (function (_super) {
        __extends(IdeaEditElementInsertPanelView, _super);
        function IdeaEditElementInsertPanelView() {
            var _this = _super.call(this, { delegate: false }) || this;
            _this.viewId = 'element-insert-panel';
            _this.$body = $('.js-FieldEditor-insert');
            _this.elementTpl = new Fily.MustacheTpl('idea/edit/element');
            _this.textCountClass = '.js-textCount';
            _this.currentTextCountClass = '.js-currentTextCount';
            _this.maxTextCountClass = '.js-maxTextCount';
            _this.ideaId = $('.js-Idea').data('idea-id');
            _this.userId = $('.js-Idea').data('user-id');
            _this.userClassification = $('.js-Idea').data('user-classification');
            _this.isOfficial = $('.js-Idea').data('iam-official-user');
            _this.canUseOfficialElement = $('.js-Idea').data('can-use-official-element');
            _this.delegateEvent();
            _this.elementTpl.load();
            return _this;
        }
        IdeaEditElementInsertPanelView.prototype.events = function () {
            return {
                'click / .js-show-insertPanel': 'showInsertPanel',
                'click / .js-hide-insertPanel': 'hideInsertPanel',
                'click / .js-insert-element--title': 'insertTitle',
                'click / .js-insert-element--body': 'insertBody',
                'click / .js-insert-element--photo': 'insertPhoto',
                'click / .js-insert-element--link': 'insertLink',
                'click / .js-insert-element--affiliate': 'insertAffiliate',
                'click / .js-insert-element--official': 'insertOfficial',
                'click / .js-insert-element--movie': 'insertMovie',
                'click / .js-insert-element--sns': 'insertSns',
            };
        };
        IdeaEditElementInsertPanelView.prototype.insertTitle = function (event) {
            var _this = this;
            event.preventDefault();
            var $insertPanel = $(event.target).parents('.js-FieldEditor-insert');
            var pos = $('.js-FieldEditor-insert').index($insertPanel);
            new Fily.IdeaEditTitleElementModalView(this.ideaId, {
                bodyTpl: new Fily.MustacheTpl('idea/edit/title_element_modal'),
                params: { position: pos },
                doneCallback: function ($body, hide, res) {
                    if (_this.canUseOfficialElement) {
                        res['can_use_official_element'] = true;
                    }
                    ;
                    _this.elementTpl.load().done(function () {
                        var tpl;
                        var $elementFiled = $(_this.elementTpl.render(res));
                        if (res['type'] === Fily.Api.Idea.Element.Type.title) {
                            tpl = new Fily.MustacheTpl('idea/element/title', 'pc');
                        }
                        else if (res['type'] === Fily.Api.Idea.Element.Type.subheadline) {
                            tpl = new Fily.MustacheTpl('idea/element/subheadline', 'pc');
                        }
                        else if (res['type'] === Fily.Api.Idea.Element.Type.smallheadline) {
                            tpl = new Fily.MustacheTpl('idea/element/smallheadline', 'pc');
                        }
                        tpl.load().done(function () {
                            $elementFiled.find('.js-Element').html(tpl.render(res));
                            $insertPanel.parent().after($elementFiled);
                            hide();
                            if (_this.isOfficial) {
                                $elementFiled.find(_this.textCountClass).removeAttr('hidden');
                                $elementFiled.find(_this.currentTextCountClass).html(res['description'].length);
                                $elementFiled.find(_this.maxTextCountClass).append('60');
                            }
                            _this.hideInsertPanel(event);
                            IdeaEditView.delegateEvent();
                        });
                    });
                }
            }).show();
        };
        IdeaEditElementInsertPanelView.prototype.insertBody = function (event) {
            var _this = this;
            event.preventDefault();
            var $insertPanel = $(event.target).parents('.js-FieldEditor-insert');
            var pos = $('.js-FieldEditor-insert').index($insertPanel);
            new Fily.IdeaEditBodyElementModalView(this.ideaId, {
                bodyTpl: new Fily.MustacheTpl('idea/edit/body_element_modal'),
                params: { position: pos },
                doneCallback: function ($body, hide, res) {
                    if (_this.canUseOfficialElement) {
                        res['can_use_official_element'] = true;
                    }
                    ;
                    _this.elementTpl.load().done(function () {
                        var $elementFiled = $(_this.elementTpl.render(res));
                        var bodyTpl = new Fily.MustacheTpl('idea/element/body', 'pc');
                        bodyTpl.load().done(function () {
                            $('.js-Element', $elementFiled).html(bodyTpl.render(res));
                            $insertPanel.parent().after($elementFiled);
                            hide();
                            if (_this.isOfficial) {
                                $elementFiled.find(_this.textCountClass).removeAttr('hidden');
                                $elementFiled.find(_this.currentTextCountClass).html(res['description'].length);
                                $elementFiled.find(_this.maxTextCountClass).append('1000');
                            }
                            _this.hideInsertPanel(event);
                            IdeaEditView.delegateEvent();
                        });
                    });
                }
            }).show();
        };
        IdeaEditElementInsertPanelView.prototype.insertPhoto = function (event) {
            var _this = this;
            event.preventDefault();
            var $insertPanel = $(event.target).parents('.js-FieldEditor-insert');
            var pos = $('.js-FieldEditor-insert').index($insertPanel);
            new Fily.IdeaEditPhotoElementModalView(this.ideaId, {
                bodyTpl: new Fily.MustacheTpl('idea/edit/select_photo_element_modal'),
                size: Fily.ModalSize.FixedMedium,
                params: { position: pos },
                doneCallback: function ($body, hide, res) {
                    if (_this.canUseOfficialElement) {
                        res['can_use_official_element'] = true;
                    }
                    ;
                    var photoTpl = new Fily.MustacheTpl('idea/element/photo', 'pc');
                    if (Array.isArray(res)) {
                        _this.elementTpl.load().done(function () {
                            photoTpl.load().done(function () {
                                res.reverse().forEach(function (photo) {
                                    var $elementFiled = $(_this.elementTpl.render(photo));
                                    $('.js-Element', $elementFiled).html(photoTpl.render(photo));
                                    $insertPanel.parent().after($elementFiled);
                                });
                                setTimeout(function () {
                                    Fily.ImageLoader.AspectRatioPlaceholder.init().findElement().lazyLoad();
                                }, 50);
                                hide();
                                _this.hideInsertPanel(event);
                                IdeaEditView.delegateEvent();
                            });
                        });
                    }
                    else {
                        var $elementFiled_1 = $(_this.elementTpl.render(res));
                        _this.elementTpl.load().done(function () {
                            photoTpl.load().done(function () {
                                res['content']['photo']['is_avatar'] = (res['content']['photo']['user']['id'] != _this.userId) ? true : false;
                                $elementFiled_1.find('.js-Element').html(photoTpl.render(res));
                                setTimeout(function () {
                                    Fily.ImageLoader.AspectRatioPlaceholder.init().findElement().lazyLoad();
                                }, 50);
                                $insertPanel.parent().after($elementFiled_1);
                                hide();
                                _this.hideInsertPanel(event);
                                IdeaEditView.delegateEvent();
                            });
                        });
                    }
                }
            }).show();
        };
        IdeaEditElementInsertPanelView.prototype.insertLink = function (event) {
            var _this = this;
            event.preventDefault();
            var $insertPanel = $(event.target).parents('.js-FieldEditor-insert');
            var pos = $('.js-FieldEditor-insert').index($insertPanel);
            new Fily.IdeaEditLinkElementModalView(this.ideaId, {
                bodyTpl: new Fily.MustacheTpl('idea/edit/select_link_element_modal'),
                size: Fily.ModalSize.FixedMedium,
                params: { position: pos },
                doneCallback: function ($body, hide, res) {
                    if (_this.canUseOfficialElement) {
                        res['can_use_official_element'] = true;
                    }
                    ;
                    _this.elementTpl.load().done(function () {
                        var $elementFiled = $(_this.elementTpl.render(res));
                        var tpl;
                        if (res['type'] === Fily.Api.Idea.Element.Type.idea) {
                            tpl = new Fily.MustacheTpl('idea/element/idea', 'pc');
                            tpl.load().done(function () {
                                $('.js-Element', $elementFiled).html(tpl.render(res));
                                $insertPanel.parent().after($elementFiled);
                                hide();
                                _this.hideInsertPanel(event);
                                IdeaEditView.delegateEvent();
                            });
                        }
                        else {
                            tpl = new Fily.MustacheTpl('idea/element/link', 'pc');
                            tpl.load().done(function () {
                                $('.js-Element', $elementFiled).html(tpl.render(res));
                                $insertPanel.parent().after($elementFiled);
                                hide();
                                if (_this.isOfficial) {
                                    $elementFiled.find(_this.textCountClass).removeAttr('hidden');
                                    $elementFiled.find(_this.currentTextCountClass).html(res['description'].length);
                                    $elementFiled.find(_this.maxTextCountClass).append('60');
                                }
                                _this.hideInsertPanel(event);
                                IdeaEditView.delegateEvent();
                            });
                        }
                    });
                }
            }).show();
        };
        IdeaEditElementInsertPanelView.prototype.insertAffiliate = function (event) {
            var _this = this;
            event.preventDefault();
            var $insertPanel = $(event.target).parents('.js-FieldEditor-insert');
            var pos = $('.js-FieldEditor-insert').index($insertPanel);
            new Fily.IdeaEditAffiliateElementModalView(this.ideaId, {
                bodyTpl: new Fily.MustacheTpl('idea/edit/select_affiliate_element_modal'),
                size: Fily.ModalSize.FixedMedium,
                params: { position: pos, userClassification: this.userClassification },
                doneCallback: function ($body, hide, res) {
                    if (_this.canUseOfficialElement) {
                        res['can_use_official_element'] = true;
                    }
                    ;
                    _this.elementTpl.load().done(function () {
                        var $elementFiled = $(_this.elementTpl.render(res));
                        var tpl;
                        if (res['type'] === Fily.Api.Idea.Element.Type.product) {
                            tpl = new Fily.MustacheTpl('idea/element/product_insert', 'pc');
                            tpl.load().done(function () {
                                if (res['description'] == '1位') {
                                    res['is1stRank'] = true;
                                }
                                else if (res['description'] == '2位') {
                                    res['is2ndRank'] = true;
                                }
                                else if (res['description'] == '3位') {
                                    res['is3rdRank'] = true;
                                }
                                else if (res['description'] == 'PR') {
                                    res['isPr'] = true;
                                }
                                else {
                                    res['isRank'] = true;
                                }
                                res['content']['product']['features'].length > 0 ? res['content']['product']['is_features'] = true : '';
                                res['content']['product']['representative_items'].map(function (item) {
                                    item.price = _this.formatPrice(item.price);
                                });
                                res['content']['product']['representative_items'].sort(function (x, y) {
                                    return y['platform']['id'] - x['platform']['id'];
                                });
                                switch (res['content']['product']['official_link_flag']) {
                                    case 1:
                                        if (res['content']['product']['official_item']) {
                                            res['content']['product']['representative_items'].push(res['content']['product']['official_item']);
                                        }
                                        break;
                                    case 2:
                                        if (res['content']['product']['official_item']) {
                                            res['content']['product']['representative_items'] = [res['content']['product']['official_item']];
                                            res['content']['product']['rating'] = res['content']['product']['official_item']['rating'] ? res['content']['product']['official_item']['rating'] : 0;
                                            res['content']['product']['review_count'] = res['content']['product']['official_item']['review_count'] ? res['content']['product']['official_item']['review_count'] : 0;
                                        }
                                        break;
                                    default:
                                        break;
                                }
                                res['content']['product']['rating'] > 0 ? res['content']['product']['is_rating'] = true : '';
                                res['content']['product']['price'] = _this.formatPrice(res['content']['product']['price']);
                                if (res['content']['product']['discount_price'] !== null) {
                                    res['content']['product']['discount_price'] = _this.formatPrice(res['content']['product']['discount_price']);
                                }
                                ;
                                var elementId = res['id'];
                                var productId = res['content']['product']['id'];
                                var slideId = elementId + '-' + productId;
                                res['content']['product']['images'].splice(10);
                                res['content']['product']['images'].map(function (image, index) {
                                    if (image['credit_url']) {
                                        image['credit_domain'] = (new URL(image['credit_url'])).hostname;
                                    }
                                    image['display_id'] = slideId + '-' + index;
                                });
                                if (res['content']['product']['addition'] !== null && res['description'] == 'PR' && res['content']['product']['addition']['pr_link']) {
                                    res['content']['product']['is_pr_product'] = true;
                                }
                                $('.js-Element', $elementFiled).html(tpl.render(res));
                                $insertPanel.parent().after($elementFiled);
                                hide();
                                _this.hideInsertPanel(event);
                                IdeaEditView.delegateEvent();
                                setTimeout(function () {
                                    new Fily.PhotoSlideView(slideId, true);
                                }, 300);
                            });
                        }
                        else if (res['type'] === Fily.Api.Idea.Element.Type.product_table) {
                            tpl = new Fily.MustacheTpl('idea/element/product_table_insert', 'pc');
                            Fily.Api.Idea.get(_this.ideaId).done(function (idea) {
                                tpl.load().done(function () {
                                    if (res['content']['product_table']['elements'].length === 0) {
                                        res['isProductElement'] = false;
                                        $('.js-Element', $elementFiled).html(tpl.render(res));
                                        $insertPanel.parent().after($elementFiled);
                                        hide();
                                        _this.hideInsertPanel(event);
                                        return;
                                    }
                                    $.each(res['content']['product_table']['elements'], function (index, tableElement) {
                                        $.each(idea['elements'], function (index, ideaElement) {
                                            res['position'] = pos;
                                            if (tableElement.id == ideaElement.id) {
                                                var isPrElement = ideaElement.description.search('PR');
                                                if (ideaElement.type === Fily.Api.Idea.Element.Type.product && isPrElement === -1) {
                                                    tableElement.table_elements = ideaElement;
                                                }
                                            }
                                        });
                                    });
                                    var targetElements = [];
                                    targetElements = res['content']['product_table']['elements'].filter(function (value) {
                                        if (value.table_elements) {
                                            return value;
                                        }
                                    });
                                    if (targetElements.length !== 0) {
                                        var featureItemsLists_1 = [];
                                        res['isProductElement'] = true;
                                        res['isFeatureTitle'] = true;
                                        targetElements.map(function (targetElement, index, array) {
                                            targetElement.table_elements.description = targetElement.table_elements.description.replace('位', '');
                                            var productLink = {};
                                            var productLinks = [];
                                            $.each(targetElement.table_elements.content.product.representative_items, function (index, item) {
                                                item.price = _this.formatPrice(item.price);
                                                if (item.shipping_type === 0) {
                                                    item.shopping_type_text = '送料無料';
                                                }
                                                else if (item.shipping_type === 1) {
                                                    item.shopping_type_text = '送料別';
                                                }
                                                else if (item.shipping_type === 2 || item.shipping_type === 3) {
                                                    item.shopping_type_text = '送料要確認';
                                                }
                                                if (item.platform.id === '0') {
                                                    item.platform_logo = 'yahoo';
                                                    productLink = {
                                                        affiliate_url: item.affiliate_url,
                                                        platform: {
                                                            id: item.platform.id,
                                                            name: 'Yahoo!'
                                                        },
                                                        link_position: 2,
                                                        button_number: 4
                                                    };
                                                }
                                                else if (item.platform.id === '1') {
                                                    item.platform_logo = 'amazon';
                                                    productLink = {
                                                        affiliate_url: item.affiliate_url,
                                                        platform: item.platform,
                                                        link_position: 1,
                                                        button_number: 3
                                                    };
                                                }
                                                else if (item.platform.id === '2') {
                                                    item.platform_logo = 'rakuten';
                                                    productLink = {
                                                        affiliate_url: item.affiliate_url,
                                                        platform: item.platform,
                                                        link_position: 0,
                                                        button_number: 5
                                                    };
                                                }
                                                productLinks.push(productLink);
                                            });
                                            productLinks.sort(function (a, b) {
                                                return a.link_position - b.link_position;
                                            });
                                            targetElement.table_elements.content.product.product_links = productLinks;
                                            if (index === 0) {
                                                featureItemsLists_1 = targetElement.table_elements.content.product.features.map(function (feature, index) {
                                                    return {
                                                        title: feature.title,
                                                    };
                                                });
                                            }
                                            if (targetElement.table_elements.content.product.addition === null) {
                                                res['isFeatureTitle'] = false;
                                            }
                                        });
                                        var orderDirection = res['description'];
                                        if (orderDirection === '1') {
                                            targetElements.sort(function (a, b) {
                                                return a.order_num - b.order_num;
                                            });
                                        }
                                        else if (orderDirection === '2') {
                                            targetElements.sort(function (a, b) {
                                                return b.order_num - a.order_num;
                                            });
                                        }
                                        var lists_1 = [];
                                        featureItemsLists_1.map(function (item, index) {
                                            var list = {};
                                            var descriptions = [];
                                            targetElements.map(function (element, index, array) {
                                                element.table_elements.content.product.features.map(function (feature) {
                                                    if (item.title == feature.title) {
                                                        descriptions.push(feature.description);
                                                    }
                                                });
                                            });
                                            list = {
                                                title: item.title,
                                                descriptions: descriptions
                                            };
                                            if (descriptions.length == targetElements.length) {
                                                lists_1.push(list);
                                            }
                                        });
                                        res['content']['product_table']['feature_lists'] = lists_1;
                                        res['content']['product_table']['elements'] = targetElements;
                                    }
                                    var checkComparisonTable = confirm('「ランキング順位、PR表記入欄」が未入力の商品があります。\n表を追加しますか？');
                                    if (checkComparisonTable) {
                                        $('.js-Element', $elementFiled).html(tpl.render(res));
                                        $insertPanel.parent().after($elementFiled);
                                        hide();
                                        _this.hideInsertPanel(event);
                                        IdeaEditView.delegateEvent();
                                    }
                                });
                            });
                        }
                        else {
                            tpl = new Fily.MustacheTpl('idea/element/affiliate', 'pc');
                            tpl.load().done(function () {
                                $('.js-Element', $elementFiled).html(tpl.render(res));
                                setTimeout(function () {
                                    Fily.ImageLoader.AspectRatioPlaceholder.init().findElement().lazyLoad();
                                }, 50);
                                $insertPanel.parent().after($elementFiled);
                                hide();
                                _this.hideInsertPanel(event);
                                IdeaEditView.delegateEvent();
                            });
                        }
                    });
                }
            }).show();
        };
        IdeaEditElementInsertPanelView.prototype.formatPrice = function (price) {
            return price.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
        };
        IdeaEditElementInsertPanelView.prototype.insertMovie = function (event) {
            var _this = this;
            event.preventDefault();
            var $insertPanel = $(event.target).parents('.js-FieldEditor-insert');
            var pos = $('.js-FieldEditor-insert').index($insertPanel);
            new Fily.IdeaEditMovieElementModalView(this.ideaId, {
                bodyTpl: new Fily.MustacheTpl('idea/edit/movie_element_modal'),
                params: { position: pos },
                doneCallback: function ($body, hide, res) {
                    if (_this.canUseOfficialElement) {
                        res['can_use_official_element'] = true;
                    }
                    ;
                    _this.elementTpl.load().done(function () {
                        var $elementFiled = $(_this.elementTpl.render(res));
                        var movieTpl = new Fily.MustacheTpl('idea/element/movie', 'pc');
                        movieTpl.load().done(function () {
                            $('.js-Element', $elementFiled).html(movieTpl.render(res));
                            $insertPanel.parent().after($elementFiled);
                            hide();
                            if (!($elementFiled.find('.js-Element-movie--caption').text().length === 0) && _this.isOfficial) {
                                $elementFiled.find(_this.textCountClass).removeAttr('hidden');
                                $elementFiled.find(_this.currentTextCountClass).html(res['description'].length);
                                $elementFiled.find(_this.maxTextCountClass).append('800');
                            }
                            _this.hideInsertPanel(event);
                            IdeaEditView.delegateEvent();
                        });
                    });
                }
            }).show();
        };
        IdeaEditElementInsertPanelView.prototype.insertSns = function (event) {
            var _this = this;
            event.preventDefault();
            var $insertPanel = $(event.target).parents('.js-FieldEditor-insert');
            var pos = $('.js-FieldEditor-insert').index($insertPanel);
            new Fily.IdeaEditSnsElementModalView(this.ideaId, {
                bodyTpl: new Fily.MustacheTpl('idea/edit/select_sns_element_modal'),
                size: Fily.ModalSize.FixedMedium,
                params: { position: pos },
                doneCallback: function ($body, hide, res) {
                    if (_this.canUseOfficialElement) {
                        res['can_use_official_element'] = true;
                    }
                    ;
                    _this.elementTpl.load().done(function () {
                        var $elementFiled = $(_this.elementTpl.render(res));
                        var snsTpl = new Fily.MustacheTpl('idea/element/sns');
                        snsTpl.load().done(function () {
                            $('.js-Element', $elementFiled).html(snsTpl.render(res));
                            $insertPanel.parent().after($elementFiled);
                            if (res['content']['sns_type'] == Fily.Api.Idea.Element.SnsType.Instagram)
                                Fily.Instagram.embed();
                            hide();
                            _this.hideInsertPanel(event);
                            IdeaEditView.delegateEvent();
                        });
                    });
                }
            }).show();
        };
        IdeaEditElementInsertPanelView.prototype.insertOfficial = function (event) {
            var _this = this;
            event.preventDefault();
            var $insertPanel = $(event.target).parents('.js-FieldEditor-insert');
            var pos = $('.js-FieldEditor-insert').index($insertPanel);
            new Fily.IdeaEditOfficialElementModalView(this.ideaId, {
                bodyTpl: new Fily.MustacheTpl('idea/edit/select_official_element_modal'),
                size: Fily.ModalSize.FixedMedium,
                params: { position: pos, userClassification: this.userClassification },
                doneCallback: function ($body, hide, res) {
                    if (_this.canUseOfficialElement) {
                        res['can_use_official_element'] = true;
                    }
                    ;
                    _this.elementTpl.load().done(function () {
                        var $elementFiled = $(_this.elementTpl.render(res));
                        var tpl;
                        if (res['type'] === Fily.Api.Idea.Element.Type.speech_bubble) {
                            var tpl_1 = new Fily.MustacheTpl('idea/element/speech_bubble', 'pc');
                            tpl_1.load().done(function () {
                                $elementFiled.find('.js-Element').html(tpl_1.render(res));
                                setTimeout(function () {
                                    Fily.ImageLoader.AspectRatioPlaceholder.init().findElement().lazyLoad();
                                }, 50);
                                $insertPanel.parent().after($elementFiled);
                                hide();
                                _this.hideInsertPanel(event);
                                IdeaEditView.delegateEvent();
                            });
                        }
                        else if (res['type'] === Fily.Api.Idea.Element.Type.list) {
                            var tpl_2 = new Fily.MustacheTpl('idea/element/list', 'pc');
                            tpl_2.load().done(function () {
                                $elementFiled.find('.js-Element').html(tpl_2.render(res));
                                $insertPanel.parent().after($elementFiled);
                                hide();
                                _this.hideInsertPanel(event);
                                IdeaEditView.delegateEvent();
                            });
                        }
                        else if (res['type'] === Fily.Api.Idea.Element.Type.table_structure) {
                            var tpl_3 = new Fily.MustacheTpl('idea/element/table_structure', 'pc');
                            tpl_3.load().done(function () {
                                IdeaEditView.delegateEvent();
                                location.reload();
                            });
                        }
                    });
                }
            }).show();
        };
        IdeaEditElementInsertPanelView.prototype.showInsertPanel = function (event) {
            $(event.target).next().addClass('is-show');
        };
        IdeaEditElementInsertPanelView.prototype.hideInsertPanel = function (event) {
            $(event.target).parents('.is-show').removeClass('is-show');
        };
        return IdeaEditElementInsertPanelView;
    }(Fily.BaseView));
    var IdeaEditElementView = (function (_super) {
        __extends(IdeaEditElementView, _super);
        function IdeaEditElementView() {
            var _this = _super.call(this, { delegate: false }) || this;
            _this.elementTpl = new Fily.MustacheTpl('idea/edit/element');
            _this.targetClass = '.js-FieldEditor-target';
            _this.ideaId = $('.js-Idea').data('idea-id');
            _this.userClassification = $('.js-Idea').data('user-classification');
            _this.delegateEvent();
            return _this;
        }
        IdeaEditElementView.prototype.events = function () {
            return {
                'click / .js-add-elementChild--affiliate': 'insertAffiliateLink',
            };
        };
        IdeaEditElementView.prototype.insertAffiliateLink = function (event) {
            var _this = this;
            event.preventDefault();
            var $target = $(event.target).parents(this.targetClass);
            new Fily.IdeaEditAffiliateElementModalView(this.ideaId, {
                bodyTpl: new Fily.MustacheTpl('idea/edit/select_affiliate_element_modal'),
                size: Fily.ModalSize.FixedMedium,
                params: {
                    id: $target.data('element-id'),
                    userClassification: this.userClassification
                },
                doneCallback: function ($body, hide, res) {
                    _this.elementTpl.load().done(function () {
                        var tpl = new Fily.MustacheTpl('idea/element/affiliate', 'pc');
                        tpl.load().done(function () {
                            $target.find('.js-Element').html(tpl.render(res));
                            setTimeout(function () {
                                Fily.ImageLoader.AspectRatioPlaceholder.init().findElement().lazyLoad();
                            }, 50);
                            hide();
                        });
                    });
                }
            }).show();
        };
        return IdeaEditElementView;
    }(Fily.BaseView));
})(Fily || (Fily = {}));
