var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var Fily;
(function (Fily) {
    var IdeaEditLinkElementModalView = (function (_super) {
        __extends(IdeaEditLinkElementModalView, _super);
        function IdeaEditLinkElementModalView(ideaId, options) {
            var _this = _super.call(this, options) || this;
            _this.ideaId = ideaId;
            return _this;
        }
        Object.defineProperty(IdeaEditLinkElementModalView, "ALL_IDEAS_LOAD_LIMIT", {
            get: function () { return 40; },
            enumerable: true,
            configurable: true
        });
        ;
        Object.defineProperty(IdeaEditLinkElementModalView, "ALL_IDEAS_LOAD_OFFSET", {
            get: function () { return 40; },
            enumerable: true,
            configurable: true
        });
        ;
        Object.defineProperty(IdeaEditLinkElementModalView, "ALL_IDEAS_LOAD_INTERVAL", {
            get: function () { return 220; },
            enumerable: true,
            configurable: true
        });
        ;
        IdeaEditLinkElementModalView.prototype.events = function () {
            return $.extend(_super.prototype.events.call(this), {
                'submit / .js-searchAllIdea': 'searchAllIdea',
            });
        };
        IdeaEditLinkElementModalView.prototype.render = function () {
            _super.prototype.render.call(this);
            this.$inputText = this.$body.find('input[name=linktext]');
            this.$inputUrl = this.$body.find('input[name=linkurl]');
            this.$inputIdeaId = this.$body.find('input[name=ideaid]');
            if (this.params.id) {
                this.$inputText.val(this.params.text);
                this.$inputUrl.val(this.params.url);
            }
            this.linktextCounter = new Fily.InputCounter(this.$inputText, $('.js-counter-linktext'));
            this.linkurlCounter = new Fily.InputCounter(this.$inputUrl, $('.js-counter-linkurl'));
            if (!this.params.id) {
                this.ideasTpl = new Fily.MustacheTpl('idea/edit/select_idea');
                this.loadMyIdeaList();
                this.$allIdeasStreamWrapper = this.$body.find('#js-allIdeasStreamWrapper');
                this.$allIdeasStream = this.$allIdeasStreamWrapper.find('#js-allIdeasStream');
                this.$loaderAllIdea = this.$allIdeasStreamWrapper.find('.js-loader-allIdea');
            }
        };
        IdeaEditLinkElementModalView.prototype.done = function (event) {
            var _this = this;
            if (this.params.id) {
                var invalid = false;
                if (!this.linktextCounter.checkRequire()) {
                    Fily.Field.Err.show(this.$inputText, Fily.ERR.CODE.INPUT_EMPTY, ['リンクのテキスト']);
                    invalid = true;
                }
                if (!this.linktextCounter.checkLength()) {
                    Fily.Field.Err.show(this.$inputText, Fily.ERR.CODE.INPUT_MAX_LENGTH_INVALID, ['リンクのテキスト', this.linktextCounter.maxLength.toString()]);
                    invalid = true;
                }
                var url = this.$inputUrl.val();
                if (!Fily.Util.isUrl(url)) {
                    Fily.Field.Err.show(this.$inputUrl, Fily.ERR.CODE.INPUT_FORMAT_INVALID, ['URL']);
                    invalid = true;
                }
                if (!this.linkurlCounter.checkRequire()) {
                    Fily.Field.Err.show(this.$inputUrl, Fily.ERR.CODE.INPUT_EMPTY, ['URL']);
                    invalid = true;
                }
                if (!this.linkurlCounter.checkLength()) {
                    Fily.Field.Err.show(this.$inputUrl, Fily.ERR.CODE.INPUT_MAX_LENGTH_INVALID, ['URL', this.linkurlCounter.maxLength.toString()]);
                    invalid = true;
                }
                if (invalid)
                    return;
                Fily.showFullLoading();
                Fily.Api.Idea.Element.edit(this.ideaId, this.params.id, {
                    label: this.$inputText.val(),
                    url: url
                }).done(function (res) { return _super.prototype.done.call(_this, res); })
                    .fail(function () { return Fily.Ngword.checkInput([_this.$inputText]); })
                    .always(function () { return Fily.hideFullLoading(); });
            }
            else {
                var $target = $(event.currentTarget);
                if ($target.hasClass('js-select-idea')) {
                    Fily.showFullLoading();
                    Fily.Api.Idea.Element.create(this.ideaId, Fily.Api.Idea.Element.Type.idea, {
                        idea_id: $target.parents('.js-article-idea').data('idea-id')
                    }, this.params.position).done(function (res) { return _super.prototype.done.call(_this, res); })
                        .always(function () { return Fily.hideFullLoading(); });
                }
                else if ($target.hasClass('js-select-linkurl')) {
                    var invalid = false;
                    if (!this.linktextCounter.checkRequire()) {
                        Fily.Field.Err.show(this.$inputText, Fily.ERR.CODE.INPUT_EMPTY, ['テキスト']);
                        invalid = true;
                    }
                    if (!this.linktextCounter.checkLength()) {
                        Fily.Field.Err.show(this.$inputText, Fily.ERR.CODE.INPUT_MAX_LENGTH_INVALID, ['テキスト', this.linktextCounter.maxLength.toString()]);
                        invalid = true;
                    }
                    var url = this.$inputUrl.val();
                    if (!Fily.Util.isUrl(url)) {
                        Fily.Field.Err.show(this.$inputUrl, Fily.ERR.CODE.INPUT_FORMAT_INVALID, ['URL']);
                        invalid = true;
                    }
                    if (!this.linkurlCounter.checkRequire()) {
                        Fily.Field.Err.show(this.$inputUrl, Fily.ERR.CODE.INPUT_EMPTY, ['URL']);
                        invalid = true;
                    }
                    if (!this.linkurlCounter.checkLength()) {
                        Fily.Field.Err.show(this.$inputUrl, Fily.ERR.CODE.INPUT_MAX_LENGTH_INVALID, ['URL', this.linkurlCounter.maxLength.toString()]);
                        invalid = true;
                    }
                    if (invalid)
                        return;
                    Fily.showFullLoading();
                    Fily.Api.Idea.Element.create(this.ideaId, Fily.Api.Idea.Element.Type.link, {
                        label: this.$inputText.val(),
                        url: url
                    }, this.params.position).done(function (res) { return _super.prototype.done.call(_this, res); })
                        .fail(function () { return Fily.Ngword.checkInput([_this.$inputText]); })
                        .always(function () { return Fily.hideFullLoading(); });
                }
                else if ($target.hasClass('js-select-ideaid')) {
                    var invalid = false;
                    var ideaId = this.$inputIdeaId.val();
                    if (ideaId === 0 || ideaId === '') {
                        Fily.Field.Err.show(this.$inputIdeaId, Fily.ERR.CODE.INPUT_EMPTY, ['アイデアID']);
                        invalid = true;
                    }
                    if (invalid) {
                        $target.addClass('is-error');
                        setTimeout(function () {
                            $target.removeClass('is-error');
                        }, 1000);
                        return;
                    }
                    ;
                    Fily.showFullLoading();
                    Fily.Api.Idea.Element.create(this.ideaId, Fily.Api.Idea.Element.Type.idea, {
                        idea_id: ideaId
                    }, this.params.position).done(function (res) { return _super.prototype.done.call(_this, res); })
                        .fail(function (err) {
                        if (err.responseJSON.error_code === 50012) {
                            Fily.Field.Err.show(_this.$inputIdeaId, Fily.ERR.CODE.ID_INVALID);
                            return;
                        }
                    })
                        .always(function () { return Fily.hideFullLoading(); });
                }
            }
        };
        IdeaEditLinkElementModalView.prototype.loadMyIdeaList = function () {
            var _this = this;
            var $myIdeasStreamWrapper = this.$body.find('#js-myIdeasStreamWrapper');
            var $myIdeasStream = $myIdeasStreamWrapper.find('#js-myIdeasStream');
            var $loaderMyIdea = $myIdeasStreamWrapper.find('.js-loader-myIdea');
            var MY_IDEAS_LOAD_LIMIT = 40;
            var MY_IDEAS_LOAD_OFFSET = 40;
            var MY_IDEAS_LOAD_INTERVAL = 200;
            var error = null;
            var params = null;
            var myIdeasAutoLoader = Fily.AutoLoader.init({
                eventType: 'scroll',
                limit: MY_IDEAS_LOAD_LIMIT,
                offset: MY_IDEAS_LOAD_OFFSET,
                intervalLoad: MY_IDEAS_LOAD_INTERVAL,
                $targetWrapper: $myIdeasStreamWrapper,
                $target: $myIdeasStream,
                $loader: $loaderMyIdea,
                sender: function (offset, limit) {
                    return Fily.Api.Idea.getUserIdeaList(limit, offset);
                },
                dataFormat: function (data, limit) {
                    if (data && data.length < limit) {
                        error = { 'isLimitItem': true };
                    }
                    return (error !== null) ? { ideas: data, length: data.length, error: error } : { ideas: data, length: data.length };
                },
                tpl: this.ideasTpl,
            });
            $loaderMyIdea.show();
            Fily.showLoading($loaderMyIdea);
            Fily.Api.Idea.getUserIdeaList(MY_IDEAS_LOAD_LIMIT).done(function (res) {
                _this.ideasTpl.load().done(function () {
                    if (res && res.length < MY_IDEAS_LOAD_LIMIT) {
                        error = { 'isLimitItem': true };
                    }
                    else if (!res) {
                        error = { 'isNoItem': true };
                    }
                    if (error !== null) {
                        myIdeasAutoLoader = null;
                        $myIdeasStreamWrapper.off('scroll.autoload');
                        $loaderMyIdea.hide();
                        params = { ideas: res, error: error };
                    }
                    else {
                        params = { ideas: res };
                    }
                    $myIdeasStream.html(_this.ideasTpl.render(params));
                }).always(function () { return Fily.hideLoading($loaderMyIdea); });
            }).fail(function () { return Fily.hideLoading($loaderMyIdea); });
        };
        IdeaEditLinkElementModalView.prototype.searchAllIdea = function () {
            var _this = this;
            this.$allIdeasStream.empty();
            var inputSearchValue = this.$body.find('[name="inputSearchAllIdea"]').val();
            if (inputSearchValue === '') {
                return false;
            }
            var error = null;
            var params = null;
            delete (this.allIdeasAutoLoader);
            this.$allIdeasStreamWrapper.off('scroll.autoload');
            this.allIdeasAutoLoader = Fily.AutoLoader.init({
                eventType: 'scroll',
                limit: IdeaEditLinkElementModalView.ALL_IDEAS_LOAD_LIMIT,
                offset: IdeaEditLinkElementModalView.ALL_IDEAS_LOAD_OFFSET,
                intervalLoad: IdeaEditLinkElementModalView.ALL_IDEAS_LOAD_INTERVAL,
                $targetWrapper: this.$allIdeasStreamWrapper,
                $target: this.$allIdeasStream,
                $loader: this.$loaderAllIdea,
                sender: function (offset, limit) {
                    return Fily.Api.Search.getSearch(inputSearchValue, 3, limit, offset);
                },
                dataFormat: function (data, limit) {
                    var ideas = data.contents.map(function (item) {
                        return item.content;
                    });
                    if (ideas.length < limit) {
                        error = { 'isLimitItem': true };
                    }
                    return (error !== null) ? { ideas: ideas, length: ideas.length, error: error } : { ideas: ideas, length: ideas.length };
                },
                tpl: this.ideasTpl,
            });
            this.$loaderAllIdea.show();
            Fily.showLoading(this.$loaderAllIdea);
            Fily.Api.Search.getSearch(inputSearchValue, 3).done(function (res) {
                var ideas = res.contents.map(function (item) {
                    return item.content;
                });
                if (ideas.length < IdeaEditLinkElementModalView.ALL_IDEAS_LOAD_LIMIT) {
                    if (ideas.length === 0) {
                        error = { 'keyword': inputSearchValue, 'isNoExactMatches': true };
                    }
                    else {
                        error = { 'isLimitItem': true };
                    }
                }
                if (error !== null) {
                    delete (_this.allIdeasAutoLoader);
                    _this.$allIdeasStreamWrapper.off('scroll.autoload');
                    _this.$loaderAllIdea.hide();
                    params = { ideas: ideas, error: error };
                }
                else {
                    params = { ideas: ideas };
                }
                _this.ideasTpl.load().done(function () {
                    _this.$allIdeasStream.html(_this.ideasTpl.render(params));
                }).always(function () { return Fily.hideLoading(_this.$loaderAllIdea); });
            }).fail(function () { return Fily.hideLoading(_this.$loaderAllIdea); });
            return false;
        };
        return IdeaEditLinkElementModalView;
    }(Fily.ModalView));
    Fily.IdeaEditLinkElementModalView = IdeaEditLinkElementModalView;
})(Fily || (Fily = {}));
