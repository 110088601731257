var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var Fily;
(function (Fily) {
    var PlagiarismCheckView = (function (_super) {
        __extends(PlagiarismCheckView, _super);
        function PlagiarismCheckView(id) {
            var _this = _super.call(this, { delegate: false }) || this;
            _this.$checkerElement = $('.js-plagiarismChecker');
            _this.$plagiarismCheckeResultElement = $('#js-plagiarismCheckeResult');
            _this.delegateEvent();
            _this.ideaId = id;
            Fily.Api.Idea.report(_this.ideaId).done(function (res) {
                if (res.hash.length === 0) {
                    _this.status = 'short';
                }
                else if (res.requestable == false) {
                    if (res.result.length > 0) {
                        _this.status = 'checked';
                        _this.renderCheckResult(res);
                    }
                    else {
                        _this.status = 'checking';
                    }
                }
                else if (res.requestable == true) {
                    if (res.result.length > 0) {
                        _this.status = 'checkedAgain';
                    }
                    else {
                        _this.status = 'unchecked';
                    }
                }
                _this.renderCheckerElement(res);
            });
            return _this;
        }
        PlagiarismCheckView.prototype.events = function () {
            return {
                'click / .js-clickPlagiarismText': 'clickPlagiarismText',
                'click / .js-plagiarismCheckeStart': 'plagiarismCheckeStart',
            };
        };
        PlagiarismCheckView.prototype.renderCheckerElement = function (res) {
            var _this = this;
            if (this.status == 'checking') {
                res.isChecking = true;
            }
            else if (this.status == 'checked') {
                res.isChecked = true;
            }
            else if (this.status == 'short') {
                res.isShort = true;
            }
            var tpl = new Fily.MustacheTpl('idea/edit/copy_check');
            tpl.load().done(function () {
                _this.$checkerElement.html(tpl.render(res));
            });
        };
        PlagiarismCheckView.prototype.plagiarismCheckeStart = function () {
            var _this = this;
            if (this.status === 'checking') {
                return;
            }
            if (this.status === 'checked') {
                return;
            }
            if (this.status === 'short') {
                return;
            }
            var check = confirm('コピペチェックを開始します\n本当によろしいですか？');
            if (check) {
                this.status = 'checking';
                Fily.Api.Idea.check(this.ideaId).done(function () {
                    _this.$checkerElement.find('.js-plagiarismCheckeStart').text('コピペチェック中').addClass('is-disabled');
                    _this.$checkerElement.find('.js-plagiarismChecking').show();
                });
            }
            else {
                return false;
            }
        };
        PlagiarismCheckView.prototype.renderCheckResult = function (res) {
            var _this = this;
            var tpl = new Fily.MustacheTpl('idea/edit/copy_check_result');
            res.result.map(function (item) {
                item.average = Math.floor(item.average * 100);
                item.diffs = Object.keys(item.diffs).map(function (key) { return item.diffs[key]; });
                item.diffs = item.diffs.sort(function (t1, t2) { return t2['text'].length - t1['text'].length; });
                return item;
            });
            tpl.load().done(function () {
                _this.$plagiarismCheckeResultElement.html(tpl.render(res));
            });
        };
        PlagiarismCheckView.prototype.clickPlagiarismText = function (event) {
            event.preventDefault();
            $('#js-plagiarismCheckeResult').find('.ideaTextCopyCheck-currentTarget').removeClass('ideaTextCopyCheck-currentTarget');
            var $target = $(event.currentTarget);
            $target.addClass('ideaTextCopyCheck-currentTarget');
            var matchText = $target.find('.js-matchText').text();
            var str = matchText;
            var listener = function (e) {
                e.clipboardData.setData("text/plain", str);
                e.preventDefault();
                document.removeEventListener("copy", listener);
            };
            document.addEventListener("copy", listener);
            document.execCommand("copy");
        };
        return PlagiarismCheckView;
    }(Fily.BaseView));
    Fily.PlagiarismCheckView = PlagiarismCheckView;
})(Fily || (Fily = {}));
