var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var Fily;
(function (Fily) {
    var KeywordSuggestBodyView = (function (_super) {
        __extends(KeywordSuggestBodyView, _super);
        function KeywordSuggestBodyView() {
            var _this = _super.call(this, {}) || this;
            _this.tpl = new Fily.MustacheTpl('keyword/suggest_item');
            _this.IGNORE_KEYS = [
                9,
                16,
                17,
                18,
                21,
                22,
                27,
                32,
                37,
                38,
                39,
                40,
                91
            ];
            _this.$input = $('#js-keywordSearchBody');
            _this.$suggest = $('#js-keywordSuggestAreaBody');
            _this.isSuggested = false;
            _this.$body.on('click', function (e) {
                if (e.target.id !== 'js-keywordSearchBody') {
                    _this.close();
                }
            });
            return _this;
        }
        KeywordSuggestBodyView.prototype.events = function () {
            var _this = this;
            return {
                'keydown / #js-keywordSearchBody': function (e) {
                    if (!_this.isSuggested)
                        return;
                    if (_this.isEnterKey(e.keyCode))
                        _this.submitFromSuggest();
                    if (_this.isUpKey(e.keyCode) || _this.isDownKey(e.keyCode))
                        _this.selectSuggest(e.keyCode);
                },
                'keyup paste propertychange / #js-keywordSearchBody': function (e) {
                    if (_this.IGNORE_KEYS.indexOf(e.keyCode) > -1)
                        return;
                    var val = e.target.value;
                    if (!val) {
                        _this.close();
                        return;
                    }
                    _this.showSuggest(val);
                },
                'click / .js-suggestKeyword > a': function (e) {
                    e.stopPropagation();
                    _this.$input.val($(e.currentTarget).parent().data('word'));
                }
            };
        };
        KeywordSuggestBodyView.prototype.showSuggest = function (val) {
            var _this = this;
            $.when(Fily.Api.Keywords.suggest(val), this.tpl.load()).done(function (res) { return _this.renderSuggest(res); });
        };
        KeywordSuggestBodyView.prototype.renderSuggest = function (res) {
            this.$suggest.empty().append(this.tpl.render({ keywords: res.suggest.suggestions })).addClass('is-active');
            this.isSuggested = true;
        };
        KeywordSuggestBodyView.prototype.selectSuggest = function (keyCode) {
            var $keywordItems = $('.js-suggestKeyword', this.$suggest);
            var hasCurrent = $keywordItems.hasClass('is-current');
            var $currentItem = $keywordItems.filter('.is-current').removeClass('is-current');
            if (this.isUpKey(keyCode)) {
                if ($currentItem.prev().length) {
                    $currentItem.prev().addClass('is-current');
                }
                else if (!hasCurrent) {
                    $keywordItems.last().addClass('is-current');
                }
            }
            if (this.isDownKey(keyCode)) {
                if ($currentItem.next().length) {
                    $currentItem.next().addClass('is-current');
                }
                else if (!hasCurrent) {
                    $keywordItems.first().addClass('is-current');
                }
            }
        };
        KeywordSuggestBodyView.prototype.submitFromSuggest = function () {
            var keyword = $('.js-suggestKeyword').filter('.is-current').data('word') || this.$input.val();
            this.$input.val(keyword);
        };
        KeywordSuggestBodyView.prototype.close = function () {
            if (this.isSuggested) {
                this.$suggest.empty().removeClass('is-active');
                this.isSuggested = false;
            }
        };
        KeywordSuggestBodyView.prototype.isEnterKey = function (keyCode) {
            return keyCode === 13;
        };
        KeywordSuggestBodyView.prototype.isUpKey = function (keyCode) {
            return keyCode === 38;
        };
        KeywordSuggestBodyView.prototype.isDownKey = function (keyCode) {
            return keyCode === 40;
        };
        return KeywordSuggestBodyView;
    }(Fily.BaseView));
    Fily.KeywordSuggestBodyView = KeywordSuggestBodyView;
})(Fily || (Fily = {}));
