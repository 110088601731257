var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var Fily;
(function (Fily) {
    var PhotoSlideView = (function (_super) {
        __extends(PhotoSlideView, _super);
        function PhotoSlideView(slideId, isInsertElement) {
            var _this = _super.call(this, { delegate: false }) || this;
            _this.$photoContainer = $('.js-photo-container');
            _this.$photoContent = $('.js-photo-content');
            _this.$slideRightButton = $('.js-slide-right-button');
            _this.$slideLeftButton = $('.js-slide-left-button');
            if (typeof slideId === 'string' && slideId) {
                _this.slideForItemMaster(slideId, isInsertElement);
            }
            else {
                _this.slideForPhoto();
            }
            return _this;
        }
        PhotoSlideView.prototype.slideForPhoto = function () {
            this.totalphotoCount = this.$photoContent.children('.js-slider-photos').length;
            if (this.totalphotoCount >= 1) {
                this.$photoContent.slick({
                    dots: true,
                    initialSlide: this.photoOrderNum,
                    prevArrow: this.$slideLeftButton,
                    nextArrow: this.$slideRightButton,
                    lazyLoad: 'progressive',
                    infinite: false,
                });
            }
            this.displayPhotos();
        };
        PhotoSlideView.prototype.slideForItemMaster = function (slideId, isInsertElement) {
            $('.js-photoSlideFor' + slideId).on('init', function () {
                $('.js-productCredit-' + slideId + '-0').removeAttr('hidden');
            }).slick({
                slidesToShow: 1,
                slidesToScroll: 1,
                dots: true,
                prevArrow: $('.js-slide-left-button' + slideId),
                nextArrow: $('.js-slide-right-button' + slideId),
                lazyLoad: 'progressive',
                infinite: false,
                customPaging: function (slick, index) {
                    if (!Fily.Util.Device.isTablet() && (Fily.Util.Device.isIOs() || Fily.Util.Device.isAndroid())) {
                        var targetImage = slick.$slides.eq(index).find('img').attr('src');
                        targetImage = targetImage.replace('large', 'small');
                        return '<img src="' + targetImage + '" alt="" loading="lazy">';
                    }
                    else {
                        if (isInsertElement) {
                            var targetImage = slick.$slides.eq(index).find('img').attr('src');
                            targetImage = targetImage.replace('large', 'small');
                            return '<img src=" ' + targetImage + '" width="48" height="48" loading="lazy">';
                        }
                        else {
                            var targetImage = slick.$slides.eq(index).find('img').attr('src');
                            targetImage = targetImage.replace('large', 'small');
                            return '<img src="' + targetImage + '" alt="" width="48" height="48" loading="lazy">';
                        }
                    }
                }
            }).on('beforeChange', function (event, slick, curr, next) {
                $('.js-productCredit-' + slideId + '-' + curr).attr('hidden', 'true');
                $('.js-productCredit-' + slideId + '-' + next).removeAttr('hidden');
            });
            this.displayPhotos();
        };
        PhotoSlideView.prototype.displayPhotos = function () {
            this.$photoContainer.removeAttr('hidden');
        };
        return PhotoSlideView;
    }(Fily.BaseView));
    Fily.PhotoSlideView = PhotoSlideView;
})(Fily || (Fily = {}));
