var Fily;
(function (Fily) {
    var Api;
    (function (Api) {
        var Keywords;
        (function (Keywords) {
            function getIdeas(id, offset, limit) {
                if (offset === void 0) { offset = 0; }
                if (limit === void 0) { limit = 10; }
                return Api.send("keywords." + id, {
                    offset: offset,
                    limit: limit
                }, 'GET');
            }
            Keywords.getIdeas = getIdeas;
            function getGalleries(id, offset, limit) {
                if (offset === void 0) { offset = 0; }
                if (limit === void 0) { limit = 10; }
                return Api.send("keywords." + id + ".galleries", {
                    offset: offset,
                    limit: limit
                }, 'GET');
            }
            Keywords.getGalleries = getGalleries;
            function getPhotos(id, offset, limit) {
                if (offset === void 0) { offset = 0; }
                if (limit === void 0) { limit = 10; }
                return Api.send("keywords." + id + ".photos", {
                    offset: offset,
                    limit: limit
                }, 'GET');
            }
            Keywords.getPhotos = getPhotos;
            function getRecommend() {
                return Api.send("keywords.recommend", {}, 'GET');
            }
            Keywords.getRecommend = getRecommend;
            function find() {
                return Api.send("keywords.list", {}, 'GET');
            }
            Keywords.find = find;
            function suggest(q, limit) {
                if (limit === void 0) { limit = 7; }
                return Api.send("search.suggest", { q: q, limit: limit }, 'GET');
            }
            Keywords.suggest = suggest;
            function rankingWord(q, limit) {
                if (limit === void 0) { limit = 7; }
                return Api.send("search.ranking", { q: q, limit: limit }, 'GET');
            }
            Keywords.rankingWord = rankingWord;
        })(Keywords = Api.Keywords || (Api.Keywords = {}));
    })(Api = Fily.Api || (Fily.Api = {}));
})(Fily || (Fily = {}));
