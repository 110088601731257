var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var Fily;
(function (Fily) {
    var IdeaEditBodyElementModalView = (function (_super) {
        __extends(IdeaEditBodyElementModalView, _super);
        function IdeaEditBodyElementModalView(ideaId, options) {
            var _this = _super.call(this, options) || this;
            _this.ideaId = ideaId;
            return _this;
        }
        IdeaEditBodyElementModalView.prototype.render = function () {
            var _this = this;
            _super.prototype.render.call(this);
            this.$input = $('textarea[name=text]', this.$body);
            if (this.params.id) {
                this.$input.val(this.params.body);
            }
            this.bodyCounter = new Fily.InputCounter(this.$input, $('.js-counter'));
            setTimeout(function () {
                _this.$input.focus();
            }, 0);
        };
        IdeaEditBodyElementModalView.prototype.done = function () {
            var _this = this;
            if (!this.bodyCounter.checkRequire()) {
                Fily.Field.Err.show(this.$input, Fily.ERR.CODE.INPUT_EMPTY, ['本文']);
                return;
            }
            if (!this.bodyCounter.checkLength()) {
                Fily.Field.Err.show(this.$input, Fily.ERR.CODE.INPUT_MAX_LENGTH_INVALID, ['本文', this.bodyCounter.maxLength.toString()]);
                return;
            }
            Fily.showFullLoading();
            var sendApi;
            if (this.params.id) {
                sendApi = Fily.Api.Idea.Element.edit(this.ideaId, this.params.id, {
                    body: this.$input.val()
                });
            }
            else {
                sendApi = Fily.Api.Idea.Element.create(this.ideaId, Fily.Api.Idea.Element.Type.body, {
                    body: this.$input.val()
                }, this.params.position);
            }
            sendApi.done(function (res) { return _super.prototype.done.call(_this, res); })
                .fail(function () { return Fily.Ngword.checkInput([_this.$input]); })
                .always(function () { return Fily.hideFullLoading(); });
        };
        return IdeaEditBodyElementModalView;
    }(Fily.ModalView));
    Fily.IdeaEditBodyElementModalView = IdeaEditBodyElementModalView;
})(Fily || (Fily = {}));
