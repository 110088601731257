var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var Fily;
(function (Fily) {
    var IdeaEditPhotoElementModalView = (function (_super) {
        __extends(IdeaEditPhotoElementModalView, _super);
        function IdeaEditPhotoElementModalView(ideaId, options) {
            var _this = _super.call(this, options) || this;
            _this.ideaId = ideaId;
            _this.photosTpl = new Fily.MustacheTpl('idea/edit/select_photo');
            _this.itemClass = '.js-uploadphoto-item';
            _this.files = {};
            _this.uploaders = {};
            _this.fileSizeList = {};
            _this.photoTpl = new Fily.MustacheTpl('idea/edit/multiple_photo');
            _this.recipeblogStreamTpl = new Fily.MustacheTpl('idea/edit/select_recipeblog');
            _this.affiliateCategoriesTpl = new Fily.MustacheTpl('idea/edit/select_affiliate_categories');
            _this.amazonItemStreamTpl = new Fily.MustacheTpl('idea/edit/select_amazon_image');
            return _this;
        }
        Object.defineProperty(IdeaEditPhotoElementModalView, "ALL_PHOTOS_LOAD_LIMIT", {
            get: function () { return 40; },
            enumerable: true,
            configurable: true
        });
        ;
        Object.defineProperty(IdeaEditPhotoElementModalView, "ALL_PHOTOS_LOAD_OFFSET", {
            get: function () { return 40; },
            enumerable: true,
            configurable: true
        });
        ;
        Object.defineProperty(IdeaEditPhotoElementModalView, "ALL_PHOTOS_LOAD_INTERVAL", {
            get: function () { return 220; },
            enumerable: true,
            configurable: true
        });
        ;
        Object.defineProperty(IdeaEditPhotoElementModalView, "RECIPEBLOG_LOAD_LIMIT", {
            get: function () { return 40; },
            enumerable: true,
            configurable: true
        });
        ;
        Object.defineProperty(IdeaEditPhotoElementModalView, "RECIPEBLOG_LOAD_OFFSET", {
            get: function () { return 40; },
            enumerable: true,
            configurable: true
        });
        ;
        Object.defineProperty(IdeaEditPhotoElementModalView, "RECIPEBLOG_LOAD_INTERVAL", {
            get: function () { return 300; },
            enumerable: true,
            configurable: true
        });
        ;
        IdeaEditPhotoElementModalView.prototype.events = function () {
            var _this = this;
            return $.extend(_super.prototype.events.call(this), {
                'click / .js-select-idea': 'loadPhotoListFromIdea',
                'change / .js-upload-photo': 'uploadPhoto',
                'click / #js-rotatePhoto': function (e) {
                    _this.uploader.rotatePreview();
                },
                'change / .js-select--photofile': 'selectPhotoFiles',
                'click / .js-delete--photo': 'removePhoto',
                'click / .js-upload--photos': 'upload',
                'click / .js-rotatePhoto': function (e) {
                    var key = $(e.currentTarget).data('key');
                    _this.uploaders[key].rotatePreview();
                },
                'submit / .js-searchAllPhoto': 'searchAllPhoto',
                'submit / .js-searchRecipeBlog': 'searchRecipeBlog',
                'submit / .js-searchAmazonImage': 'searchAmazonImage',
            });
        };
        IdeaEditPhotoElementModalView.prototype.render = function () {
            _super.prototype.render.call(this);
            if (this.params.id) {
                this.$inputCaption = $('[name=caption]', this.$body);
                this.captionCounter = new Fily.InputCounter(this.$inputCaption, $('.js-counter', this.$body));
            }
            else {
                var $preview = $('.js-upload-photoarea');
                this.$inputSearchAllPhotos = this.$body.find('[name="inputSearchAllPhoto"]');
                this.$allPhotosStreamWrapper = this.$body.find('#js-allPhotosStreamWrapper');
                this.$allPhotosStream = this.$allPhotosStreamWrapper.find('#js-allPhotosStream');
                this.$loaderAllPhoto = this.$allPhotosStreamWrapper.find('.js-loader-allPhoto');
                this.$inputPhotoId = this.$body.find('input[name=photoid]');
                this.loadMyIdeaList();
                this.$photoTabMyIdeaPhoto = this.$body.find('#photo-tab-myIdeaPhoto');
                this.$myIdeaPhotosWrapper = this.$body.find('#js-myIdeaPhotosWrapper');
                this.$myIdeaPhotos = this.$myIdeaPhotosWrapper.find('#js-myIdeaPhotos');
                this.$inputCreditUrl = $('[name=crediturl]', this.$body);
                this.$inputCredit = $('[name=credit]', this.$body);
                this.crediturlCounter = new Fily.InputCounter(this.$inputCreditUrl, $('.js-counter-crediturl', this.$body));
                this.creditCounter = new Fily.InputCounter(this.$inputCredit, $('.js-counter--credit', this.$body));
                this.uploader = new Fily.PhotoUploader($preview);
                this.$cite = $('[name=cite-multiple]');
                this.$credit = $('[name=credit-multiple]');
                this.citeCounter = new Fily.InputCounter(this.$cite, $('.js-counter--cite-multiple'));
                this.creditCounterMultiple = new Fily.InputCounter(this.$credit, $('.js-counter--credit-multiple'));
                this.sumBytes = 0;
                this.fileSizeList = {};
                $('#js-uploadPhotos').html('');
                this.$recipeblogStreamWrapper = this.$body.find('#js-recipeblogStreamWrapper');
                this.$recipeblogStream = this.$body.find('#js-recipeblogStream');
                this.$loaderRecipeblog = this.$recipeblogStreamWrapper.find('.js-loader-recipe');
                this.$affiliateModalTabPanel = this.$body.find('.js-photomodalTabPanel');
                this.loadAffiliateSelectBoxes();
            }
        };
        IdeaEditPhotoElementModalView.prototype.done = function (event) {
            var _this = this;
            var $target = $(event.currentTarget);
            if ($target.prop('disabled'))
                return;
            if ($target.hasClass('js-select-photo')) {
                $target.prop('disabled', true);
                Fily.showFullLoading();
                Fily.Api.Idea.Element.create(this.ideaId, Fily.Api.Idea.Element.Type.photo, {
                    photo_id: $target.parents('.js-photo-item').data('photo-id'),
                    layout: 1
                }, this.params.position).done(function (res) { return _super.prototype.done.call(_this, res); })
                    .always(function () {
                    $target.prop('disabled', false);
                    Fily.hideFullLoading();
                });
            }
            else if ($target.hasClass('js-select-photoupload')) {
                var invalid = false;
                var $selectPhoto = $('.js-upload-photo');
                Fily.Field.Err.hide($selectPhoto);
                Fily.Field.Err.hide(this.$inputCreditUrl);
                Fily.Field.Err.hide(this.$inputCredit);
                if (!this.uploader.uploadImageDataString) {
                    Fily.Field.Err.show($selectPhoto, Fily.ERR.CODE.SELECT_INVALID, ['画像ファイル']);
                    invalid = true;
                }
                else if (!Fily.Util.checkImageFileSize($selectPhoto.prop('files')[0])) {
                    Fily.Field.Err.show($selectPhoto, Fily.ERR.CODE.IMAGE_SIZE_INVALID);
                    invalid = true;
                }
                if (this.$inputCreditUrl.val() && !Fily.Util.isUrl(this.$inputCreditUrl.val())) {
                    Fily.Field.Err.show(this.$inputCreditUrl, Fily.ERR.CODE.INPUT_INVALID, ['出典（URL）']);
                    invalid = true;
                }
                else if (Fily.Denied.isDeniedPhotoUrl(this.$inputCreditUrl.val())) {
                    Fily.Field.Err.show(this.$inputCreditUrl, Fily.ERR.CODE.DENIED_PHOTO_URL);
                    invalid = true;
                }
                if (!this.crediturlCounter.checkLength()) {
                    Fily.Field.Err.show(this.$inputCreditUrl, Fily.ERR.CODE.INPUT_MAX_LENGTH_INVALID, ['出典（URL）', this.crediturlCounter.maxLength.toString()]);
                    invalid = true;
                }
                if (!this.creditCounter.checkLength()) {
                    Fily.Field.Err.show(this.$inputCredit, Fily.ERR.CODE.INPUT_MAX_LENGTH_INVALID, ['クレジット', this.creditCounter.maxLength.toString()]);
                    invalid = true;
                }
                if (invalid) {
                    $target.addClass('is-error');
                    setTimeout(function () {
                        $target.removeClass('is-error');
                    }, 1000);
                    return;
                }
                $target.prop('disabled', true);
                Fily.showFullLoading();
                this.uploader.rotateUploadPhoto().then(function () {
                    Fily.Api.Idea.Element.create(_this.ideaId, Fily.Api.Idea.Element.Type.photo, {
                        data: _this.uploader.uploadImageDataString,
                        credit_url: _this.$inputCreditUrl.val(),
                        credit_title: _this.$inputCredit.val()
                    }, _this.params.position).done(function (res) { return _super.prototype.done.call(_this, res); })
                        .fail(function () { return Fily.Ngword.checkInput([_this.$inputCreditUrl, _this.$inputCredit]); })
                        .always(function () {
                        $target.prop('disabled', false);
                        Fily.hideFullLoading();
                    });
                });
            }
            else if ($target.hasClass('js-edit-photo')) {
                if (!this.captionCounter.checkRequire()) {
                    Fily.Field.Err.show(this.$inputCaption, Fily.ERR.CODE.INPUT_EMPTY, ['キャプション']);
                    return;
                }
                if (!this.captionCounter.checkLength()) {
                    Fily.Field.Err.show(this.$inputCaption, Fily.ERR.CODE.INPUT_MAX_LENGTH_INVALID, ['キャプション', this.captionCounter.maxLength.toString()]);
                    return;
                }
                $target.prop('disabled', true);
                Fily.showFullLoading();
                Fily.Api.Idea.Element.edit(this.ideaId, this.params.id, {
                    caption: this.$inputCaption.val()
                }).done(function (res) {
                    res.content.photo.is_affiliate = (res.content.photo.is_amazon_item || res.content.photo.is_rakuten_ichiba_item);
                    res.content.photo.is_avatar = _this.params.is_avatar;
                    _super.prototype.done.call(_this, res);
                }).fail(function () { return Fily.Ngword.checkInput([_this.$inputCaption]); })
                    .always(function () {
                    $target.prop('disabled', false);
                    Fily.hideFullLoading();
                });
            }
            else if ($target.hasClass('js-select-recipeblog')) {
                $target.prop('disabled', true);
                Fily.showFullLoading();
                var $recipeblogItem = $target.parents('.js-stream-item');
                Fily.Api.Idea.Element.create(this.ideaId, Fily.Api.Idea.Element.Type.photo, {
                    photo_url: $recipeblogItem.data('recipeblog-image'),
                    credit_url: $recipeblogItem.data('recipeblog-href'),
                    credit_title: $recipeblogItem.data('recipeblog-nickname'),
                    description: $recipeblogItem.data('recipeblog-name')
                }, this.params.position).done(function (res) { return _super.prototype.done.call(_this, res); })
                    .always(function () {
                    $target.prop('disabled', false);
                    Fily.hideFullLoading();
                });
            }
            else if ($target.hasClass('js-select-photoid')) {
                var invalid = false;
                var photoId = this.$inputPhotoId.val();
                if (photoId === 0 || photoId === '') {
                    Fily.Field.Err.show(this.$inputPhotoId, Fily.ERR.CODE.INPUT_EMPTY, ['フォトID']);
                    invalid = true;
                }
                if (invalid) {
                    $target.addClass('is-error');
                    setTimeout(function () {
                        $target.removeClass('is-error');
                    }, 1000);
                    return;
                }
                ;
                Fily.showFullLoading();
                Fily.Api.Idea.Element.create(this.ideaId, Fily.Api.Idea.Element.Type.photo, {
                    photo_id: photoId,
                    layout: 1
                }, this.params.position).done(function (res) { return _super.prototype.done.call(_this, res); })
                    .fail(function (err) {
                    if (err.responseJSON.error_code === 60400) {
                        Fily.Field.Err.show(_this.$inputPhotoId, Fily.ERR.CODE.ID_INVALID);
                        return;
                    }
                })
                    .always(function () {
                    Fily.hideFullLoading();
                });
            }
            else if ($target.hasClass('js-select-amazonImage')) {
                $target.prop('disabled', true);
                Fily.showFullLoading();
                var $amazonImageItem = $target.parents('.js-streamItem');
                var parseUrl = new URL($amazonImageItem.data('url'));
                Fily.Api.Idea.Element.create(this.ideaId, Fily.Api.Idea.Element.Type.photo, {
                    photo_url: $amazonImageItem.data('image') + '?apa=1',
                    credit_url: parseUrl.origin + parseUrl.pathname,
                    credit_title: $amazonImageItem.data('caption'),
                    description: $amazonImageItem.data('title')
                }, this.params.position).done(function (res) { return _super.prototype.done.call(_this, res); })
                    .always(function () {
                    $target.prop('disabled', false);
                    Fily.hideFullLoading();
                });
            }
        };
        IdeaEditPhotoElementModalView.prototype.searchAllPhoto = function () {
            var _this = this;
            this.$allPhotosStream.empty();
            var inputSearchValue = this.$body.find('[name="inputSearchAllPhoto"]').val();
            if (inputSearchValue === '') {
                return false;
            }
            var error = null;
            var params = null;
            delete (this.allPhotosAutoLoader);
            this.$allPhotosStreamWrapper.off('scroll.autoload');
            this.allPhotosAutoLoader = Fily.AutoLoader.init({
                eventType: 'scroll',
                limit: IdeaEditPhotoElementModalView.ALL_PHOTOS_LOAD_LIMIT,
                offset: IdeaEditPhotoElementModalView.ALL_PHOTOS_LOAD_OFFSET,
                intervalLoad: IdeaEditPhotoElementModalView.ALL_PHOTOS_LOAD_INTERVAL,
                $targetWrapper: this.$allPhotosStreamWrapper,
                $target: this.$allPhotosStream,
                $loader: this.$loaderAllPhoto,
                sender: function (offset, limit) {
                    return Fily.Api.Search.getSearch(inputSearchValue, 1, limit, offset);
                },
                dataFormat: function (data, limit) {
                    var photos = data.contents.map(function (item) {
                        return item.content;
                    });
                    if (photos.length < limit) {
                        error = { 'isLimitItem': true };
                    }
                    return (error !== null) ? { photos: photos, length: photos.length, error: error } : { photos: photos, length: photos.length };
                },
                tpl: this.photosTpl,
            });
            this.$loaderAllPhoto.show();
            Fily.showLoading(this.$loaderAllPhoto);
            Fily.Api.Search.getSearch(inputSearchValue, 1).done(function (res) {
                var photos = res.contents.map(function (item) {
                    return item.content;
                });
                if (photos.length < IdeaEditPhotoElementModalView.ALL_PHOTOS_LOAD_LIMIT) {
                    if (photos.length === 0) {
                        error = { 'keyword': inputSearchValue, 'isNoExactMatches': true };
                    }
                    else {
                        error = { 'isLimitItem': true };
                    }
                }
                if (error !== null) {
                    delete (_this.allPhotosAutoLoader);
                    _this.$allPhotosStreamWrapper.off('scroll.autoload');
                    _this.$loaderAllPhoto.hide();
                    params = { photos: photos, error: error };
                }
                else {
                    params = { photos: photos };
                }
                _this.photosTpl.load().done(function () {
                    _this.$allPhotosStream.html(_this.photosTpl.render(params));
                }).always(function () { return Fily.hideLoading(_this.$loaderAllPhoto); });
            }).fail(function () { return Fily.hideLoading(_this.$loaderAllPhoto); });
            return false;
        };
        IdeaEditPhotoElementModalView.prototype.loadMyIdeaList = function () {
            var $myIdeasStreamWrapper = this.$body.find('#js-myIdeasStreamWrapper');
            var $myIdeasStream = $myIdeasStreamWrapper.find('#js-myIdeasStream');
            var $loaderMyIdea = $myIdeasStreamWrapper.find('.js-loader-myIdea');
            var myIdeasTpl = new Fily.MustacheTpl('idea/edit/select_idea');
            var MY_IDEAS_LOAD_LIMIT = 40;
            var MY_IDEAS_LOAD_OFFSET = 40;
            var MY_IDEAS_LOAD_INTERVAL = 200;
            var filteredIdeas = [];
            var error = null;
            var params = null;
            var myIdeasAutoLoader = Fily.AutoLoader.init({
                eventType: 'scroll',
                limit: MY_IDEAS_LOAD_LIMIT,
                offset: MY_IDEAS_LOAD_OFFSET,
                intervalLoad: MY_IDEAS_LOAD_INTERVAL,
                $targetWrapper: $myIdeasStreamWrapper,
                $target: $myIdeasStream,
                $loader: $loaderMyIdea,
                sender: function (offset, limit) {
                    return Fily.Api.Idea.getUserIdeaList(limit, offset);
                },
                dataFormat: function (data, limit) {
                    if (data) {
                        if (data.length < limit) {
                            error = { 'isLimitItem': true };
                        }
                        filteredIdeas = data.filter(function (item) {
                            return item.main_photo.content.photo.photo_urls.ss1 !== '';
                        });
                    }
                    else {
                        error = { 'isLimitItem': true };
                    }
                    return (error !== null) ? { ideas: filteredIdeas, length: filteredIdeas.length, error: error } : { ideas: filteredIdeas, length: filteredIdeas.length };
                },
                tpl: myIdeasTpl,
            });
            $loaderMyIdea.show();
            Fily.showLoading($loaderMyIdea);
            Fily.Api.Idea.getUserIdeaList(MY_IDEAS_LOAD_LIMIT).done(function (res) {
                myIdeasTpl.load().done(function () {
                    if (res) {
                        filteredIdeas = res.filter(function (item) {
                            return item.main_photo.content.photo.photo_urls.ss1 !== '';
                        });
                        if (res.length < MY_IDEAS_LOAD_LIMIT) {
                            if (filteredIdeas.length === 0) {
                                error = { 'isNoItem': true };
                            }
                            else {
                                error = { 'isLimitItem': true };
                            }
                        }
                    }
                    else {
                        error = { 'isNoItem': true };
                    }
                    if (error !== null) {
                        myIdeasAutoLoader = null;
                        $myIdeasStreamWrapper.off('scroll.autoload');
                        $loaderMyIdea.hide();
                        params = { ideas: filteredIdeas, error: error };
                    }
                    else {
                        params = { ideas: filteredIdeas };
                    }
                    $myIdeasStream.html(myIdeasTpl.render(params));
                }).always(function () { return Fily.hideLoading($loaderMyIdea); });
            }).fail(function () { return Fily.hideLoading($loaderMyIdea); });
            return false;
        };
        IdeaEditPhotoElementModalView.prototype.loadPhotoListFromIdea = function (event) {
            var _this = this;
            this.$myIdeaPhotos.empty();
            var targetIdeaId = $(event.target).parents('.js-article-idea').data('idea-id');
            Fily.Api.Idea.getPhotoList(targetIdeaId).done(function (res) {
                _this.photosTpl.load().done(function () {
                    _this.$myIdeaPhotos.html(_this.photosTpl.render({ photos: res }));
                    _this.$photoTabMyIdeaPhoto.click();
                });
            });
            return false;
        };
        IdeaEditPhotoElementModalView.prototype.uploadPhoto = function (event) {
            this.uploader.renderPhoto($(event.target).prop('files'));
            $('#js-rotatePhoto').removeClass('is-inactive');
        };
        IdeaEditPhotoElementModalView.prototype.selectPhotoFiles = function (event) {
            var _this = this;
            var files = $(event.target).prop('files');
            var req = this.photoTpl.load();
            Fily.showFullLoading();
            req.always(function () { return Fily.hideFullLoading(); })
                .done(function (cats, areas) {
                var currentPhoto = [];
                var photos = Array.prototype.map.call(files, function (file) {
                    var tmpPhotoId = file.name.replace(/[^a-z0-9-_]/ig, '') + Date.now().toString();
                    currentPhoto.push(tmpPhotoId);
                    _this.setFiles(file, tmpPhotoId);
                    _this.fileSizeList[tmpPhotoId] = file.size;
                    _this.sumBytes += file.size;
                    return _this.photoTpl.render({
                        tmp_photo_id: tmpPhotoId,
                        photo_url: Fily.Util.createObjectURL(file),
                        name: file.name,
                        size: Fily.Util.byteSizeFormat(file.size)
                    });
                });
                $('#js-uploadPhotos').show().append(photos);
                Object.keys(_this.files).map(function (key) {
                    if (currentPhoto.indexOf(key) >= 0) {
                        _this.setUploader($("#js-preview" + key), key)
                            .renderPhoto(_this.files[key]);
                    }
                });
                $('#js-sumBytes').html(Fily.Util.byteSizeFormat(_this.sumBytes));
            });
        };
        IdeaEditPhotoElementModalView.prototype.setFiles = function (file, tmpPhotoId) {
            this.files[tmpPhotoId] = file;
        };
        IdeaEditPhotoElementModalView.prototype.setUploader = function ($preview, tmpPhotoId) {
            this.uploaders[tmpPhotoId] = new Fily.PhotoUploader($preview);
            return this.uploaders[tmpPhotoId];
        };
        IdeaEditPhotoElementModalView.prototype.removePhoto = function (event) {
            var $item = $(event.target).parents(this.itemClass);
            var tmpPhotoId = $item.data('tmp_photo-id');
            $item.remove();
            this.sumBytes -= this.fileSizeList[tmpPhotoId];
            this.fileSizeList[tmpPhotoId] = 0;
            delete this.files[tmpPhotoId];
            delete this.uploaders[tmpPhotoId];
            $('#js-sumBytes').html(Fily.Util.byteSizeFormat(this.sumBytes));
        };
        IdeaEditPhotoElementModalView.prototype.upload = function (event) {
            var _this = this;
            var $button = $(event.currentTarget);
            if ($button.hasClass('disabled'))
                return;
            var invalid = false;
            var $uploadPhoto = $('.js-select--photofile', this.$body);
            if (!Object.keys(this.files).length) {
                Fily.Field.Err.show($uploadPhoto, Fily.ERR.CODE.SELECT_INVALID, ['画像ファイル']);
                invalid = true;
            }
            else if (!Fily.Util.checkImageSize(this.sumBytes)) {
                Fily.Field.Err.show($uploadPhoto, Fily.ERR.CODE.IMAGE_SIZE_INVALID);
                invalid = true;
            }
            else {
                Fily.Field.Err.hide($uploadPhoto);
            }
            if (!this.citeCounter.checkLength()) {
                Fily.Field.Err.show(this.$cite, Fily.ERR.CODE.INPUT_MAX_LENGTH_INVALID, ['出典（URL）', this.citeCounter.maxLength.toString()]);
                invalid = true;
            }
            else if (this.$cite.val() && !Fily.Util.isUrl(this.$cite.val())) {
                Fily.Field.Err.show(this.$cite, Fily.ERR.CODE.INPUT_INVALID, ['出典（URL）']);
                invalid = true;
            }
            else if (Fily.Denied.isDeniedPhotoUrl(this.$cite.val())) {
                Fily.Field.Err.show(this.$cite, Fily.ERR.CODE.DENIED_PHOTO_URL);
                invalid = true;
            }
            else {
                Fily.Field.Err.hide(this.$cite);
            }
            if (!this.creditCounterMultiple.checkLength()) {
                Fily.Field.Err.show(this.$credit, Fily.ERR.CODE.INPUT_MAX_LENGTH_INVALID, ['写真クレジット', this.creditCounterMultiple.maxLength.toString()]);
                invalid = true;
            }
            else {
                Fily.Field.Err.hide(this.$credit);
            }
            if (invalid) {
                $button.addClass('is-error');
                setTimeout(function () {
                    $button.removeClass('is-error');
                }, 1000);
                return;
            }
            var transforms = [];
            var uploaders = this.uploaders;
            var uploaderKeys = Object.keys(uploaders);
            (function transformPhoto() {
                var d = $.Deferred();
                uploaderKeys.forEach(function (key) {
                    console.assert(uploaders[key] !== null, 'unfortunately Uploader is null');
                    uploaders[key].rotateUploadPhoto().then(function () {
                        transforms.push(key);
                        if (transforms.length === uploaderKeys.length) {
                            d.resolve();
                        }
                    });
                });
                return d.promise();
            })().then(function () {
                var photos = Array.prototype.map.call($(_this.itemClass), function (dom, i) {
                    return {
                        position: i + _this.params.position,
                        data: _this.uploaders[$(dom).data('tmp_photo-id')].uploadImageDataString,
                        credit_title: _this.$credit.val(),
                        credit_url: _this.$cite.val(),
                    };
                });
                $button.addClass('disabled');
                Fily.showFullLoading();
                Fily.Api.Idea.Element.multipleUpload(_this.ideaId, photos)
                    .done(function (res) { return _super.prototype.done.call(_this, res); })
                    .always(function () {
                    Fily.hideFullLoading();
                    $button.removeClass('disabled');
                });
            });
        };
        IdeaEditPhotoElementModalView.prototype.searchRecipeBlog = function () {
            var _this = this;
            this.$recipeblogStream.empty();
            var query = $('[name="q"]', this.$body).val();
            if (query == '') {
                return false;
            }
            var error = null;
            delete (this.recipeblogAutoLoader);
            this.$recipeblogStreamWrapper.off('scroll.autoload');
            this.recipeblogAutoLoader = Fily.AutoLoader.init({
                eventType: 'scroll',
                limit: IdeaEditPhotoElementModalView.RECIPEBLOG_LOAD_LIMIT,
                offset: IdeaEditPhotoElementModalView.RECIPEBLOG_LOAD_OFFSET,
                intervalLoad: IdeaEditPhotoElementModalView.RECIPEBLOG_LOAD_INTERVAL,
                $targetWrapper: this.$recipeblogStreamWrapper,
                $target: this.$recipeblogStream,
                $loader: this.$loaderRecipeblog,
                sender: function (offset, limit) {
                    return Fily.Api.Recipeblog.getRecipeblog(query, limit, offset);
                },
                dataFormat: function (data, limit) {
                    if (data.list.length < limit) {
                        error = {
                            'isLimitItem': true,
                        };
                    }
                    return (error !== null) ? { recipes: data, length: data.list.length, error: error } : { recipes: data, length: data.list.length };
                },
                tpl: this.recipeblogStreamTpl,
            });
            this.$loaderRecipeblog.show();
            Fily.showLoading(this.$loaderRecipeblog);
            Fily.Api.Recipeblog.getRecipeblog(query).done(function (res) {
                if (res.list.length < IdeaEditPhotoElementModalView.RECIPEBLOG_LOAD_LIMIT) {
                    if (res.list.length === 0) {
                        error = {
                            'keyword': query,
                            'isNoExactMatches': true,
                        };
                    }
                    else {
                        error = {
                            'isLimitItem': true,
                        };
                    }
                }
                var params = null;
                if (error !== null) {
                    delete (_this.recipeblogAutoLoader);
                    _this.$recipeblogStreamWrapper.off('scroll.autoload');
                    _this.$loaderRecipeblog.hide();
                    params = { recipes: res, error: error };
                }
                else {
                    params = { recipes: res };
                }
                _this.recipeblogStreamTpl.load().done(function () {
                    _this.$recipeblogStream.html(_this.recipeblogStreamTpl.render(params));
                }).always(function () { return Fily.hideLoading(_this.$loaderRecipeblog); });
            }).fail(function () { return Fily.hideLoading(_this.$loaderRecipeblog); });
            return false;
        };
        IdeaEditPhotoElementModalView.prototype.loadAffiliateSelectBoxes = function () {
            var _this = this;
            this.$affiliateModalTabPanel.each(function (key, value) {
                Fily.Api.Amazon.getSearchIndex().done(function (res) {
                    _this.renderAffiliateSelectBoxes($(value).find('.js-affiliateCategories'), { name: 'ジャンルを選択', value: 0 }, res.map(function (item) {
                        return {
                            name: item.display_name,
                            value: item.index_name
                        };
                    }), _this.affiliateCategoriesTpl);
                });
            });
        };
        IdeaEditPhotoElementModalView.prototype.renderAffiliateSelectBoxes = function ($target, defaultOption, options, targetTpl) {
            targetTpl.load().done(function () {
                $target.html(targetTpl.render({
                    defaultOption: defaultOption,
                    options: options
                }));
            });
        };
        IdeaEditPhotoElementModalView.prototype.searchAmazonImage = function (event) {
            var _this = this;
            event.preventDefault();
            var $target = $(event.currentTarget);
            var $streamWrapperAmazon = $target.find('.js-streamWrapperAmazon');
            var $streamAmazon = $streamWrapperAmazon.find('.js-stream');
            var $loaderAmzon = $streamWrapperAmazon.find('.js-loader');
            var $beforeSearchIconAmzon = $streamWrapperAmazon.find('.js-affiliateBeforeSearchIcon');
            $streamAmazon.empty();
            var keyword = $target.find('[name="inputAffiliateSearch"]').val();
            if (keyword === '') {
                return false;
            }
            var category = $target.find('[name="selectAffiliateCategories"]').val();
            var site = parseInt($target.parent().data('site'), 10);
            var error = null;
            var autoloaderParam = {
                eventType: 'scroll',
                isPage: true,
                $targetWrapper: $streamWrapperAmazon,
                $target: $streamAmazon,
                $loader: $loaderAmzon,
                limit: null,
                offset: null,
                intervalLoad: null,
                sender: null,
                dataFormat: null,
                tpl: null,
            };
            $beforeSearchIconAmzon.hide();
            autoloaderParam.limit = IdeaEditPhotoElementModalView.AMAZON_ITEM_LOAD_LIMIT;
            autoloaderParam.offset = IdeaEditPhotoElementModalView.AMAZON_ITEM_LOAD_PAGE;
            autoloaderParam.intervalLoad = IdeaEditPhotoElementModalView.AMAZON_ITEM_LOAD_INTERVAL;
            autoloaderParam.sender = function (page, limit) {
                return Fily.Api.Amazon.getItems(keyword, category, page);
            };
            autoloaderParam.dataFormat = function (data) {
                return { items: data, length: data.list.length, error: data.error };
            };
            autoloaderParam.tpl = this.amazonItemStreamTpl;
            delete (this.affiliateAutoLoader);
            $streamWrapperAmazon.off('scroll.autoload');
            this.affiliateAutoLoader = Fily.AutoLoader.init(autoloaderParam);
            $loaderAmzon.show();
            Fily.showLoading($loaderAmzon);
            $.when(autoloaderParam.sender(1, 0), autoloaderParam.tpl.load()).done(function (res) {
                if (res.error) {
                    if (res.list.length === 0) {
                        res.error.isParameterOutOfRange = false;
                    }
                    delete (_this.affiliateAutoLoader);
                    $streamWrapperAmazon.off('scroll.autoload');
                }
                $streamAmazon.html(autoloaderParam.tpl.render({ items: res, error: res.error }));
            }).always(function () { return Fily.hideLoading($loaderAmzon); });
            return false;
        };
        IdeaEditPhotoElementModalView.AMAZON_ITEM_LOAD_LIMIT = 10;
        IdeaEditPhotoElementModalView.AMAZON_ITEM_LOAD_PAGE = 2;
        IdeaEditPhotoElementModalView.AMAZON_ITEM_LOAD_INTERVAL = 150;
        return IdeaEditPhotoElementModalView;
    }(Fily.ModalView));
    Fily.IdeaEditPhotoElementModalView = IdeaEditPhotoElementModalView;
})(Fily || (Fily = {}));
