var Fily;
(function (Fily) {
    var Cookie;
    (function (Cookie) {
        var doc = null;
        Cookie.init = function () {
            if (doc === null) {
                if (typeof document === 'undefined') {
                    doc.cookie = '';
                }
                doc = document;
            }
        };
        Cookie.getItem = function (key) {
            Cookie.init();
            var splat = doc.cookie.split(/;\s*/);
            for (var i = 0; i < splat.length; i++) {
                var ps = splat[i].split('=');
                if (decodeURIComponent(ps[0]) === key) {
                    return decodeURIComponent(ps[1]);
                }
            }
            return;
        };
        Cookie.setItem = function (key, value, options) {
            if (!options) {
                options = {};
            }
            Cookie.init();
            var str = encodeURIComponent(key) + '=' + encodeURIComponent(value);
            if (options.expires) {
                str += '; expires=' + options.expires;
            }
            if (options.path) {
                str += '; path=' + encodeURIComponent(options.path);
            }
            if (options.domain) {
                str += '; domain=' + encodeURIComponent(options.domain);
            }
            if (options.secure) {
                str += '; secure';
            }
            doc.cookie = str;
            return str;
        };
        Cookie.removeItem = function (key) {
            Cookie.setItem(key, null, {
                expires: 'Thu, 01 Jan 1970 00:00:00 GMT'
            });
        };
    })(Cookie = Fily.Cookie || (Fily.Cookie = {}));
})(Fily || (Fily = {}));
