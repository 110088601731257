var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var Fily;
(function (Fily) {
    var ModalSize;
    (function (ModalSize) {
        ModalSize[ModalSize["Small"] = 0] = "Small";
        ModalSize[ModalSize["Medium"] = 1] = "Medium";
        ModalSize[ModalSize["FixedMedium"] = 2] = "FixedMedium";
        ModalSize[ModalSize["Large"] = 3] = "Large";
    })(ModalSize = Fily.ModalSize || (Fily.ModalSize = {}));
    var ModalView = (function (_super) {
        __extends(ModalView, _super);
        function ModalView(options) {
            if (options === void 0) { options = {}; }
            var _this = _super.call(this, { delegate: false }) || this;
            _this.viewId = 'modalView';
            _this.$body = $('.js-modal');
            _this.$modalBody = $('.js-modal-body', _this.$body);
            _this.$win = $(window);
            _this.scrollPosition = _this.$win.scrollTop();
            _this.setSize(options.size);
            _this.bodyTpl = options.bodyTpl;
            _this.doneCallback = options.doneCallback;
            _this.params = options.params;
            _this.delegateEvent();
            return _this;
        }
        ModalView.prototype.events = function () {
            return {
                'click / .js-modalClose': 'hide',
                'click / .js-done': 'done'
            };
        };
        ModalView.prototype.setSize = function (size) {
            var sizeClass = 'Modal--';
            this.$body.removeClass(function (i, className) {
                var m = new RegExp(sizeClass + '\\S+').exec(className);
                return m ? m[0] : '';
            }).addClass(function () {
                switch (size) {
                    case ModalSize.Small:
                        sizeClass += 'small';
                        break;
                    case ModalSize.Large:
                        sizeClass += 'large';
                        break;
                    case ModalSize.Medium:
                        sizeClass += 'medium';
                        break;
                    case ModalSize.FixedMedium:
                        sizeClass += 'fixedMedium';
                        break;
                    default:
                        sizeClass += 'medium';
                        break;
                }
                return sizeClass;
            });
        };
        ModalView.prototype.loadTpl = function () {
            var _this = this;
            return this.bodyTpl.load().pipe(function () { return _this; });
        };
        ModalView.prototype.render = function () {
            this.$modalBody.html(this.bodyTpl.render(this.params));
        };
        ModalView.prototype.show = function () {
            var _this = this;
            Fily.showFullLoading();
            if (Fily.Util.Device.isIOs()) {
                $(document.body).css({
                    'top': -this.scrollPosition,
                    'position': 'fixed',
                    'width': '100%',
                });
            }
            this.loadTpl().done(function () {
                _this.render();
                _this.toggle(true);
            }).always(function () { return Fily.hideFullLoading(); });
        };
        ModalView.prototype.done = function (params) {
            if (params === void 0) { params = {}; }
            this.doneCallback(this.$body, $.proxy(this.hide, this), params);
        };
        ModalView.prototype.hide = function () {
            if (Fily.Util.Device.isIOs()) {
                $(document.body).css({
                    'top': '',
                    'position': '',
                    'width': '',
                });
                $(document).scrollTop(this.scrollPosition);
            }
            this.toggle(false);
        };
        ModalView.prototype.clear = function () {
            this.$modalBody.empty();
        };
        ModalView.prototype.isShown = function () {
            return this.$body.attr('aria-hidden') === 'false';
        };
        ModalView.prototype.escape = function () {
            var _this = this;
            var $doc = $(document);
            if (this.isShown()) {
                $doc.on('keydown.modal', function (e) {
                    if (e.keyCode === 27 && e.target.tagName.toUpperCase() === 'BODY') {
                        _this.hide();
                    }
                });
            }
            else {
                $doc.off('keydown.modal');
            }
        };
        ModalView.prototype.toggle = function (isShow) {
            if (isShow === void 0) { isShow = null; }
            this.$body.attr('aria-hidden', String(!isShow));
            $('html').toggleClass('Modal-is-locked', isShow);
            this.escape();
        };
        return ModalView;
    }(Fily.BaseView));
    Fily.ModalView = ModalView;
})(Fily || (Fily = {}));
