var Fily;
(function (Fily) {
    var ImageLoader;
    (function (ImageLoader) {
        var AspectRatioPlaceholder = (function () {
            function AspectRatioPlaceholder() {
                this.placeholderClassName = 'js-imgPlaceholder';
                this.targetList = {};
                this.findElement();
            }
            AspectRatioPlaceholder.prototype.findElement = function () {
                var _this = this;
                var $body = $(document.body);
                var $list = $body.find("." + this.placeholderClassName);
                [].forEach.call($list, function (l, i) {
                    var imgEl = l.querySelector('img');
                    var sourceEl = l.querySelector('source');
                    if (imgEl.getAttribute('data-src')) {
                        if (sourceEl) {
                            _this.targetList[i + "_" + imgEl.getAttribute('data-src')] = {
                                imgEl: imgEl,
                                src: imgEl.getAttribute('data-src'),
                                sourceEl: sourceEl,
                                srcset: sourceEl.getAttribute('data-srcset')
                            };
                        }
                        else {
                            _this.targetList[i + "_" + imgEl.getAttribute('data-src')] = {
                                imgEl: imgEl,
                                src: imgEl.getAttribute('data-src')
                            };
                        }
                    }
                });
                return this;
            };
            AspectRatioPlaceholder.init = function () {
                if (!this._instance) {
                    this._instance = new AspectRatioPlaceholder();
                }
                return this._instance;
            };
            AspectRatioPlaceholder.prototype.isInViewport = function (el) {
                var height = 256 + (window.innerHeight || document.documentElement.clientHeight);
                var rect = el.getBoundingClientRect();
                return (rect.top >= 0 &&
                    rect.top <= height);
            };
            AspectRatioPlaceholder.prototype.replace = function () {
                var _this = this;
                var keys = Object.keys(this.targetList);
                Object.keys(this.targetList).forEach(function (key, i) {
                    var _item = _this.targetList[key];
                    if (_item === null)
                        return;
                    if (_this.isInViewport(_item.imgEl)) {
                        if (_item.sourceEl) {
                            _item.sourceEl.setAttribute('srcset', _item.srcset);
                            _item.sourceEl.removeAttribute('data-srcset');
                        }
                        _item.imgEl.setAttribute('src', _item.src);
                        _item.imgEl.removeAttribute('data-src');
                        _item.imgEl.onload = function () {
                            setTimeout(function () {
                                if (!Fily.Util.Device.isTablet() && (Fily.Util.Device.isIOs() || Fily.Util.Device.isAndroid())) {
                                    $(_item.imgEl).parents('.js-imgPlaceholder').addClass('is-loaded');
                                }
                                else {
                                    $(_item.imgEl).addClass('is-loaded').siblings('div').hide();
                                }
                                _this.targetList[key] = null;
                            }, 4);
                        };
                    }
                });
            };
            AspectRatioPlaceholder.prototype.lazyLoad = function () {
                var _this = this;
                var $win = $(window);
                $win.on('scroll.AspectRatioPlaceholder resize.AspectRatioPlaceholder', _.debounce(function (e) {
                    setTimeout(function () {
                        _this.replace();
                    }, 30);
                }, 1000 / 30));
                $(function () {
                    _this.replace();
                });
                return this;
            };
            return AspectRatioPlaceholder;
        }());
        ImageLoader.AspectRatioPlaceholder = AspectRatioPlaceholder;
        function preLoad(imagePathArray) {
            return imagePathArray.map(function (imgPath) {
                console.assert(!!imgPath, 'imagePath is not undefined');
                if (typeof imgPath !== 'string')
                    return;
                var img = new Image();
                img.src = imgPath;
                return img;
            }).filter(function (img) { return !!img; });
        }
        ImageLoader.preLoad = preLoad;
    })(ImageLoader = Fily.ImageLoader || (Fily.ImageLoader = {}));
})(Fily || (Fily = {}));
