var Fily;
(function (Fily) {
    var Twitter;
    (function (Twitter) {
        Twitter.embed = function (tweetId, target) {
            if (typeof window.twttr !== 'undefined')
                window.twttr.widgets.createTweetEmbed(tweetId, target);
        };
        Twitter.setCallback = function (action) {
            if (typeof window.twttr !== 'undefined') {
                var twttr = window['twttr'];
                twttr['ready'](function (twttr) {
                    twttr.events.bind('tweet', function (event) {
                        var match = event.target.baseURI.match(/^https:\/\/.*.jp(\/.*)$/);
                        var url = match[1];
                        Fily.GA.sendSocial('twitter', action, url);
                    });
                });
            }
        };
        Twitter.idRegExp = /^https?:\/\/twitter.com\/\w+\/status\/\d+/;
        Twitter.isValidUrl = function (url) { return Twitter.idRegExp.test(url); };
    })(Twitter = Fily.Twitter || (Fily.Twitter = {}));
})(Fily || (Fily = {}));
