var Fily;
(function (Fily) {
    var Api;
    (function (Api) {
        var Idea;
        (function (Idea) {
            function create(title, categoryId, publish, alias) {
                if (publish === void 0) { publish = false; }
                return Api.send('idea.entry', { title: title, category_id: categoryId, publish: publish, alias: alias }, 'POST').done(function () {
                    if (publish) {
                        Fily.GA.sendEvent('コンテンツ', 'アイデア', 'オープン:作成', 1);
                    }
                    else {
                        Fily.GA.sendEvent('コンテンツ', 'アイデア', 'クローズ:作成', 0);
                    }
                }).fail(function (e) {
                    if (e.status === 401) {
                        alert('ログインしてください。');
                    }
                });
            }
            Idea.create = create;
            function getCategory() {
                return Api.send('idea.categories', {}, 'GET');
            }
            Idea.getCategory = getCategory;
            function get(id) {
                return Api.send('idea.' + id, {}, 'GET');
            }
            Idea.get = get;
            function getMyself(limit, offset, exceptIdeaId, toClip) {
                if (limit === void 0) { limit = 20; }
                if (offset === void 0) { offset = 0; }
                if (exceptIdeaId === void 0) { exceptIdeaId = null; }
                if (toClip === void 0) { toClip = false; }
                return Api.send("user." + Fily.Myself.alias + ".idea", {
                    limit: limit,
                    offset: offset,
                    except_idea_id: exceptIdeaId,
                    to_clip: Number(toClip)
                }, 'GET');
            }
            Idea.getMyself = getMyself;
            function getUserIdeaList(limit, offset, exceptIdeaId, toClip) {
                if (limit === void 0) { limit = 20; }
                if (offset === void 0) { offset = 0; }
                if (exceptIdeaId === void 0) { exceptIdeaId = null; }
                if (toClip === void 0) { toClip = false; }
                return Api.send("user." + Fily.GetOwnerAlias.alias + ".idea", {
                    limit: limit,
                    offset: offset,
                    except_idea_id: exceptIdeaId,
                    to_clip: Number(toClip)
                }, 'GET');
            }
            Idea.getUserIdeaList = getUserIdeaList;
            function getPhotoList(id, limit, offset) {
                if (limit === void 0) { limit = 20; }
                if (offset === void 0) { offset = 0; }
                return Api.send('idea.' + id + '.photos', {
                    limit: limit,
                    offset: offset
                }, 'GET');
            }
            Idea.getPhotoList = getPhotoList;
            function remove(id) {
                return Api.send('idea.' + id, {}, 'DELETE').done(function () {
                    Fily.GA.sendEvent('コンテンツ', 'アイデア', 'クローズ:削除', 0);
                });
            }
            Idea.remove = remove;
            function edit(id, params, changePublish) {
                if (changePublish === void 0) { changePublish = false; }
                return Api.send('idea.' + id, params, 'PUT').done(function () {
                    if (params.contest_id) {
                        Fily.GA.sendEvent('コンテスト', params.contest_id, '応募', 1);
                    }
                    if (!changePublish)
                        return;
                    if (params.publish) {
                        Fily.GA.sendEvent('コンテンツ', 'アイデア', 'オープン:更新', 1);
                    }
                    else {
                        Fily.GA.sendEvent('コンテンツ', 'アイデア', 'クローズ:更新', 0);
                    }
                });
            }
            Idea.edit = edit;
            function like(id, flag) {
                if (flag === void 0) { flag = true; }
                if (flag) {
                    return Api.send('idea.' + id + '.like', {}, 'POST').done(function () {
                        Fily.GA.setDimension('ida_' + id);
                        Fily.GA.sendEvent('レスポンス', 'いいね', '追加', 1);
                    });
                }
                return Api.send('idea.' + id + '.like', {}, 'DELETE').done(function () {
                    Fily.GA.setDimension('ida_' + id);
                    Fily.GA.sendEvent('レスポンス', 'いいね', '削除', 0);
                });
            }
            Idea.like = like;
            function likes(id, limit, offset) {
                if (limit === void 0) { limit = 10; }
                if (offset === void 0) { offset = 0; }
                return Api.send('idea.' + id + '.likes', {
                    limit: limit,
                    offset: offset
                }, 'GET');
            }
            Idea.likes = likes;
            function clip(ideaId, element) {
                return Element.create(ideaId, Element.Type.idea, element).done(function () {
                    Fily.GA.setDimension('ida_' + ideaId);
                    Fily.GA.sendEvent('レスポンス', 'クリップ', '追加', 1);
                });
            }
            Idea.clip = clip;
            function getRecommendKeywords(categoryEnglishName) {
                return Api.send('idea.keyword.' + categoryEnglishName, {}, 'GET');
            }
            Idea.getRecommendKeywords = getRecommendKeywords;
            function commentRefusal(id) {
                return Api.send('idea.' + id + '.comment.refusal', {}, 'POST').done(function () {
                    Fily.GA.sendEvent('レスポンス', 'コメント受付', 'ON', 1);
                });
            }
            Idea.commentRefusal = commentRefusal;
            function commentAccept(id) {
                return Api.send('idea.' + id + '.comment.refusal', {}, 'DELETE').done(function () {
                    Fily.GA.sendEvent('レスポンス', 'コメント受付', 'OFF', 0);
                });
            }
            Idea.commentAccept = commentAccept;
            function check(ideaId) {
                return Api.send('idea.' + ideaId + '.copycheck', {}, 'POST');
            }
            Idea.check = check;
            function report(ideaId) {
                return Api.send('idea.' + ideaId + '.copycheck', {}, 'GET');
            }
            Idea.report = report;
            var Element;
            (function (Element) {
                var Type;
                (function (Type) {
                    Type[Type["title"] = 1] = "title";
                    Type[Type["body"] = 2] = "body";
                    Type[Type["photo"] = 3] = "photo";
                    Type[Type["idea"] = 4] = "idea";
                    Type[Type["link"] = 6] = "link";
                    Type[Type["movie"] = 7] = "movie";
                    Type[Type["sns"] = 8] = "sns";
                    Type[Type["insidemaps"] = 9] = "insidemaps";
                    Type[Type["affiliate"] = 11] = "affiliate";
                    Type[Type["affiliateLink"] = 12] = "affiliateLink";
                    Type[Type["subheadline"] = 15] = "subheadline";
                    Type[Type["product"] = 17] = "product";
                    Type[Type["product_feature"] = 18] = "product_feature";
                    Type[Type["smallheadline"] = 19] = "smallheadline";
                    Type[Type["product_table"] = 20] = "product_table";
                    Type[Type["speech_bubble"] = 22] = "speech_bubble";
                    Type[Type["list"] = 23] = "list";
                    Type[Type["table_structure"] = 24] = "table_structure";
                })(Type = Element.Type || (Element.Type = {}));
                var SnsType;
                (function (SnsType) {
                    SnsType[SnsType["Twitter"] = 1] = "Twitter";
                    SnsType[SnsType["Instagram"] = 2] = "Instagram";
                })(SnsType = Element.SnsType || (Element.SnsType = {}));
                var AffiliateSite;
                (function (AffiliateSite) {
                    AffiliateSite[AffiliateSite["amazon"] = 1] = "amazon";
                    AffiliateSite[AffiliateSite["rakuten"] = 2] = "rakuten";
                    AffiliateSite[AffiliateSite["ichiba"] = 3] = "ichiba";
                    AffiliateSite[AffiliateSite["item_master"] = 4] = "item_master";
                })(AffiliateSite = Element.AffiliateSite || (Element.AffiliateSite = {}));
                function create(ideaId, elementType, element, position) {
                    if (position === void 0) { position = 0; }
                    var params = {
                        position: position,
                        element_type: elementType,
                        element: element
                    };
                    return Api.send('idea.' + ideaId + '.element.entry', params, 'POST')
                        .fail(function (e) {
                        if (e.status === 401) {
                            alert('正しいユーザーでログインしてください。');
                        }
                    });
                }
                Element.create = create;
                function createChild(ideaId, elementId, elementType, element) {
                    return Api.send("idea." + ideaId + ".element." + elementId, { element_type: elementType, affiliate_link: element }, 'POST');
                }
                Element.createChild = createChild;
                function remove(ideaId, elementId) {
                    return Api.send('idea.' + ideaId + '.element.' + elementId, {}, 'DELETE');
                }
                Element.remove = remove;
                function edit(ideaId, elementId, element) {
                    return Api.send('idea.' + ideaId + '.element.' + elementId, element, 'PUT')
                        .fail(function (e) {
                        if (e.status === 401) {
                            alert('正しいユーザーでログインしてください。');
                        }
                    });
                }
                Element.edit = edit;
                function multipleUpload(ideaId, element) {
                    return Api.send('idea.' + ideaId + '.photos.entry', { element: element }, 'POST');
                }
                Element.multipleUpload = multipleUpload;
            })(Element = Idea.Element || (Idea.Element = {}));
        })(Idea = Api.Idea || (Api.Idea = {}));
    })(Api = Fily.Api || (Fily.Api = {}));
})(Fily || (Fily = {}));
