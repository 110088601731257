var Fily;
(function (Fily) {
    var Sns;
    (function (Sns) {
        Sns.showSnsInvalidUrlError = function (elem, snsType) {
            if (snsType === Fily.Api.Idea.Element.SnsType.Twitter) {
                Fily.Field.Err.show(elem, Fily.ERR.CODE.TWITTER_URL_INVALID, ['URL']);
            }
            else if (snsType === Fily.Api.Idea.Element.SnsType.Instagram) {
                Fily.Field.Err.show(elem, Fily.ERR.CODE.INSTAGRAM_URL_INVALID, ['URL']);
            }
        };
        Sns.isValidSnsUrl = function (snsUrl, snsType) {
            if (snsType === Fily.Api.Idea.Element.SnsType.Twitter) {
                return Fily.Twitter.isValidUrl(snsUrl);
            }
            else if (snsType === Fily.Api.Idea.Element.SnsType.Instagram) {
                return Fily.Instagram.isValidUrl(snsUrl);
            }
        };
        Sns.replaceSnsUrl = function (snsUrl, snsType) {
            if (snsType === Fily.Api.Idea.Element.SnsType.Twitter) {
                return snsUrl;
            }
            else if (snsType === Fily.Api.Idea.Element.SnsType.Instagram) {
                return Fily.Instagram.replaceUrl(snsUrl);
            }
        };
    })(Sns = Fily.Sns || (Fily.Sns = {}));
})(Fily || (Fily = {}));
