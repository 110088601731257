var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var Fily;
(function (Fily) {
    var IdeaDetailView = (function (_super) {
        __extends(IdeaDetailView, _super);
        function IdeaDetailView() {
            var _this = _super.call(this, { delegate: false }) || this;
            _this.$body = $('.js-Idea');
            _this.delegateEvent();
            _this.ideaId = _this.$body.data('idea-id');
            Fily.FollowView.load();
            Fily.ContentsView.load();
            Fily.Insidemaps.init().setInsidemapsHeight();
            return _this;
        }
        IdeaDetailView.prototype.delegateEvent = function () {
            _super.prototype.delegateEvent.call(this);
        };
        IdeaDetailView.prototype.events = function () {
            var _this = this;
            return {
                'click / .js-Element-link': function (e) {
                    Fily.GA.sendEvent('リンク', 'アイデア', $(e.currentTarget).data('element-id'), 1);
                    Fily.GA.setDimension(_this.ideaId, 2);
                },
                'click / .js-Element--affiliate': function (e) {
                    var $target = $(e.currentTarget);
                    var $elementId = $target.parents('.js-Element-id');
                    Fily.GA.sendEvent('アフィリエイト', $target.data('action'), $target.data('label') + ':' + $elementId.data('element-id'));
                    Fily.GA.setDimension(_this.ideaId, 2);
                },
                'click / .js-indexLink': function (e) {
                    Fily.PageLinkView.smoothScroll({
                        adjustVal: -90,
                        target: "#index-" + $(e.currentTarget).data('index-id')
                    });
                }
            };
        };
        return IdeaDetailView;
    }(Fily.BaseView));
    Fily.IdeaDetailView = IdeaDetailView;
})(Fily || (Fily = {}));
