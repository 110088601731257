var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var Fily;
(function (Fily) {
    var SuggestView = (function (_super) {
        __extends(SuggestView, _super);
        function SuggestView(options) {
            var _this = _super.call(this, {}) || this;
            _this.isSuggested = false;
            _this.showedMax = 5;
            _this.IGNORE_KEYS = [
                9,
                16,
                17,
                18,
                21,
                22,
                27,
                32,
                37,
                38,
                39,
                40,
                91
            ];
            _this.$input = options.$input;
            _this.$result = options.$result;
            _this.tpl = options.template ? new Fily.MustacheTpl(options.template) : new Fily.MustacheTpl('suggest');
            _this.showedMax = options.showedMax ? options.showedMax : 5;
            return _this;
        }
        SuggestView.prototype.countLength = function () {
            var _this = this;
            return Object.keys(this.selected).filter(function (item) { return !!_this.selected[item]; }).length;
        };
        SuggestView.prototype.getAvailableIndex = function () {
            var _this = this;
            return Number(Object.keys(this.selected).filter(function (item) { return !_this.selected[item]; })[0]);
        };
        SuggestView.prototype.cancelEnter = function (e) {
            if (e.keyCode === 13) {
                e.preventDefault();
            }
        };
        SuggestView.prototype.keepKeycode = function (e) {
            this.keydownCode = e.keyCode;
        };
        SuggestView.prototype.isIgnoreKey = function (e) {
            return this.IGNORE_KEYS.indexOf(e.keyCode) > -1;
        };
        SuggestView.prototype.isContain = function (target, enter) {
            return Fily.Util.Str.hiraganaToFullWidthKatakana(target).toUpperCase().indexOf(enter) > -1;
        };
        SuggestView.prototype.findAllMatch = function (enter) {
            return [];
        };
        SuggestView.prototype.renderResult = function (enter) {
            var filterdKeywords = this.findAllMatch(enter).slice(0, this.showedMax);
            var $text = $(this.tpl.render({
                areas: filterdKeywords
            }));
            this.currentSuggestList = filterdKeywords;
            [].forEach.call($text.find('.js-suggestResult'), function (elm) {
                elm.innerHTML = Fily.Util.Str.replaceToBold(enter, $(elm).html());
            });
            this.$result.empty().append($text).addClass('is-active');
            this.isSuggested = true;
        };
        SuggestView.prototype.close = function () {
            if (this.isSuggested) {
                this.currentSuggestList = [];
                this.$result.empty().removeClass('is-active');
                this.isSuggested = false;
                this.$input.val('');
            }
        };
        return SuggestView;
    }(Fily.BaseView));
    Fily.SuggestView = SuggestView;
})(Fily || (Fily = {}));
