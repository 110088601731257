var Fily;
(function (Fily) {
    var Api;
    (function (Api) {
        Api.VERSION = 'v1';
        function send(requestOrApiName, params, type) {
            if (params === void 0) { params = {}; }
            if (type === void 0) { type = 'GET'; }
            if (requestOrApiName instanceof Object) {
                if (Array.isArray(requestOrApiName)) {
                    return batch(requestOrApiName);
                }
                var request = requestOrApiName;
                return send(request.apiName, request.params, request.type);
            }
            return new Ajax(requestOrApiName, params, type).send().pipe(function (res) {
                if (typeof res === 'string') {
                    return JSON.parse(res);
                }
                return res;
            });
        }
        Api.send = send;
        var batch = function (requests) {
            return $.when.apply(null, requests.map(function (request) { return send(request); }))
                .pipe(function () {
                var results = [];
                for (var _i = 0; _i < arguments.length; _i++) {
                    results[_i] = arguments[_i];
                }
                return results[1] == 'success' ? results.slice(0, 1) : results.map(function (result) { return result[0]; });
            });
        };
        var Ajax = (function () {
            function Ajax(apiName, params, type) {
                if (params === void 0) { params = {}; }
                if (type === void 0) { type = 'GET'; }
                this.apiName = apiName;
                this.params = params;
                this.type = type;
            }
            Ajax.prototype.send = function () {
                var data = null;
                if (this.type === 'GET') {
                    data = $.param(this.params);
                }
                else {
                    data = JSON.stringify(this.params);
                }
                return $.ajax({
                    url: this.convertToApiUrl(this.apiName),
                    dataType: 'json',
                    contentType: 'application/json',
                    data: data,
                    type: this.type,
                    headers: { 'X-FUEL-CSRF-TOKEN': Fily.Config.csrfToken }
                });
            };
            Ajax.prototype.convertToApiUrl = function (apiName) {
                return location.protocol + '//' + location.host + '/api/' + Api.VERSION + '/' + apiName.replace(/\./g, '/');
            };
            return Ajax;
        }());
        Api.Ajax = Ajax;
        function notSend() {
            var d = $.Deferred();
            d.resolve();
            return d.promise();
        }
        Api.notSend = notSend;
    })(Api = Fily.Api || (Fily.Api = {}));
})(Fily || (Fily = {}));
