var Fily;
(function (Fily) {
    var tplStore = {};
    var MustacheTpl = (function () {
        function MustacheTpl(fileName, device) {
            if (device === void 0) { device = Fily.Config.device; }
            this.fileName = fileName;
            this.device = device;
            this.mustacheFns = {
                'line_break': function () {
                    return function (text, render) {
                        return render(text).replace(/\n/g, '<br>\n');
                    };
                },
                'bold_tags': function () {
                    return function (text, render) {
                        return render(text)
                            .replace(/&lt;b&gt;([\s\S]*?)&lt;(&#x2F;|\/)b&gt;/g, '<b>$1</b>');
                    };
                },
                'a_tags': function () {
                    return function (text, render) {
                        function htmlDecode(input) {
                            var e = document.createElement('textarea');
                            e.innerHTML = input;
                            return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
                        }
                        var result = "";
                        render(text)
                            .split(/(&lt;a .*?&lt;&#x2F;a&gt;)/g)
                            .forEach(function (it) {
                            var linkHref = it.replace(/&lt;a .*?href&#x3D;&quot;(.*?)&quot;.*?&gt;(.*?)&lt;&#x2F;a&gt;/g, '$1');
                            if (linkHref.isEmpty) {
                                result += it;
                            }
                            else {
                                var decorded = htmlDecode(linkHref);
                                var linkBody = it.replace(/&lt;a.*?href&#x3D;&quot;(.*?)&quot;.*?&gt;(.*?)&lt;&#x2F;a&gt;/g, 'data-textstyle="linkRecommend" rel="nofollow noopener" class="js-send-click-event" target="_blank">$2</a>');
                                var linkTag = '<a href='.concat('', decorded).concat('', ' data-href=').concat('', decorded).concat('', ' ').concat('', linkBody);
                                result += it.replace(/&lt;a([\s\S]*?)&gt;([\s\S]*?)&lt;(&#x2F;|\/)a&gt;/g, linkTag);
                            }
                        });
                        return result;
                    };
                },
                'ucwords': function () {
                    return function (text, render) {
                        var t = render(text);
                        return t.replace(t.charAt(0), t.charAt(0).toUpperCase());
                    };
                },
                'strimwidth32': function () {
                    return function (text, render) {
                        var t = render(text).replace(/\s/g, '');
                        return t.length > 32 ? t.substr(0, 32) + '...' : t;
                    };
                },
                'strimwidth80': function () {
                    return function (text, render) {
                        var t = render(text).replace(/\s/g, '');
                        return t.length > 80 ? t.substr(0, 80) + '...' : t;
                    };
                },
                'strimwidth140': function () {
                    return function (text, render) {
                        var t = render(text).replace(/\s/g, '');
                        return t.length > 140 ? t.substr(0, 140) + '...' : t;
                    };
                },
            };
            this.appBridges = {
                'push_url': function () {
                    return function (text, render) {
                        var url = Fily.Config.serverProtocol + Fily.Config.httpHost + render(text);
                        return 'limia-bridge://push?url=' + encodeURIComponent(url);
                    };
                },
                'push_ex_url': function () {
                    return function (text, render) {
                        return 'limia-bridge://external?url=' + encodeURIComponent(render(text));
                    };
                },
                'push_view': function () {
                    return function (text, render) {
                        return 'limia-bridge://push?' + render(text);
                    };
                },
                'modal_url': function () {
                    return function (text, render) {
                        var url = Fily.Config.serverProtocol + Fily.Config.httpHost + render(text);
                        return 'limia-bridge://modal?url=' + encodeURIComponent(url);
                    };
                },
                'logout': function () { return 'limia-bridge://logout'; },
                'signin': function () { return 'limia-bridge://promotesignin'; },
                'pop': function () { return 'limia-bridge://pop'; }
            };
            this.key = this.device + ':' + this.fileName;
        }
        ;
        MustacheTpl.prototype.load = function () {
            var _this = this;
            var d = $.Deferred();
            if (this.key in tplStore) {
                this.template = tplStore[this.key];
                d.resolve(this);
            }
            else {
                var apiName = 'template.include.' + this.device + '.' + this.fileName.replace(/\//g, ':');
                new Fily.Api.Ajax(apiName).send().done(function (res) {
                    _this.template = res.tpl;
                    tplStore[_this.key] = _this.template;
                    d.resolve(_this);
                });
            }
            return d.promise();
        };
        MustacheTpl.prototype.render = function (params) {
            if (params === void 0) { params = {}; }
            params['mustache_fns'] = this.mustacheFns;
            params['app_bridge'] = this.appBridges;
            params['config'] = Fily.Config.instance;
            console.assert(this.template !== undefined, 'template is not undefined');
            return Mustache.render(this.template, params);
        };
        return MustacheTpl;
    }());
    Fily.MustacheTpl = MustacheTpl;
})(Fily || (Fily = {}));
