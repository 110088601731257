var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var Fily;
(function (Fily) {
    var PhotoUploadModalView = (function (_super) {
        __extends(PhotoUploadModalView, _super);
        function PhotoUploadModalView() {
            var _this = _super !== null && _super.apply(this, arguments) || this;
            _this.bodyTpl = new Fily.MustacheTpl('photo/upload_modal');
            _this.isContestPhoto = false;
            _this.contest10 = '1.改善したかった点\n2.こだわりポイント\n3.お住まいの種別(賃貸 or 持家)';
            return _this;
        }
        PhotoUploadModalView.prototype.events = function () {
            var _this = this;
            return $.extend(_super.prototype.events.call(this), {
                'click / .js-upload-photoarea': function () { return _this.$uploadPhoto.click(); },
                'change / select[name=contest]': function (e) {
                    if (e.target.value === '10') {
                        _this.$comment.val(_this.contest10);
                    }
                    else if (_this.$comment.val() === _this.contest10) {
                        _this.$comment.val('');
                    }
                },
                'click / #js-rotatePhoto': function (e) {
                    _this.uploader.rotatePreview();
                }
            });
        };
        PhotoUploadModalView.prototype.showWithPhoto = function ($uploadPhoto) {
            this.$uploadPhoto = $uploadPhoto;
            this.show();
        };
        PhotoUploadModalView.prototype.prepare = function () {
            var _this = this;
            this.$uploadPhotoArea = $('.js-upload-photoarea', this.$body);
            this.$comment = $('textarea[name=comment]', this.$body);
            this.$contest = $('select[name=contest]', this.$body);
            this.$creditUrl = $('input[name=crediturl]', this.$body);
            this.$credit = $('input[name=credit]', this.$body);
            Fily.Api.Contest.getEnterablePhoto().done(function (res) {
                if (!res) {
                    $("#" + _this.$contest.data('target-id')).remove();
                    _this.$contest = null;
                }
                else {
                    _this.isContestPhoto = true;
                    $("#" + _this.$contest.data('target-id')).show();
                    _this.$contest.append(res.map(function (c) { return "<option value=\"" + c.id + "\">" + c.title + "</option>"; }).join(''));
                    if (_this.$uploadPhoto.data('contest-id')) {
                        _this.$contest.val(_this.$uploadPhoto.data('contest-id'));
                    }
                    if (_this.$contest.val() === '10') {
                        _this.$comment.val(_this.contest10);
                    }
                }
            });
            this.commentCounter = new Fily.InputCounter(this.$comment, $('.js-counter--comment'));
            this.creditUrlCounter = new Fily.InputCounter(this.$creditUrl, $('.js-counter--crediturl'));
            this.creditCounter = new Fily.InputCounter(this.$credit, $('.js-counter--credit'));
        };
        PhotoUploadModalView.prototype.render = function () {
            _super.prototype.render.call(this);
            this.uploader = new Fily.PhotoUploader($('.js-upload-photoarea', this.$body));
            this.renderPhoto();
            this.keywordEditor = new Fily.KeywordEditorView();
            this.prepare();
        };
        PhotoUploadModalView.prototype.renderPhoto = function () {
            this.uploader.renderPhoto(this.$uploadPhoto.prop('files'));
        };
        PhotoUploadModalView.prototype.done = function (event) {
            var _this = this;
            var $button = $(event.currentTarget);
            if ($button.hasClass('is-disabled'))
                return;
            $button.addClass('is-disabled');
            if (this.checkError()) {
                $button.addClass('is-error');
                setTimeout(function () {
                    $button.removeClass('is-error is-disabled');
                }, 1000);
                return;
            }
            Fily.showFullLoading();
            var isContestPhoto = this.$contest && !!this.$contest.val();
            this.uploader.rotateUploadPhoto().then(function () {
                var api;
                if (isContestPhoto) {
                    api = _this.upload(null, _this.$contest.val());
                }
                else {
                    api = _this.upload(null);
                }
                api.done(function (res) {
                    if (isContestPhoto) {
                        location.href = "/contest/" + _this.$contest.val() + "/";
                    }
                    else {
                        location.href = '/user/' + Fily.Myself.alias + '/photo/';
                    }
                }).fail(function () {
                    Fily.Ngword.checkInput([_this.$comment, _this.$credit]);
                    $button.addClass('is-error');
                    setTimeout(function () {
                        $button.removeClass('is-error');
                    }, 1000);
                }).always(function () {
                    $button.removeClass('is-disabled');
                    Fily.hideFullLoading();
                });
            });
        };
        PhotoUploadModalView.prototype.upload = function (galleryId, contestId) {
            if (galleryId === void 0) { galleryId = null; }
            if (contestId === void 0) { contestId = null; }
            if (contestId) {
                return Fily.Api.Photo.upload({
                    data: this.uploader.uploadImageDataString,
                    description: this.$comment.val(),
                    keywords: this.keywordEditor.keywords(),
                    credit_url: this.$creditUrl.val(),
                    credit_title: this.$credit.val(),
                    contest_id: contestId
                });
            }
            else {
                return Fily.Api.Photo.upload({
                    data: this.uploader.uploadImageDataString,
                    description: this.$comment.val(),
                    keywords: this.keywordEditor.keywords(),
                    credit_url: this.$creditUrl.val(),
                    credit_title: this.$credit.val()
                });
            }
        };
        PhotoUploadModalView.prototype.checkError = function () {
            var invalid = false;
            if (!this.uploader.uploadImageDataString) {
                Fily.Field.Err.show(this.$uploadPhotoArea, Fily.ERR.CODE.SELECT_INVALID, ['画像ファイル']);
                invalid = true;
            }
            else if (!Fily.Util.checkImageFileSize(this.$uploadPhoto.prop('files')[0])) {
                Fily.Field.Err.show(this.$uploadPhotoArea, Fily.ERR.CODE.IMAGE_SIZE_INVALID);
                invalid = true;
            }
            if (!this.commentCounter.checkLength()) {
                Fily.Field.Err.show(this.$comment, Fily.ERR.CODE.INPUT_MAX_LENGTH_INVALID, ['コメント', this.commentCounter.maxLength.toString()]);
                invalid = true;
            }
            if (this.$creditUrl.val() && !Fily.Util.isUrl(this.$creditUrl.val())) {
                Fily.Field.Err.show(this.$creditUrl, Fily.ERR.CODE.INPUT_INVALID, ['出典（URL）']);
                invalid = true;
            }
            else if (Fily.Denied.isDeniedPhotoUrl(this.$creditUrl.val())) {
                Fily.Field.Err.show(this.$creditUrl, Fily.ERR.CODE.DENIED_PHOTO_URL);
                invalid = true;
            }
            if (!this.creditUrlCounter.checkLength()) {
                Fily.Field.Err.show(this.$creditUrl, Fily.ERR.CODE.INPUT_MAX_LENGTH_INVALID, ['出典（URL）', this.creditUrlCounter.maxLength.toString()]);
                invalid = true;
            }
            if (!this.creditCounter.checkLength()) {
                Fily.Field.Err.show(this.$credit, Fily.ERR.CODE.INPUT_MAX_LENGTH_INVALID, ['クレジット', this.creditCounter.maxLength.toString()]);
                invalid = true;
            }
            return invalid;
        };
        PhotoUploadModalView.prototype.hide = function () {
            _super.prototype.hide.call(this);
            this.clear();
            this.$uploadPhoto.val('');
        };
        return PhotoUploadModalView;
    }(Fily.ModalView));
    Fily.PhotoUploadModalView = PhotoUploadModalView;
})(Fily || (Fily = {}));
