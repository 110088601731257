var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var Fily;
(function (Fily) {
    var IdeaEditIdeaElementModalView = (function (_super) {
        __extends(IdeaEditIdeaElementModalView, _super);
        function IdeaEditIdeaElementModalView(ideaId, options) {
            var _this = _super.call(this, options) || this;
            _this.ideaId = ideaId;
            return _this;
        }
        IdeaEditIdeaElementModalView.prototype.render = function () {
            _super.prototype.render.call(this);
            this.$inputCaption = $('textarea[name=caption]', this.$body);
            this.captionCounter = new Fily.InputCounter(this.$inputCaption, $('.js-counter'));
        };
        IdeaEditIdeaElementModalView.prototype.done = function (event) {
            var _this = this;
            if (!this.captionCounter.checkRequire()) {
                Fily.Field.Err.show(this.$inputCaption, Fily.ERR.CODE.INPUT_EMPTY, ['キャプション']);
                return;
            }
            if (!this.captionCounter.checkLength()) {
                Fily.Field.Err.show(this.$inputCaption, Fily.ERR.CODE.INPUT_MAX_LENGTH_INVALID, ['キャプション', this.captionCounter.maxLength.toString()]);
                return;
            }
            Fily.showFullLoading();
            Fily.Api.Idea.Element.edit(this.ideaId, this.params.id, {
                caption: this.$inputCaption.val(),
            }).done(function (res) { return _super.prototype.done.call(_this, res); })
                .fail(function () { return Fily.Ngword.checkInput([_this.$inputCaption]); })
                .always(function () { return Fily.hideFullLoading(); });
        };
        return IdeaEditIdeaElementModalView;
    }(Fily.ModalView));
    Fily.IdeaEditIdeaElementModalView = IdeaEditIdeaElementModalView;
})(Fily || (Fily = {}));
