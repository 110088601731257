var Fily;
(function (Fily) {
    var Carousel = (function () {
        function Carousel(elements) {
            var _this = this;
            this.dragThreshold = 3;
            this.state = {
                pressX: 0,
                offsetX: 0,
                slide: 0,
                isPressed: false,
            };
            $(window).on('load', function () {
                _this.mount(elements);
            });
        }
        Carousel.prototype.handleStart = function (e) {
            var _this = this;
            var evt = e.targetTouches && e.targetTouches.length ? e.targetTouches[0] : e;
            this.set({
                pressX: evt.clientX,
                isPressed: true,
            });
            setTimeout(function () {
                _this.$wrap.addClass('is-draggable');
            }, 1000 / 60);
        };
        Carousel.prototype.handleMove = function (e) {
            var _this = this;
            var evt = e.targetTouches && e.targetTouches.length ? e.targetTouches[0] : e;
            var pageX = evt.pageX;
            if (this.state.isPressed) {
                var diff = (pageX - this.state.pressX);
                var slide = this.state.offsetX + diff;
                if (Math.abs(diff) < this.dragThreshold) {
                    return;
                }
                e.preventDefault();
                if (slide > 0) {
                    this.set({ slide: 0 });
                }
                else if ((this.threshold - this.wrapWidth) < -slide) {
                    this.set({ slide: -(this.threshold - this.wrapWidth) });
                }
                else {
                    this.set({ slide: slide });
                }
                setTimeout(function () {
                    _this.$inner.css(Fily.Util.Css.addVendorPrefix('transform'), Fily.Util.Css.addVendorPrefix("translate3d(" + _this.state.slide + "px, 0, 0)"));
                }, 1000 / 60);
            }
        };
        Carousel.prototype.handleEnd = function () {
            var _this = this;
            this.set({
                isPressed: false,
                offsetX: this.state.slide,
                pressX: 0,
            });
            setTimeout(function () {
                _this.$wrap.removeClass('is-draggable');
            }, 1000 / 60);
        };
        Carousel.prototype.set = function (newState) {
            _.extend(this.state, newState);
        };
        Carousel.init = function (elements) {
            return new Carousel(elements);
        };
        Carousel.prototype.mount = function (elements) {
            this.$wrap = elements.$wrap;
            this.wrapWidth = elements.$wrap.width();
            this.$inner = elements.$wrap.find('.js-carouselInner');
            var $items = this.$inner.find('li');
            var innerWidth = ($items.eq(0).outerWidth(true) * $items.length);
            this.threshold = innerWidth + (this.$inner.outerWidth() - this.$inner.width());
            if (this.threshold > this.$wrap.width()) {
                this.$inner.width(innerWidth);
                this.setHandlers();
            }
            else {
                this.$wrap.css('padding-left', 0);
            }
        };
        Carousel.prototype.setHandlers = function () {
            var wrapElem = this.$wrap.get(0);
            wrapElem.addEventListener('touchstart', this.handleStart.bind(this));
            wrapElem.addEventListener('touchmove', this.handleMove.bind(this));
            wrapElem.addEventListener('touchend', this.handleEnd.bind(this));
            wrapElem.addEventListener('mousedown', this.handleStart.bind(this));
            wrapElem.addEventListener('mousemove', this.handleMove.bind(this));
            wrapElem.addEventListener('mouseup', this.handleEnd.bind(this));
        };
        return Carousel;
    }());
    Fily.Carousel = Carousel;
})(Fily || (Fily = {}));
