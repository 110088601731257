var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var Fily;
(function (Fily) {
    var IdeaEditSnsElementModalView = (function (_super) {
        __extends(IdeaEditSnsElementModalView, _super);
        function IdeaEditSnsElementModalView(ideaId, options) {
            var _this = _super.call(this, options) || this;
            _this.ideaId = ideaId;
            return _this;
        }
        IdeaEditSnsElementModalView.prototype.events = function () {
            return $.extend(_super.prototype.events.call(this), {
                'click / .js-snsModalTab': 'clickModalTab',
            });
        };
        IdeaEditSnsElementModalView.prototype.render = function () {
            _super.prototype.render.call(this);
            if (this.params.id) {
                this.snsType = this.params.sns_type;
                this.$snsInputUrl = this.$body.find('input[name=snsUrl]');
            }
            else {
                this.snsType = parseInt(this.$body.find('.js-snsModalTab[aria-expanded=true]').data('sns_type'), 10);
                this.$snsModalTabPanel = this.$body.find('.js-snsModalTabPanel');
                this.$twitterInputUrl = this.$snsModalTabPanel.find('input[name=twitterUrl]');
                this.$instagramInputUrl = this.$snsModalTabPanel.find('input[name=instagramUrl]');
            }
        };
        IdeaEditSnsElementModalView.prototype.done = function (event) {
            var _this = this;
            var $button = $(event.currentTarget);
            var snsUrl;
            var $targetInputUrl;
            if (this.params.id) {
                snsUrl = this.$snsInputUrl.val();
                $targetInputUrl = this.$snsInputUrl;
            }
            else {
                if (this.snsType === Fily.Api.Idea.Element.SnsType.Twitter) {
                    snsUrl = this.$twitterInputUrl.val();
                    $targetInputUrl = this.$twitterInputUrl;
                }
                else if (this.snsType === Fily.Api.Idea.Element.SnsType.Instagram) {
                    snsUrl = this.$instagramInputUrl.val();
                    $targetInputUrl = this.$instagramInputUrl;
                }
            }
            var replacedSnsUrl = '';
            var invalid = false;
            if (!snsUrl) {
                Fily.Field.Err.show($targetInputUrl, Fily.ERR.CODE.INPUT_EMPTY, ['URL']);
                invalid = true;
            }
            if (!Fily.Util.isUrl(snsUrl)) {
                Fily.Field.Err.show($targetInputUrl, Fily.ERR.CODE.INPUT_FORMAT_INVALID, ['URL']);
                return;
            }
            if (!Fily.Sns.isValidSnsUrl(snsUrl, this.snsType)) {
                Fily.Sns.showSnsInvalidUrlError($targetInputUrl, this.snsType);
                invalid = true;
            }
            else {
                replacedSnsUrl = Fily.Sns.replaceSnsUrl(snsUrl, this.snsType);
                if (!replacedSnsUrl) {
                    Fily.Sns.showSnsInvalidUrlError($targetInputUrl, this.snsType);
                    invalid = true;
                }
            }
            if (invalid) {
                $button.removeClass('is-error').addClass('is-error');
                setTimeout(function () {
                    $button.removeClass('is-error');
                }, 500);
                return;
            }
            Fily.showFullLoading();
            var api;
            if (this.params.id) {
                api = Fily.Api.Idea.Element.edit(this.ideaId, this.params.id, {
                    url: replacedSnsUrl,
                });
            }
            else {
                api = Fily.Api.Idea.Element.create(this.ideaId, Fily.Api.Idea.Element.Type.sns, {
                    url: replacedSnsUrl,
                    sns_type: this.snsType,
                }, this.params.position);
            }
            api.done(function (res) { return _super.prototype.done.call(_this, res); })
                .always(function () { return Fily.hideFullLoading(); });
        };
        IdeaEditSnsElementModalView.prototype.clickModalTab = function (event) {
            var $target = $(event.currentTarget);
            $('.js-snsModalTab[aria-expanded=true]').attr('aria-expanded', 'false');
            this.$snsModalTabPanel.hide();
            $target.attr('aria-expanded', $target.attr('aria-expanded') === 'true' ? 'false' : 'true');
            this.$body.find("#" + $target.attr('aria-controls')).show();
            this.snsType = parseInt($target.data('sns_type'), 10);
        };
        return IdeaEditSnsElementModalView;
    }(Fily.ModalView));
    Fily.IdeaEditSnsElementModalView = IdeaEditSnsElementModalView;
})(Fily || (Fily = {}));
