var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var Fily;
(function (Fily) {
    var PhotoEditView = (function (_super) {
        __extends(PhotoEditView, _super);
        function PhotoEditView() {
            var _this = _super.call(this, { delegate: false }) || this;
            _this.$body = $('.js-Photo');
            PhotoEditView.instance = _this;
            _this.delegateEvent();
            _this.photoId = _this.$body.data('photo-id');
            _this.galleryId = _this.$body.data('gallery-id');
            _this.$description = $('textarea[name=description]', _this.$body);
            _this.descriptionCounter = new Fily.InputCounter(_this.$description, $('.js-description-counter'));
            _this.$creditTitle = $('input[name=credit]', _this.$body);
            _this.creditTitleCounter = new Fily.InputCounter(_this.$creditTitle, $('.js-credit-counter'));
            _this.$creditUrl = $('input[name=cite]', _this.$body);
            _this.creditUrlCounter = new Fily.InputCounter(_this.$creditUrl, $('.js-cite-counter'));
            return _this;
        }
        PhotoEditView.delegateEvent = function () {
            if (!PhotoEditView.instance)
                new PhotoEditView();
            PhotoEditView.instance.delegateEvent();
        };
        ;
        PhotoEditView.prototype.delegateEvent = function () {
            _super.prototype.delegateEvent.call(this);
        };
        PhotoEditView.prototype.events = function () {
            var _this = this;
            return {
                'click / .js-delete--photo': function () {
                    if (window.confirm('このフォトを削除しますが、よろしいですか？')) {
                        Fily.Api.Photo.remove(_this.photoId).done(function () {
                            if (_this.galleryId) {
                                location.href = "/gallery/" + _this.galleryId + "/";
                            }
                            else {
                                location.href = "/user/" + Fily.Myself.alias + "/";
                            }
                        });
                    }
                },
                'click / .js-photo-save': 'save'
            };
        };
        PhotoEditView.prototype.save = function () {
            var _this = this;
            if (!this.descriptionCounter.checkLength()) {
                Fily.Field.Err.show(this.$description, Fily.ERR.CODE.INPUT_MAX_LENGTH_INVALID, ['紹介文', this.descriptionCounter.maxLength.toString()]);
                return;
            }
            if (!this.creditTitleCounter.checkLength()) {
                Fily.Field.Err.show(this.$creditTitle, Fily.ERR.CODE.INPUT_MAX_LENGTH_INVALID, ['クレジット', this.creditTitleCounter.maxLength.toString()]);
                return;
            }
            if (!this.creditUrlCounter.checkLength()) {
                Fily.Field.Err.show(this.$creditUrl, Fily.ERR.CODE.INPUT_MAX_LENGTH_INVALID, ['出典（URL）', this.creditUrlCounter.maxLength.toString()]);
                return;
            }
            var creditUrl = this.$creditUrl.val();
            if (creditUrl && !Fily.Util.isUrl(creditUrl)) {
                Fily.Field.Err.show(this.$creditUrl, Fily.ERR.CODE.INPUT_INVALID, ['出典（URL）']);
                return;
            }
            else if (Fily.Denied.isDeniedPhotoUrl(creditUrl)) {
                Fily.Field.Err.show(this.$creditUrl, Fily.ERR.CODE.DENIED_PHOTO_URL);
                return;
            }
            Fily.showFullLoading();
            Fily.Api.Photo.edit(this.photoId, {
                gallery_id: $('select[name=gallery]', this.$body).val(),
                description: this.$description.val(),
                credit_title: this.$creditTitle.val(),
                credit_url: creditUrl,
                keywords: $.map($('.js-photo-keyword', this.$body), function (dom) { return $(dom).text(); })
            }).done(function (res) {
                location.href = '/photo/' + res.id + '/';
            }).fail(function () { return Fily.Ngword.checkInput([_this.$description, _this.$creditTitle]); })
                .always(function () { return Fily.hideFullLoading(); });
        };
        return PhotoEditView;
    }(Fily.BaseView));
    Fily.PhotoEditView = PhotoEditView;
})(Fily || (Fily = {}));
