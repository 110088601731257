var Fily;
(function (Fily) {
    var Field;
    (function (Field) {
        var Err;
        (function (Err) {
            Err.show = function ($inputArea, code, params) {
                if (params === void 0) { params = []; }
                $inputArea.parents('.InputField, .TextareaField, .SelectField, .UploadField, .CheckboxField, .RadioboxField')
                    .addClass('is-error')
                    .find('.InputField-errorMessage, .TextareaField-errorMessage, .SelectField-errorMessage, .UploadField-errorMessage, .CheckboxField-errorMessage, .RadioboxField-errorMessage').html(Fily.ERR.toString(code, params));
            };
            Err.hide = function ($inputArea) {
                $inputArea.parents('.InputField, .TextareaField, .SelectField, .UploadField').removeClass('is-error');
            };
            Err.toggle = function ($inputArea, valid, code, params) {
                if (code === void 0) { code = null; }
                if (params === void 0) { params = []; }
                if (valid) {
                    Err.show($inputArea, code, params);
                }
                else {
                    Err.hide($inputArea);
                }
            };
        })(Err = Field.Err || (Field.Err = {}));
    })(Field = Fily.Field || (Fily.Field = {}));
})(Fily || (Fily = {}));
